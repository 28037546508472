import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const PREFIX = 'YearlyRecordCell';

const classes = {
  hoursCell: `${PREFIX}-hoursCell`,
  reportCell: `${PREFIX}-reportCell`,
  recordHoursText: `${PREFIX}-recordHoursText`,
  flexContainer: `${PREFIX}-flexContainer`,
  hoursContainer: `${PREFIX}-hoursContainer`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '63px',
  minWidth: '240px',

  [`& .${classes.hoursCell}`]: {
    margin: '2.5px',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.reportCell}`]: {
    backgroundColor: theme.palette.white,
  },

  [`& .${classes.recordHoursText}`]: {
    color: theme.palette.grey.dark,
  },
}));

const YearlyRecordsCell = (props) => {
  const { hours } = props;

  return (
    <Root>
      {
        <div className={`${classes.hoursCell} ${classes.reportCell}`}>
          <Typography className={classes.recordHoursText}>{hours}</Typography>
        </div>
      }
    </Root>
  );
};

export default YearlyRecordsCell;
