import React from 'react';
import Select from 'react-select';

const InnerSelectField = ({ input, label, required, options, ...custom }) => {
  //TODO fix select error validation
  return (
    <Select
      value={input.value}
      required={required}
      options={options}
      menuPosition={'static'}
      onChange={value => {
        if (value) {
          input.onChange(value);
        } else {
          input.onChange(null);
        }
      }}
      {...custom}
    />
  );
};

export default InnerSelectField;
