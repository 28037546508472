import { connect } from 'react-redux';
import YearlyExcelReport from './YearlyExcelReport';
import { getYearlyUserActivities } from '../../../../redux/modules/activities/activitiesActions';

const mapStateToProps = state => ({
  userActivities: state.activities.yearlyUserActivities,
  isLoading: state.activities.isLoading,
});

const mapDispatchToProps = {
  getYearlyUserActivities,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YearlyExcelReport);
