import { useEffect } from 'react';

const useDebouncedEffect = (callback, timeout, deps) => {
  useEffect(() => {
    const handler = setTimeout(callback, timeout);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout, ...deps]);
};

export default useDebouncedEffect;
