import React, { useEffect, useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import YearlyUsersColumnContainer from '../UsersColumn/YearlyUsersColumnContainer';
import ActivityTypesLegendBar from '../ActivityTypesLegendBar/ActivityTypesLegendBar';
import YearlyRecordsColumnContainer from './RecordsColumn/YearlyRecordsColumnContainer';
import YearlyTotalsColumnContainer from './TotalsColumn/YearlyTotalsColumnContainer';
import SearchUserFormContainer from '../SearchUserForm/SearchUserFormContainer';
import paginationConstants from '../../../helper/paginationConstants';
import YearPicker from '../../../common/DatePickers/YearPicker';
import TableRowSpinner from '../../../common/TableSpinners/TableRowSpinner';

const PREFIX = 'YearlyReport';

const classes = {
  table: `${PREFIX}-table`,
  borderlessCell: `${PREFIX}-borderlessCell`,
  flexContainer: `${PREFIX}-flexContainer`,
  container: `${PREFIX}-container`,
  tableBody: `${PREFIX}-tableBody`,
  tableHeader: `${PREFIX}-tableHeader`,
  loadingTable: `${PREFIX}-loadingTable`,
  loadingCell: `${PREFIX}-loadingCell`,
  loadingWrapper: `${PREFIX}-loadingWrapper`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.whitegrey,
  boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',

  [`& .${classes.table}`]: {
    minWidth: 700,
  },

  [`& .${classes.borderlessCell}`]: {
    border: 'none',
  },

  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    height: 'auto',
  },

  [`& .${classes.container}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  [`& .${classes.tableBody}`]: {
    minHeight: '30vh',
    overflowY: 'auto',
  },

  [`& .${classes.tableHeader}`]: {
    backgroundColor: theme.palette.white,
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
  },

  [`& .${classes.loadingTable}`]: {
    height: '30vh',
  },

  [`& .${classes.loadingCell}`]: {
    border: 'none',
    paddingTop: '160px',
  },

  [`& .${classes.loadingWrapper}`]: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingTop: '160px',
    height: '60vh',
    maxHeight: '82vh',
  },
}));

const YearlyReport = (props) => {
  const { year, loadMore, isLoading } = props;
  return (
    <TableBody className={classes.tableBody}>
      <TableRow className={classes.flexContainer}>
        <YearlyUsersColumnContainer year={year} loadMore={loadMore} isLoading={isLoading} />
        <YearlyRecordsColumnContainer year={year} />
        <YearlyTotalsColumnContainer />
      </TableRow>
      {isLoading && <TableRowSpinner />}
    </TableBody>
  );
};

const YearlyReportPage = (props) => {
  const { year, isYearlyReportLoading, getYearlyReport, clearYearlyReport } = props;
  const navigate = useNavigate();

  const pageSize = paginationConstants.itemsPerPage;
  const [usersPagination, setUsersPagination] = useState({
    page: 1,
    search: '',
  });

  const handleYearChange = useCallback((date) => {
    clearYearlyReport();
    setUsersPagination((prev) => ({ ...prev, page: 1 }));
    navigate(`/reports/yearly?year=${date}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleLoadUsers = useCallback(() => {
    setUsersPagination((prev) => ({ ...prev, page: prev.page + 1 }));
  }, []);
  const handleSearchUsers = useCallback((name) => {
    clearYearlyReport();
    setUsersPagination((prev) => ({ ...prev, search: name, page: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      clearYearlyReport();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getYearlyReport(year, usersPagination.search, usersPagination.page, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, usersPagination.search, usersPagination.page]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 37) {
        const previousYear = year - 1;
        handleYearChange(previousYear);
      }
      if (e.keyCode === 39) {
        const nextYear = year + 1;
        handleYearChange(nextYear);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  return (
    <>
      <StyledPaper className={classes.root} elevation={2}>
        <Table className={classes.table}>
          <TableHead className={classes.tableHeader}>
            <TableRow className={classes.container}>
              <TableCell className={classes.borderlessCell}>
                <YearPicker year={year} yearChanged={handleYearChange} />
              </TableCell>
              <TableCell className={classes.borderlessCell}>
                <SearchUserFormContainer onSearch={handleSearchUsers} />
              </TableCell>
            </TableRow>
          </TableHead>
          <YearlyReport classes={classes} year={year} loadMore={handleLoadUsers} isLoading={isYearlyReportLoading} />
        </Table>
        <ActivityTypesLegendBar />
      </StyledPaper>
    </>
  );
};

export default YearlyReportPage;
