import moment from 'moment';
import actionTypes from './actionTypes';

const getTotalTime = totals => {
  const totalHoursPerDay = Array(7).fill({ hours: 0 });
  totals.filter(x => x.action !== actionTypes.deleted).forEach(item => {
    const dayNumber = moment(item.date).day();
    const index = (dayNumber + 6) % 7;
    if (totalHoursPerDay[index].date) {
      totalHoursPerDay[index].hours += item.hours;
    } else {
      totalHoursPerDay[index] = { ...item };
    }
  });
  return totalHoursPerDay;
};

export default getTotalTime;
