import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DateRangePickerField from '../../fields/DateRangePickerFIeld';

const PREFIX = 'SickListModalForm';

const classes = {
  flexContainer: `${PREFIX}-flexContainer`,
  paragraph: `${PREFIX}-paragraph`,
  button: `${PREFIX}-button`,
};

const Root = styled('form')(({ theme }) => ({
  [`& .${classes.paragraph}`]: {
    fontSize: '14px',
    textAlign: 'center',
    marginBottom: '20px',
    marginTop: '20px',
  },

  [`& .${classes.button}`]: {
    margin: '5px 0px 0 10px',
    padding: '4px 16px',
    fontSize: '18px',
    minWidth: 180,
  },

  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5% 0px',
  },
}));

const AddUpdateSickListForm = ({ handleSubmit, onCancel }) => (
  <Root onSubmit={handleSubmit}>
    <DateRangePickerField name="dateRange" />
    <Typography className={classes.paragraph}>
      Please do not forget to bring the sick list to the accounting department
    </Typography>
    <div className={classes.flexContainer}>
      <Button onClick={onCancel} color="cancelButton" variant="contained" className={classes.button}>
        Cancel
      </Button>
      <Button color="secondary" type="submit" variant="contained" className={classes.button}>
        Save
      </Button>
    </div>
  </Root>
);

export default AddUpdateSickListForm;
