import axios from 'axios';
import { SESSION_TERMINATED, USER_FOUND } from 'redux-oidc';

const accessTokenHandlerMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case USER_FOUND:
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + action.payload.access_token;
      break;
    case SESSION_TERMINATED:
      delete axios.defaults.headers.common['Authorization'];
      break;
    default:
  }
  return next(action);
};

export default accessTokenHandlerMiddleware;
