import moment from 'moment';
import { ACTION_TYPES, MODAL_TYPES } from './modalsConstants';
import { SickListModalActions } from '../../../components/modals/SickListModal/SickListModalActions';

export const showUpdateActivityModal = (id, name, activityType, description) => {
  return {
    type: ACTION_TYPES.SHOW_MODAL,
    payload: {
      modalType: MODAL_TYPES.UPDATE_ACTIVITY,
      modalProps: {
        id,
        name,
        activityType,
        description,
      },
    },
  };
};

export const showOOOActivitiesModal = (vacationHours, sickLeaveHours, unpaidLeaveHours) => {
  return {
    type: ACTION_TYPES.SHOW_MODAL,
    payload: {
      modalType: MODAL_TYPES.OOO_ACTIVITIES_MODAL,
      modalProps: {
        vacationHours,
        sickLeaveHours,
        unpaidLeaveHours,
      },
    },
  };
};

export const showPdfReportModal = (firstDayOfMonth, firstDayOfWeek, userId, userFullName) => {
  return {
    type: ACTION_TYPES.SHOW_MODAL,
    payload: {
      modalType: MODAL_TYPES.PDF_REPORT_MODAL,
      modalProps: {
        firstDayOfMonth,
        firstDayOfWeek,
        userId,
        userFullName,
      },
    },
  };
};

export const showExcelReportModal = (firstDayOfMonth, year, userId, userFullName) => {
  return {
    type: ACTION_TYPES.SHOW_MODAL,
    payload: {
      modalType: MODAL_TYPES.EXCEL_REPORT_MODAL,
      modalProps: {
        firstDayOfMonth,
        year,
        userId,
        userFullName,
      },
    },
  };
};

export const showAddSickListModal = (userId, onSave) => {
  return {
    type: ACTION_TYPES.SHOW_MODAL,
    payload: {
      modalType: MODAL_TYPES.SICK_LIST_MODAL,
      modalProps: {
        userId,
        title: 'Add Sick List',
        action: SickListModalActions.ADD,
        dateRange: [moment().startOf('week'), moment().endOf('week')],
        onSave,
      },
    },
  };
};

export const showUpdateSickListModal = (sickList, onSave) => {
  return {
    type: ACTION_TYPES.SHOW_MODAL,
    payload: {
      modalType: MODAL_TYPES.SICK_LIST_MODAL,
      modalProps: {
        dateRange: [moment(sickList.startDate), moment(sickList.endDate)],
        title: 'Update Sick List',
        action: SickListModalActions.UPDATE,
        sickListId: sickList.id,
        onSave,
      },
    },
  };
};

export const showUpdateResourceManagerModal = (userId, currentResourceManager) => {
  return {
    type: ACTION_TYPES.SHOW_MODAL,
    payload: {
      modalType: MODAL_TYPES.UPDATE_RESOURCE_MANAGER_MODAL,
      modalProps: {
        userId,
        resourceManager: currentResourceManager && {
          value: currentResourceManager.id,
          label: `${currentResourceManager.firstName} ${currentResourceManager.lastName}`,
        },
      },
    },
  };
};

export const showMyManagersModal = () => {
  return {
    type: ACTION_TYPES.SHOW_MODAL,
    payload: {
      modalType: MODAL_TYPES.MY_MANAGERS,
    },
  };
};

export const showExportWeekChangeHistoryModal = (userId, userName) => {
  return {
    type: ACTION_TYPES.SHOW_MODAL,
    payload: {
      modalType: MODAL_TYPES.EXPORT_WEEK_CHANGE_HISTORY,
      modalProps: {
        userId,
        title: `Export time track week change history for user ${userName}`,
        dateRange: [moment().startOf('week'), moment().endOf('week')],
      },
    },
  };
};

export const hideModal = () => {
  return {
    type: ACTION_TYPES.HIDE_MODAL,
  };
};
