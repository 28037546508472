import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Spinner from '../Spinner/Spinner';
import IntersectionObserverComponent from '../IntersectionObserverComponent/IntersectionObserverComponent';

const InfiniteList = ({ children, isLoading, hasMore, loadMore, ...other }) => {
  return (
    <List {...other}>
      {React.Children.map(children, (child, index) =>
        children.length - 1 === index && hasMore ? (
          <IntersectionObserverComponent isLoading={isLoading} onIntersect={loadMore}>
            {child}
          </IntersectionObserverComponent>
        ) : (
          child
        )
      )}
      {isLoading && (
        <ListItem>
          <Spinner size={20} thickness={4} />
        </ListItem>
      )}
    </List>
  );
};

export default InfiniteList;
