import { connect } from 'react-redux';
import MonthlyExcelReport from './MonthlyExcelReport';
import { getMonthlyUserActivities } from '../../../../redux/modules/activities/activitiesActions';

const mapStateToProps = state => ({
  userActivities: state.activities.monthlyUserActivities,
  isLoading: state.activities.isLoading,
});

const mapDispatchToProps = {
  getMonthlyUserActivities,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthlyExcelReport);
