import React, { useEffect } from 'react';
import userManager from '../../../oidc/userManager';
import FullScreenLoader from '../../common/FullScreenLoader/FullScreenLoader';

const LoginComponent = ({ redirectPath }) => {
  useEffect(() => {
    userManager.signinRedirect({ data: { redirectUrl: redirectPath } });
  }, [redirectPath]);

  return <FullScreenLoader />;
};

export default LoginComponent;
