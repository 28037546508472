export default Object.freeze({
  EMPLOYEES_PENDING: 'EMPLOYEES_PENDING',
  GET_EMPLOYEES: 'GET_EMPLOYEES',
  GET_SUBORDINATES_BY_USER_ID: 'GET_SUBORDINATES_BY_USER_ID',
  GET_MANAGERS_BY_USER_ID: 'GET_MANAGERS_BY_USER_ID',
  ADD_SUBORDINATE_TO_USER: 'ADD_SUBORDINATE_TO_USER',
  REMOVE_SUBORDINATE_FROM_USER: 'REMOVE_SUBORDINATE_FROM_USER',
  CLEAR_EMPLOYEES: 'CLEAR_EMPLOYEES',
  ARCHIVE_USER: 'ARCHIVE_USER',
  UNARCHIVE_USER: 'UNARCHIVE_USER',
  GET_RESOURCE_MANAGERS: 'GET_RESOURCE_MANAGERS',
  UPDATE_RESOURCE_MANAGER: 'UPDATE_RESOURCE_MANAGER',
  GET_MY_MANAGERS: 'GET_MY_MANAGERS',
});
