import React, { useEffect } from 'react';
import YearlyExcelReportFormContainer from './YearlyExcelReportFormContainer';
import TableBodySpinner from '../../../common/TableSpinners/TableBodySpinner';
import Table from '@mui/material/Table';
import YearPicker from '../../../common/DatePickers/YearPicker';

const YearlyExcelReport = (props) => {
  const { hideModal, year, yearChanged, userId, isLoading, getYearlyUserActivities, userActivities } = props;

  useEffect(() => {
    getYearlyUserActivities(year, userId);
  }, [year, userId, getYearlyUserActivities]);

  return (
    <>
      <YearPicker year={year} yearChanged={yearChanged} />
      {isLoading ? (
        <Table>
          <TableBodySpinner />
        </Table>
      ) : (
        <YearlyExcelReportFormContainer
          onCancel={hideModal}
          userId={userId}
          year={year}
          userActivities={userActivities}
        />
      )}
    </>
  );
};

export default YearlyExcelReport;
