import { connect } from 'react-redux';
import UsersColumn from './UsersColumn';
import { getUsersForYearlyReport, yearlyReportSelector } from '../../../../redux/modules/report/reportSelectors';

const mapStateToProps = (state) => ({
  users: getUsersForYearlyReport(state),
  totalUsers: yearlyReportSelector(state).totalItems,
});

export default connect(mapStateToProps)(UsersColumn);
