import React, { useEffect, useRef } from 'react';

export default function withDragScrolling(WrappedComponent) {
  const ScrollingComponent = (props) => {
    const mouseState = useRef({
      isMouseDown: false,
      lastMousePositionX: null,
      lastMousePositionY: null,
    });

    const childRef = useRef();

    const onMouseDownHandler = (event) => {
      event.preventDefault();
      mouseState.current = {
        isMouseDown: true,
        lastMousePositionX: event.clientX,
        lastMousePositionY: event.clientY,
      };
    };

    const onMouseUpHandler = (event) => {
      event.preventDefault();
      mouseState.current = {
        isMouseDown: false,
        lastMousePositionX: null,
        lastMousePositionY: null,
      };
    };

    const onMouseMoveHandler = (event) => {
      const scrollComponent = childRef.current;

      if (!mouseState.current.isMouseDown || scrollComponent == null) {
        return;
      }

      const { lastMousePositionX, lastMousePositionY } = mouseState.current;

      if (typeof scrollComponent.scrollLeft === 'function') {
        scrollComponent.scrollLeft(scrollComponent.getScrollLeft() + lastMousePositionX - event.clientX);
        scrollComponent.scrollTop(scrollComponent.getScrollTop() + lastMousePositionY - event.clientY);
      } else {
        scrollComponent.scrollLeft += lastMousePositionX - event.clientX;
        scrollComponent.scrollTop += lastMousePositionY - event.clientY;
      }

      mouseState.current = {
        isMouseDown: true,
        lastMousePositionX: event.clientX,
        lastMousePositionY: event.clientY,
      };
    };

    useEffect(() => {
      if (!document.documentElement) {
        return;
      }

      document.documentElement.addEventListener('mouseup', onMouseUpHandler);
      document.documentElement.addEventListener('mousemove', onMouseMoveHandler);

      return () => {
        document.documentElement.removeEventListener('mousemove', onMouseMoveHandler);
        document.documentElement.removeEventListener('mouseup', onMouseUpHandler);
      };
    }, []);

    return <WrappedComponent onMouseDown={onMouseDownHandler} forwardedRef={childRef} {...props} />;
  };
  return ScrollingComponent;
}
