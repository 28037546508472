export const MODAL_TYPES = {
  UPDATE_ACTIVITY: 'UPDATE_ACTIVITY',
  OOO_ACTIVITIES_MODAL: 'OOO_ACTIVITIES_MODAL',
  PDF_REPORT_MODAL: 'PDF_REPORT_MODAL',
  EXCEL_REPORT_MODAL: 'EXCEL_REPORT_MODAL',
  SICK_LIST_MODAL: 'SICK_LIST_MODAL',
  UPDATE_RESOURCE_MANAGER_MODAL: 'UPDATE_RESOURCE_MANAGER_MODAL',
  MY_MANAGERS: 'MY_MANAGERS',
  EXPORT_WEEK_CHANGE_HISTORY: 'EXPORT_WEEK_CHANGE_HISTORY',
};

export const ACTION_TYPES = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
};
