import React from 'react';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TableBodySpinner from '../../common/TableSpinners/TableBodySpinner';
import moment from 'moment';

const PREFIX = 'VacationHistoryTable';

const classes = {
  table: `${PREFIX}-table`,
  tableBody: `${PREFIX}-tableBody`,
  tableHeader: `${PREFIX}-tableHeader`,
  headerText: `${PREFIX}-headerText`,
  centeredCell: `${PREFIX}-centeredCell`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.whitegrey,
  boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',

  [`& .${classes.table}`]: {
    minWidth: 700,
  },

  [`& .${classes.tableBody}`]: {
    minHeight: '30vh',
    overflowY: 'auto',
  },

  [`& .${classes.tableHeader}`]: {
    marginBottom: '10px',
    padding: '20px 5px',
    paddingLeft: '25px',
    backgroundColor: theme.palette.grey.light,
  },

  [`& .${classes.headerText}`]: {
    color: theme.palette.grey.dark,
  },

  [`& .${classes.centeredCell}`]: {
    textAlign: 'center',
  },
}));

const VacationHistoryTable = ({ history, isLoading, pagination, onPageChange }) => {
  const numberOfPages = Math.ceil(pagination.totalItems / pagination.itemsPerPage);

  const HeaderCell = ({ children, ...props }) => (
    <TableCell {...props}>
      <Typography className={classes.headerText}>{children}</Typography>
    </TableCell>
  );

  const renderRow = (vacation) => (
    <TableRow key={vacation.recordId}>
      <TableCell>{vacation.vacationType}</TableCell>
      <TableCell className={classes.centeredCell}>{moment(vacation.date).format('DD.MM.yyyy')}</TableCell>
      <TableCell className={classes.centeredCell}>{vacation.hours}</TableCell>
    </TableRow>
  );

  return (
    <>
      <StyledPaper elevation={2}>
        <Table className={classes.table}>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <HeaderCell>Vacation Type</HeaderCell>
              <HeaderCell className={classes.centeredCell}>Date</HeaderCell>
              <HeaderCell className={classes.centeredCell}>Hours</HeaderCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBodySpinner colSpan={3} />
          ) : (
            <TableBody>
              {history && history.length > 0 ? (
                history.map(renderRow)
              ) : (
                <TableRow>
                  <TableCell className={classes.centeredCell} colSpan={6}>
                    <Typography fontSize="large" color="grey">
                      No records found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        <Pagination count={numberOfPages} page={pagination.pageNumber} onChange={onPageChange} />
      </StyledPaper>
    </>
  );
};

export default VacationHistoryTable;
