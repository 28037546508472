import { connect } from 'react-redux';
import HoursRow from './HoursRow';
import renderTotalHoursPerDay from '../../../helper/totalHoursPerDay';
import {
  updateTimeTrackRecord,
  addTimeTrackRecord,
  deleteTimeTrackRecord,
  updateEmptyTimeTrackRecord,
} from '../../../../redux/modules/records/recordsActions';

const mapStateToProps = (state, props) => ({
  firstDayOfWeek: state.timeTrackWeek.firstDayOfWeek,
  totalHoursPerDay: renderTotalHoursPerDay(props.totalHoursPerDay),
});

const mapDispatchToProps = {
  updateTimeTrackRecord,
  addTimeTrackRecord,
  deleteTimeTrackRecord,
  updateEmptyTimeTrackRecord,
};

export const ROW_TYPE = {
  INPUT: 'INPUT',
  TYPOGRAPHY: 'TYPOGRAPHY',
};

export default connect(mapStateToProps, mapDispatchToProps)(HoursRow);
