import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import InputField from '../../../fields/InputField';
import CheckboxField from '../../../fields/CheckboxField';
import EmployeeStatusSelectField from '../../../fields/EmployeeStatusSelectField';
import UsersSelectField from '../../../fields/UsersSelectField';
import { useSelector } from 'react-redux';
import { selectResourceManagers } from '../../../../redux/modules/employees/employeesSelectors';

const PREFIX = 'FilterForm';

const classes = {
  select: `${PREFIX}-select`,
  input: `${PREFIX}-input`,
  flexContainer: `${PREFIX}-flexContainer`,
  resourceManagersSelect: `${PREFIX}-resourceManagersSelect`,
};

const Root = styled('form')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.white,
  width: '100%',

  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.select}`]: {
    border: 'none',
    width: '180px',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '5px 0px 5px 0px',
    fontFamily: theme.typography.fontFamily,
  },
  [`& .${classes.resourceManagersSelect}`]: {
    border: 'none',
    width: '260px',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '5px 0px 5px 10px',
    fontFamily: theme.typography.fontFamily,
  },
  [`& .${classes.input}`]: {
    border: 'none',
    overflow: 'auto',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    width: '30%',
    fontFamily: theme.typography.fontFamily,
  },
}));

// TODO: prevent search form from forcing re-rendering the parent component onChange, onBlur and onFocus
const SearchUserForm = ({ handleSubmit, withSubordinatesFilter }) => {
  const resourceManagers = useSelector(selectResourceManagers);
  return (
    <Root onSubmit={handleSubmit}>
      <div className={classes.flexContainer}>
        <EmployeeStatusSelectField className={classes.select} name="status" />
        <UsersSelectField
          name="resourceManager"
          className={classes.resourceManagersSelect}
          isClearable={true}
          isSearchable={false}
          placeholder="Resource Manager"
          options={resourceManagers.map((x) => ({ value: x.id, label: x.name }))}
        />
        {withSubordinatesFilter && (
          <CheckboxField label={<Typography>My Subordinates</Typography>} name="onlyMySubordinates" />
        )}
      </div>
      <InputField
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        className={classes.input}
        name="name"
        label="Search"
      />
    </Root>
  );
};

export default SearchUserForm;
