import VACATION_CONSTANTS from './vacationsConstants';

const defaultState = {
  history: [],
  totalItems: 0,
  isLoading: false,
};

const vacationReducers = (state = defaultState, action) => {
  switch (action.type) {
    case VACATION_CONSTANTS.VACATION_PENDING:
      return handleVacationPending(state, action.payload);
    case VACATION_CONSTANTS.GET_VACATION_HISTORY:
      return handleGetVacationHistory(state, action.payload);
    default:
      return state;
  }
};

function handleVacationPending(state, isLoading) {
  return {
    ...state,
    isLoading: isLoading,
  };
}

function handleGetVacationHistory(state, payload) {
  return {
    ...state,
    history: payload.items,
    totalItems: payload.totalItems,
  };
}

export default vacationReducers;
