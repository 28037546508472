import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import UsersSelectField from '../../fields/UsersSelectField';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import { selectAllUsers, selectUserInfo } from '../../../redux/modules/users/usersSelectors';
import { clearAllUsers, getAllUsers, getUserById } from '../../../redux/modules/users/usersActions';
import paginationConstants from '../../helper/paginationConstants';
import employeeStatuses from '../../helper/employeeStatuses';
import Notification from '../../common/Notification/Notification';
import { updateUserResourceManager } from '../../../redux/modules/employees/employeesActions';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect';
import roleConstants from '../../helper/roleConstants';

const PREFIX = 'UpdateActivityForm';

const classes = {
  select: `${PREFIX}-select`,
  flexContainer: `${PREFIX}-flexContainer`,
};

const Root = styled('form')(({ theme }) => ({
  [`& .${classes.select}`]: {
    border: 'none',
    width: '50%',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '20px auto',
    fontFamily: theme.typography.fontFamily,
  },
  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    gap: '5%',
  },
}));

const UpdateResourceManagerForm = ({ userId, handleSubmit, onCancel }) => {
  const dispatch = useDispatch();
  const userToUpdate = useSelector(selectUserInfo);
  const allUsers = useSelector(selectAllUsers);

  const [allUsersPagination, setAllUsersPagination] = useState({
    page: 1,
    isLoading: false,
    search: '',
  });

  useEffect(() => {
    dispatch(getUserById(userId));
    dispatch(clearAllUsers());
    return () => {
      dispatch(clearAllUsers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useDebouncedEffect(
    () => {
      dispatch(
        getAllUsers(
          allUsersPagination.page,
          paginationConstants.itemsPerPage,
          allUsersPagination.search,
          employeeStatuses.active,
          [roleConstants.manager, roleConstants.superManager]
        )
      );
      setAllUsersPagination((prev) => ({ ...prev, isLoading: false }));
    },
    allUsersPagination.search ? 1000 : 0,
    [allUsersPagination.page, allUsersPagination.search, userId]
  );

  const allUsersOptions = useMemo(
    () => allUsers.items.filter((user) => user.id !== userId).map((user) => ({ value: user.id, label: user.name })),
    [allUsers.items, userId]
  );

  const handleAllUsersSearchChange = useCallback(
    (value) => {
      if (value !== allUsersPagination.search) {
        dispatch(clearAllUsers());
        setAllUsersPagination({ page: 1, search: value, isLoading: true });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allUsersPagination.search]
  );
  const handleAllUsersPageChange = useCallback(() => {
    setAllUsersPagination((prev) => ({ ...prev, page: prev.page + 1, isLoading: true }));
  }, []);

  return (
    <Root onSubmit={handleSubmit}>
      <Typography variant="h6" align="center">
        Update Resource Manager for {userToUpdate?.name}
      </Typography>
      <UsersSelectField
        placeholder="Resource Managers"
        className={classes.select}
        name="resourceManager"
        options={allUsersOptions}
        loadMore={handleAllUsersPageChange}
        hasMore={allUsers.totalItems > allUsers.items?.length}
        onInputChange={handleAllUsersSearchChange}
        isLoading={allUsersPagination.isLoading}
        isClearable
      />
      <div className={classes.flexContainer}>
        <Button variant="contained" size="large" color="cancelButton" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" size="large" variant="contained" color="secondary">
          Save
        </Button>
      </div>
    </Root>
  );
};

const selector = formValueSelector('updateActivityForm');

const mapStateToProps = (state) => ({
  initialValues: state.modals.modalProps,
  resourceManager: selector(state, 'resourceManager'),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'updateResourceManagerForm',
    onSubmit: (payload, dispatch, props) => {
      dispatch(updateUserResourceManager(props.userId, payload.resourceManager?.value)).then(({ status, text }) => {
        if (status === 500 || status === 404 || status === 400) {
          Notification('warning', text)();
        } else {
          Notification('success')();
        }
      });
      props.onCancel();
    },
  })(UpdateResourceManagerForm)
);
