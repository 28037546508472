import React from 'react';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import AddUpdateSickListFormContainer from './AddUpdateSickListFormContainer';

const PREFIX = 'SickListModal';

const classes = {
  paper: `${PREFIX}-paper`,
  title: `${PREFIX}-title`,
  paragraph: `${PREFIX}-paragraph`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },

  [`& .${classes.title}`]: {
    fontSize: '20px',
    textAlign: 'center',
    margin: '10px',
  },

  [`& .${classes.paragraph}`]: {
    fontSize: '14px',
    textAlign: 'center',
    marginBottom: '20px',
    marginTop: '20px',
  },
}));

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const SickListModal = ({ hideModal, title, action, onSave }) => {
  const handleSave = () => {
    if (onSave) {
      onSave();
    }
    hideModal();
  };

  return (
    <StyledModal open={true} disableAutoFocus>
      <div style={getModalStyle()} className={classes.paper}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.paragraph}>Enter dates of your sick list</Typography>
        <AddUpdateSickListFormContainer onCancel={hideModal} onSave={handleSave} action={action} />
      </div>
    </StyledModal>
  );
};

export default SickListModal;
