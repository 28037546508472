import React from 'react';
import { styled } from '@mui/material/styles';
import NavigationSidebar from './NavigationSidebar/NavigationSidebar';

const PREFIX = 'NavigationSidebarWrapper';

const classes = {
  root: `${PREFIX}-root`,
  sidebarWrapper: `${PREFIX}-sidebarWrapper`,
  contentWrapper: `${PREFIX}-contentWrapper`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',

  [`& .${classes.sidebarWrapper}`]: {
    display: 'flex',
    flex: '0 0 158px',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 20px',
    background: `linear-gradient(to bottom, ${theme.palette.secondary.dark}, ${theme.palette.secondary.light})`,
    [theme.breakpoints.down('md')]: {
      marginLeft: '-200px',
    },
  },

  [`& .${classes.contentWrapper}`]: {
    display: 'flex',
    flex: '1 1 auto',
    width: '70%',
    flexDirection: 'column',
  },
}));

const NavigationSidebarWrapper = ({ children, isSidebarOpen }) => {
  return (
    <Root>
      <div className={classes.sidebarWrapper}>
        <NavigationSidebar isSidebarOpen={isSidebarOpen} />
      </div>
      <div className={classes.contentWrapper}>{children}</div>
    </Root>
  );
};

export default NavigationSidebarWrapper;
