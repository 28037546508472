import * as activitiesRequests from '../../../axios/activities';
import ACTIVITIES_CONSTANTS from './activitiesConstants';
import { hideModal } from '../modals/modalsActions';
import { parseErrors } from '../../../components/helper/requestsHelpers';

export const addActivity = (name, activityType) => async (dispatch) => {
  try {
    const response = await activitiesRequests.addActivity(name, activityType);

    dispatch({
      type: ACTIVITIES_CONSTANTS.ADD_ACTIVITY,
      payload: {
        name,
        type: activityType,
        id: response.data,
      },
    });
    return { status: response.status };
  } catch (e) {
    return await parseErrors(e);
  }
};

export const deleteActivity = (id) => async (dispatch) => {
  await activitiesRequests.deleteActivity(id);
  dispatch({
    type: ACTIVITIES_CONSTANTS.DELETE_ACTIVITY,
    payload: {
      id,
    },
  });
};

export const updateActivity = (id, name, activityType, description) => async (dispatch) => {
  try {
    const response = await activitiesRequests.updateActivity(id, name, activityType, description);

    dispatch({
      type: ACTIVITIES_CONSTANTS.UPDATE_ACTIVITY,
      payload: {
        id,
        name,
        activityType,
        description,
      },
    });
    dispatch(hideModal());
    return { status: response.status };
  } catch (e) {
    return await parseErrors(e);
  }
};

export const getUserActivities = (userId) => async (dispatch) => {
  dispatch({
    type: ACTIVITIES_CONSTANTS.ACTIVITIES_PENDING,
    payload: true,
  });

  const response = await activitiesRequests.getUserActivities(userId);

  dispatch({
    type: ACTIVITIES_CONSTANTS.GET_USER_ACTIVITIES,
    payload: response.data.map((activity) => ({ id: activity.activityId, name: activity.activityName, ...activity })),
  });
};

export const getMonthlyUserActivities = (firstDayOfMonth, userId) => async (dispatch) => {
  dispatch({
    type: ACTIVITIES_CONSTANTS.MONTHLY_USER_ACTIVITIES_PENDING,
    payload: true,
  });

  const response = await activitiesRequests.getMonthlyUserActivities(firstDayOfMonth, userId);

  dispatch({
    type: ACTIVITIES_CONSTANTS.GET_MONTHLY_USER_ACTIVITIES,
    payload: response.data,
  });
};

export const getWeeklyUserActivities = (firstDayOfWeek, userId) => async (dispatch) => {
  dispatch({
    type: ACTIVITIES_CONSTANTS.WEEKLY_USER_ACTIVITIES_PENDING,
    payload: true,
  });
  const response = await activitiesRequests.getWeeklyUserActivities(firstDayOfWeek, userId);

  dispatch({
    type: ACTIVITIES_CONSTANTS.GET_WEEKLY_USER_ACTIVITIES,
    payload: response.data,
  });
};

export const getYearlyUserActivities = (year, userId) => async (dispatch) => {
  dispatch({
    type: ACTIVITIES_CONSTANTS.YEARLY_USER_ACTIVITIES_PENDING,
    payload: true,
  });

  const response = await activitiesRequests.getYearlyUserActivities(year, userId);

  dispatch({
    type: ACTIVITIES_CONSTANTS.GET_YEARLY_USER_ACTIVITIES,
    payload: response.data,
  });
};

export const getActivities = (pageNumber, pageSize, name, excludeIds) => async (dispatch) => {
  dispatch({
    type: ACTIVITIES_CONSTANTS.MONTHLY_USER_ACTIVITIES_PENDING,
    payload: true,
  });

  const response = await activitiesRequests.getActivities(pageNumber, pageSize, name, excludeIds);

  dispatch({
    type: ACTIVITIES_CONSTANTS.GET_ACTIVITIES,
    payload: { activities: response.data.items, totalItems: response.data.totalItems },
  });
};

export const addUserToActivity = (activity) => async (dispatch, getState) => {
  const userId = getState().users.currentUserId;
  await activitiesRequests.addUserToActivity(activity.id, userId);
  dispatch({
    type: ACTIVITIES_CONSTANTS.ADD_USER_TO_ACTIVITY,
    payload: {
      activity,
      userId,
    },
  });
};

export const deleteUserFromActivity = (activityId) => async (dispatch, getState) => {
  const userId = getState().users.currentUserId;
  await activitiesRequests.deleteUserFromActivity(activityId, userId);
  dispatch({
    type: ACTIVITIES_CONSTANTS.DELETE_USER_FROM_ACTIVITY,
    payload: {
      activityId,
      userId,
    },
  });
};

export const clearActivities = () => (dispatch) => {
  dispatch({
    type: ACTIVITIES_CONSTANTS.CLEAR_ACTIVITIES,
  });
};

export const clearUserActivities = () => (dispatch) => {
  dispatch({
    type: ACTIVITIES_CONSTANTS.CLEAR_USER_ACTIVITIES,
  });
};
