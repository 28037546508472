import React from 'react';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import moment from 'moment';
import IntersectionObserverComponent from '../../../common/IntersectionObserverComponent/IntersectionObserverComponent';

const PREFIX = 'UsersColumn';

const classes = {
  usersColumn: `${PREFIX}-usersColumn`,
  flexContainer: `${PREFIX}-flexContainer`,
  userCell: `${PREFIX}-userCell`,
  headerContainer: `${PREFIX}-headerContainer`,
  headerText: `${PREFIX}-headerText`,
  userNameLink: `${PREFIX}-userNameLink`,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${classes.usersColumn}`]: {
    flex: '1 1 15%',
    padding: '4px 0px',
    border: 'none',
  },

  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.userCell}`]: {
    height: '58px',
    margin: '2.5px',
    display: 'flex',
    padding: '0 5px 0 25px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: theme.palette.whiteblue,
    color: theme.palette.grey.dark,
  },

  [`& .${classes.headerContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '10px',
    padding: '20px 5px',
    paddingLeft: '25px',
    backgroundColor: theme.palette.grey.light,
  },

  [`& .${classes.headerText}`]: {
    color: theme.palette.grey.dark,
  },

  [`& .${classes.userNameLink}`]: {
    textDecoration: 'none',
    color: theme.palette.grey.dark,
  },
}));

const UsersColumn = (props) => {
  const { users, totalUsers, firstDayOfMonth, loadMore, isLoading } = props;
  const hasMore = users.length < totalUsers;

  let query = '';
  if (!moment(firstDayOfMonth).isSame(new Date(), 'month')) {
    const firstDayOfWeek = moment(firstDayOfMonth).startOf('week').isoWeekday(1).format('YYYY-MM-DD');
    query = `/?firstDayOfWeek=${firstDayOfWeek}`;
  }

  const renderUser = (user) => (
    <div key={user.id} className={classes.userCell}>
      <Typography>
        <Link className={classes.userNameLink} to={`/employee/${user.id}/week${query}`}>
          {user.name}
        </Link>
      </Typography>
    </div>
  );

  return (
    <StyledTableCell className={classes.usersColumn}>
      <div className={classes.headerContainer}>
        <Typography className={classes.headerText}>Employee</Typography>
      </div>
      <div className={classes.flexContainer}>
        {users.map((user, index) =>
          users.length - 1 === index && hasMore ? (
            <IntersectionObserverComponent key="observer" isLoading={isLoading} onIntersect={loadMore}>
              {renderUser(user)}
            </IntersectionObserverComponent>
          ) : (
            renderUser(user)
          )
        )}
      </div>
    </StyledTableCell>
  );
};

UsersColumn.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UsersColumn;
