import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import ReportsPage from './pages/Reports/ReportsPage';
import EmployeesPage from './pages/EmployeesPage/EmployeesPage';
import ActivitiesPage from './pages/ActivitiesPage/ActivitiesPage';
import CallbackComponent from './pages/Callback/CallbackComponent';
import UserActivitiesPage from './pages/UserActivitiesPage/UserActivitiesPage';
import UserWeekTimesheetPage from './pages/UserWeekTimesheetPage/UserWeekTimesheetPage';
import roleConstants from './helper/roleConstants';
import withAuthorization from '../hocs/withAuthorization';
import NavigationSidebarWrapper from './common/NavigationSidebarWrapper/NavigationSidebarWrapper';
import ManagersPage from './pages/EmployeesPage/ManagersPage';
import SubordinatesPage from './pages/EmployeesPage/SubordinatesPage';
import ManageSubordinatesPage from './pages/ManageSubordinatesPage/ManageSubordinatesPage';
import VacationHistoryPage from './pages/VacationHisotryPage/VacationHistoryPage';

const Manager = withAuthorization([roleConstants.superManager, roleConstants.manager]);
const User = withAuthorization([roleConstants.superManager, roleConstants.manager, roleConstants.employee]);
const UserEditor = withAuthorization([roleConstants.superManager, roleConstants.userEditor]);

const UserActivitiesTablePageWithUserRoles = User(UserWeekTimesheetPage);
const ActivitiesPageWithRoles = Manager(ActivitiesPage);
const UserActivitiesPageWithRoles = Manager(UserActivitiesPage);
const ReportsPageWithRoles = Manager(ReportsPage, '/');
const UserActivitiesTablePageWithManagerRoles = Manager(UserWeekTimesheetPage);
const EmployeesPageWithRoles = Manager(EmployeesPage);
const ManagersPageWithRoles = Manager(ManagersPage);
const SubordinatesPageWithRoles = Manager(SubordinatesPage);
const ManageSubordinatesPageWithRoles = UserEditor(ManageSubordinatesPage);
const VacationHistoryPageWithRoles = User(VacationHistoryPage);

export default function AppRoutes(props) {
  return (
    <BrowserRouter>
      <NavigationSidebarWrapper>
        <Routes>
          <Route exact path="/" element={<UserActivitiesTablePageWithUserRoles />} />
          <Route exact path="/activities" element={<ActivitiesPageWithRoles />} />
          <Route path="/users" element={<UserActivitiesPageWithRoles />} />
          <Route exact path="/reports/monthly" element={<ReportsPageWithRoles />} />
          <Route exact path="/reports/yearly" element={<ReportsPageWithRoles />} />
          <Route exact path="/employee/:id/week" element={<UserActivitiesTablePageWithManagerRoles />} />
          <Route exact path="/callback" element={<CallbackComponent />} />
          <Route exact path="/employees" element={<EmployeesPageWithRoles />} />
          <Route exact path="/employees/:id/managers" element={<ManagersPageWithRoles />} />
          <Route exact path="/employees/:id/subordinates" element={<SubordinatesPageWithRoles />} />
          <Route exact path="/subordinates" element={<ManageSubordinatesPageWithRoles />} />
          <Route exact path="/vacation" element={<VacationHistoryPageWithRoles />} />

          <Route path="*" render={() => <Navigate to="/" replace />} />
        </Routes>
      </NavigationSidebarWrapper>
    </BrowserRouter>
  );
}
