import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { clearActivities } from '../../../../redux/modules/activities/activitiesActions';
import ActivitiesSearchForm from './ActivitiesSearchForm';

const selector = formValueSelector('searchActivityForm');

const mapStateToProps = state => ({
  name: selector(state, 'name'),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'searchActivityForm',
    onSubmit: (payload, dispatch, { onSearch }) => {
      if (onSearch) {
        onSearch(payload.name);
      }
      dispatch(clearActivities());
    },
  })(ActivitiesSearchForm)
);
