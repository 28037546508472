import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import UserRecordsRow from './UserRecordsRow';
import { getDaysOfMonthWithFormat } from '../../../../helper/momentJsExtensions';
import withDragScrolling from '../../../../../hocs/withDragScrolling';

const PREFIX = 'RecordsColumn';

const classes = {
  recordsColumn: `${PREFIX}-recordsColumn`,
  bodyContainer: `${PREFIX}-bodyContainer`,
  headerContainer: `${PREFIX}-headerContainer`,
  headerDateCell: `${PREFIX}-headerDateCell`,
  scrollBar: `${PREFIX}-scrollBar`,
  thumb: `${PREFIX}-thumb`,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${classes.recordsColumn}`]: {
    flex: '2 1 70%',
    padding: '4px 0px',
    overflow: 'auto',
    border: 'none',
  },

  [`& .${classes.bodyContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.headerContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
  },

  [`& .${classes.headerDateCell}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 0px',
    minWidth: '39px',
    backgroundColor: theme.palette.grey.light,
    color: theme.palette.grey.dark,
  },

  [`& .${classes.scrollBar}`]: {
    width: 'auto',
    height: 'auto',
    overflowY: 'hidden',
  },

  [`& .${classes.thumb}`]: {
    cursor: 'pointer',
    borderRadius: 'inherit',
    backgroundColor: theme.palette.grey.main,
  },
}));

const RecordsColumn = (props) => {
  const { reports, firstDayOfMonth, onMouseDown, forwardedRef } = props;
  const dates = getDaysOfMonthWithFormat(firstDayOfMonth, 'D ddd');
  return (
    <StyledTableCell className={classes.recordsColumn} onMouseDown={onMouseDown}>
      <Scrollbars
        className={classes.scrollBar}
        ref={forwardedRef}
        renderView={(props) => <div {...props} style={{ ...props.style, overflowY: 'hidden' }} />}
        renderThumbHorizontal={(props) => <div {...props} className={classes.thumb} />}
        renderThumbVertical={(props) => <div {...props} className={classes.thumb} />}
      >
        <div className={classes.headerContainer}>
          {dates.map((date) => {
            const dateParts = date.split(' ');
            return (
              <div key={+dateParts[0]}>
                <Typography className={classes.headerDateCell}>{dateParts[0]}</Typography>
                <Typography className={classes.headerDateCell}>{dateParts[1]}</Typography>
              </div>
            );
          })}
        </div>
        <div className={classes.bodyContainer}>
          {reports.map((report) => (
            <UserRecordsRow key={report.userId} records={report.records} firstDayOfMonth={firstDayOfMonth} />
          ))}
        </div>
      </Scrollbars>
    </StyledTableCell>
  );
};

RecordsColumn.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.object).isRequired,
  firstDayOfMonth: PropTypes.object,
};

export default withDragScrolling(RecordsColumn);
