import { connect } from 'react-redux';
import { getWeeklyUserActivities } from '../../../../redux/modules/activities/activitiesActions';
import WeeklyPdfReport from './WeeklyPdfReport';

const mapStateToProps = state => ({
  userActivities: state.activities.weeklyUserActivities,
  isLoading: state.activities.isLoading,
});

const mapDispatchToProps = {
  getWeeklyUserActivities,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeeklyPdfReport);
