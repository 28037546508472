import React from 'react';
import PageTitle from '../../common/PageTitle/PageTitle';
import ForbiddenComponent from './ForbiddenComponent';

const ForbiddenPage = () => {
  return (
    <>
      <PageTitle pageTitle="Forbidden" />
      <ForbiddenComponent pageTitle="Forbidden" />
    </>
  );
};

export default ForbiddenPage;
