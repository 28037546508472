import React from 'react';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import YearlyTotalsCell from './YearlyTotalsCell';

const PREFIX = 'YearlyTotalsColumn';

const classes = {
  totalsColumn: `${PREFIX}-totalsColumn`,
  hoursCell: `${PREFIX}-hoursCell`,
  totalsColumnContainer: `${PREFIX}-totalsColumnContainer`,
  headerContainer: `${PREFIX}-headerContainer`,
  headerText: `${PREFIX}-headerText`,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 0px !important',
  border: 'none',

  [`& .${classes.totalsColumnContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.headerContainer}`]: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0px',
    backgroundColor: theme.palette.grey.light,
  },

  [`& .${classes.headerText}`]: {
    color: theme.palette.grey.dark,
  },
}));

const StyledYearlyTotalsCell = styled(YearlyTotalsCell)(({ theme }) => ({
  minWidth: '58px',
}));

const YearlyTotalsColumn = ({ reports }) => {
  return (
    <StyledTableCell className={classes.totalsColumn}>
      <div className={classes.headerContainer}>
        <Typography className={classes.headerText}>Totals</Typography>
      </div>
      <div className={classes.totalsColumnContainer}>
        {reports.map((report) => (
          <StyledYearlyTotalsCell key={report.userId} yearlyReport={report.records} />
        ))}
      </div>
    </StyledTableCell>
  );
};

export default YearlyTotalsColumn;
