import USERS_CONSTANTS from './usersConstants';
import * as usersRequests from '../../../axios/users';

const setPending = (isPending) => (dispatch) => {
  dispatch({
    type: USERS_CONSTANTS.USERS_PENDING,
    payload: isPending,
  });
};

// this action gets users depending on current user's role (subordinates for manager and all users for supermanager)
export const getUsers = (pageNumber, pageSize, name, roles) => async (dispatch) => {
  dispatch(setPending(true));
  const response = await usersRequests.getUsers(pageNumber, pageSize, name, roles);

  dispatch({
    type: USERS_CONSTANTS.GET_USERS,
    payload: { users: response.data.items, totalItems: response.data.totalItems },
  });
  dispatch(setPending(false));
};

// this action gets all users regardless current user's role
export const getAllUsers = (pageNumber, pageSize, name, status, roles) => async (dispatch) => {
  dispatch(setPending(true));
  const response = await usersRequests.getAllUsers(pageNumber, pageSize, name, status, roles);

  dispatch({
    type: USERS_CONSTANTS.GET_ALL_USERS,
    payload: { allUsers: response.data.items, totalItems: response.data.totalItems },
  });
  dispatch(setPending(false));
};

export const getUserById = (userId) => async (dispatch) => {
  dispatch(setPending(true));
  const response = await usersRequests.getUserById(userId);

  dispatch({
    type: USERS_CONSTANTS.GET_USER_BY_ID,
    payload: response.data,
  });
  dispatch(setPending(false));
};

export const setCurrentUser = (id) => {
  return {
    type: USERS_CONSTANTS.SET_CURRENT_USER,
    payload: id,
  };
};

export const clearCurrentUser = () => {
  return {
    type: USERS_CONSTANTS.CLEAR_CURRENT_USER,
  };
};

export const clearUsers = () => {
  return {
    type: USERS_CONSTANTS.CLEAR_USERS,
  };
};

export const clearAllUsers = () => {
  return {
    type: USERS_CONSTANTS.CLEAR_ALL_USERS,
  };
};

export const clearUserInfo = () => {
  return {
    type: USERS_CONSTANTS.CLEAR_USER_INFO,
  };
};
