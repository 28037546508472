import React from 'react';
import { Field } from 'redux-form';
import InnerSelectField from './InnerSelectField';
import activityTypesForSelect from '../helper/activityTypesForSelect';

const ActivityTypesSelectField = (props) => (
  <Field
    name={props.name}
    options={activityTypesForSelect.map((type) => {
      return { value: type.id, label: type.name };
    })}
    component={InnerSelectField}
    isClearable={true}
    isSearchable={false}
    placeholder="Select activity type..."
    {...props}
  />
);

export default ActivityTypesSelectField;
