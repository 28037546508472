import React from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const PREFIX = 'Spinner';

const classes = {
  loadingWrapper: `${PREFIX}-loadingWrapper`,
};

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  border: 'none',
  backgroundColor: theme.palette.white,
  [`& .${classes.loadingWrapper}`]: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Spinner = (props) => {
  const { size = 60, thickness = 2 } = props;
  return (
    <Root>
      <div className={classes.loadingWrapper}>
        <CircularProgress size={size} thickness={thickness} color="secondary" />
      </div>
    </Root>
  );
};
export default Spinner;
