import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';

const InnerCheckboxField = ({ input, label, className }) => (
  <div>
    <FormControlLabel
      control={<Checkbox checked={!!input.value} onChange={input.onChange} />}
      labelPlacement="start"
      label={label}
      className={className}
    />
  </div>
);

export default InnerCheckboxField;
