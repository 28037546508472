import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { archiveUser, unarchiveUser } from '../../../../redux/modules/employees/employeesActions';
import Notification from '../../../common/Notification/Notification';
import { setCurrentUser } from '../../../../redux/modules/users/usersActions';
import { showUpdateResourceManagerModal } from '../../../../redux/modules/modals/modalsActions';

const StyledMenuButton = styled(Button)(() => ({
  textTransform: 'none',
}));

const ActionsMenu = ({ userId, userIsArchived, resourceManager }) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuIsOpen = Boolean(menuAnchor);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMenuOpen = (e) => {
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleManageSubordinates = () => {
    navigate('/subordinates');
    dispatch(setCurrentUser(userId));
  };

  const handleChangeStatus = async () => {
    let status;
    if (userIsArchived) {
      status = await dispatch(unarchiveUser(userId));
    } else {
      status = await dispatch(archiveUser(userId));
    }
    if (status !== 200) {
      Notification('warning', 'Unexpected error, changes were not saved')();
    }
  };

  const handleUpdateResourceManager = async () => {
    dispatch(showUpdateResourceManagerModal(userId, resourceManager));
  };

  const handleOpenTimesheet = async () => {
    navigate(`/employee/${userId}/week`);
  };

  return (
    <>
      <StyledMenuButton variant="outlined" color="black" onClick={handleMenuOpen}>
        <Typography>Action</Typography>
        {menuIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </StyledMenuButton>
      <Menu
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={menuIsOpen}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
      >
        <MenuItem key="openTimesheet" onClick={handleOpenTimesheet}>
          Open Timesheet
        </MenuItem>
        {!userIsArchived && (
          <MenuItem key="manageSubordinates" onClick={handleManageSubordinates}>
            Manage Subordinates
          </MenuItem>
        )}
        <MenuItem key="updateResourceManager" onClick={handleUpdateResourceManager}>
          Update Resource Manager
        </MenuItem>
        <MenuItem key="changeStatus" onClick={handleChangeStatus}>
          {userIsArchived ? 'Make Active' : 'Archive'}
        </MenuItem>
      </Menu>
    </>
  );
};

export default ActionsMenu;
