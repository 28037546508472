import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import TaskContainer from '../Task/TaskContainer';
import HoursRowContainer, { ROW_TYPE } from '../HoursRow/HoursRowContainer';

const PREFIX = 'Activity';

const classes = {
  activitiesRow: `${PREFIX}-activitiesRow`,
  addDescriptionButton: `${PREFIX}-addDescriptionButton`,
  mainText: `${PREFIX}-mainText`,
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.activitiesRow}`]: {
    paddingLeft: '2.4vw',
  },

  [`& .${classes.addDescriptionButton}`]: {
    color: theme.palette.primary.light,
    fontWeight: 600,
    cursor: 'pointer',
    userSelect: 'none',
    WebkitTouchCallout: 'none',
  },

  [`& .${classes.mainText}`]: {
    color: theme.palette.grey.dark,
  },
}));

const TasksList = ({ tasks, activityType, userActivityId, isLast }) => {
  return tasks.map((item, index, array) => (
    <TaskContainer
      key={item.id}
      description={item.description}
      taskId={item.id}
      activityType={activityType}
      userActivityId={userActivityId}
      isUnderlined={!isLast || (isLast && index !== array.length - 1)}
      focusDescriptionOnMount={item.description.length === 0 && index === array.length - 1}
    />
  ));
};

const Activity = (props) => {
  const { activity, totals, tasks, addTask, isLast } = props;
  const handleAddTaskClick = () => {
    addTask('', activity.activityId);
  };

  return (
    <>
      <StyledTableRow>
        <TableCell className={classes.activitiesRow}>
          <Typography type="subtitle1" className={classes.mainText}>
            {activity.activityTitle}
          </Typography>
          <Typography variant="body1" className={classes.addDescriptionButton} onClick={handleAddTaskClick}>
            Add description
          </Typography>
        </TableCell>
        <HoursRowContainer
          display={ROW_TYPE.TYPOGRAPHY}
          totalHoursPerDay={totals.totalHoursPerDay}
          totalHoursPerWeek={totals.totalHoursPerWeek}
          isUnderlined={true}
        />
      </StyledTableRow>

      <TasksList
        tasks={tasks}
        activityType={activity.activityType}
        userActivityId={activity.activityId}
        isLast={isLast}
      />
    </>
  );
};

Activity.propTypes = {
  activity: PropTypes.shape({
    activityTitle: PropTypes.string.isRequired,
    id: PropTypes.number,
  }),
  tasks: PropTypes.arrayOf(PropTypes.object),
  totals: PropTypes.shape({
    totalHoursPerDay: PropTypes.arrayOf(PropTypes.object).isRequired,
    totalHoursPerWeek: PropTypes.number.isRequired,
  }),
};

export default React.memo(Activity);
