import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import weeks from '../../../helper/weeks';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { weekEventsSelector } from '../../../../redux/modules/records/recordsSelectors';
import { Badge } from '@mui/material';

const PREFIX = 'DaysOfWeek';

const classes = {
  weekdayText: `${PREFIX}-weekdayText`,
};

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isUnderlined' && prop !== 'isWeekEnd' && prop !== 'event',
})(({ theme, isWeekEnd, event }) => {
  let bgColor;
  if (event && event.isHoliday) {
    bgColor = theme.palette.calendar.holiday;
  } else if (!event && isWeekEnd) {
    bgColor = theme.palette.calendar.weekEnd;
  } else {
    bgColor = 'inherit';
  }

  return {
    backgroundColor: bgColor,
    padding: '6px 16px',
    borderBottom: 'none',

    [`& .${classes.weekdayText}`]: {
      textAlign: 'center',
    },
  };
});

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} arrow />)(
  ({ theme }) => {
    const color = theme.palette.secondary.light;
    return {
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: color,
        fontSize: '12px',
      },
      [`& .${tooltipClasses.arrow}`]: {
        color: color,
      },
    };
  }
);

const DayOfWeek = React.forwardRef(({ date, item, event, ...props }, ref) => {
  return (
    <div ref={ref} {...props}>
      <Typography className={classes.weekdayText} variant="subtitle1">
        {event ? (
          <Badge color="secondary" variant="dot">
            {item.day}
          </Badge>
        ) : (
          item.day
        )}
      </Typography>
      <Typography className={classes.weekdayText} variant="subtitle1">
        {date}
      </Typography>
    </div>
  );
});

const DaysOfWeek = (props) => {
  const { firstDayOfWeek } = props;
  const events = useSelector(weekEventsSelector);

  return weeks.map((item, index) => {
    const date = moment(firstDayOfWeek).add(index, 'd');
    const dateString = date.format('DD.MM');

    const event = events.find((e) => date >= moment(e.startDate) && date <= moment(e.endDate).subtract(1, 'd'));

    return (
      <StyledTableCell key={item.id} isWeekEnd={item.day === 'Sat' || item.day === 'Sun'} event={event}>
        {event ? (
          <StyledTooltip title={event.name} placement="top">
            <DayOfWeek date={dateString} item={item} event={event} />
          </StyledTooltip>
        ) : (
          <DayOfWeek date={dateString} item={item} />
        )}
      </StyledTableCell>
    );
  });
};

export default DaysOfWeek;
