import 'react-notifications/lib/notifications.css';

import { NotificationManager } from 'react-notifications';

const Notification = (type, text) => {
  return () => {
    switch (type) {
      case 'success':
        NotificationManager.success('Changes successfully saved', 'Success');
        break;
      case 'warning':
        NotificationManager.warning(text ? text : 'Changes you made may not be saved', 'Warning');
        break;
      default:
        return 'wrong type';
    }
  };
};

export default Notification;
