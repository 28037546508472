import REPORT_CONSTANTS from './reportConstants';
import { downloadPdfFile } from '../../../components/helper/downloadPdfFile';
import { downloadExcelFile } from '../../../components/helper/downloadExcelFile';
import * as reportsRequests from '../../../axios/reports';
import { parseErrors } from '../../../components/helper/requestsHelpers';

export const getMonthlyReport = (firstDayOfMonth, name, pageNumber, pageSize) => async (dispatch) => {
  dispatch({
    type: REPORT_CONSTANTS.MONTHLY_REPORT_PENDING,
    payload: true,
  });

  const response = await reportsRequests.getMonthlyReport(firstDayOfMonth, name, pageNumber, pageSize);

  dispatch({
    type: REPORT_CONSTANTS.GET_MONTHLY_REPORT,
    payload: {
      reports: response.data.items,
      totalItems: response.data.totalItems,
    },
  });
};

export const getYearlyReport = (year, name, pageNumber, pageSize) => async (dispatch) => {
  dispatch({
    type: REPORT_CONSTANTS.YEARLY_REPORT_PENDING,
    payload: true,
  });

  const response = await reportsRequests.getYearlyReport(year, name, pageNumber, pageSize);

  dispatch({
    type: REPORT_CONSTANTS.GET_YEARLY_REPORT,
    payload: {
      reports: response.data.items,
      totalItems: response.data.totalItems,
    },
  });
};

export const getMonthlyPdfReport = (userId, firstDayOfMonth, activitiesIds) => async () => {
  const response = await reportsRequests.getMonthlyPdfReport(userId, firstDayOfMonth, activitiesIds);
  downloadPdfFile(response);
};

export const getWeeklyPdfReport = (userId, firstDayOfWeek, activitiesIds) => async () => {
  const response = await reportsRequests.getWeeklyPdfReport(userId, firstDayOfWeek, activitiesIds);
  downloadPdfFile(response);
};

export const getUserMonthlyExcelReport = (userId, firstDayOfMonth) => async () => {
  try {
    const response = await reportsRequests.getUserMonthlyExcelReport(userId, firstDayOfMonth);
    downloadExcelFile(response);
    return { status: response.status };
  } catch (e) {
    return await parseErrors(e);
  }
};

export const getUserYearlyExcelReport = (userId, firstDayOfYear) => async () => {
  try {
    const response = await reportsRequests.getUserYearlyExcelReport(userId, firstDayOfYear);
    downloadExcelFile(response);
    return { status: response.status };
  } catch (e) {
    return await parseErrors(e);
  }
};

export const getMonthlyExcelReport = (firstDayOfMonth) => async (dispatch) => {
  try {
    const response = await reportsRequests.getMonthlyExcelReport(firstDayOfMonth);

    downloadExcelFile(response);
    return { status: response.status };
  } catch (e) {
    return await parseErrors(e);
  }
};

export const getYearlyExcelReport = (firstDayOfYear) => async (dispatch) => {
  try {
    const response = await reportsRequests.getYearlyExcelReport(firstDayOfYear);
    downloadExcelFile(response);
    return { status: response.status };
  } catch (e) {
    return await parseErrors(e);
  }
};

export const clearMonthlyReport = () => (dispatch) => {
  dispatch({
    type: REPORT_CONSTANTS.CLEAR_MONTHLY_REPORT,
  });
};

export const clearYearlyReport = () => (dispatch) => {
  dispatch({
    type: REPORT_CONSTANTS.CLEAR_YEARLY_REPORT,
  });
};
