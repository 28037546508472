import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import SearchUserForm from './SearchUserForm';

const selector = formValueSelector('searchUserForm');

const mapStateToProps = state => ({
  name: selector(state, 'name'),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'searchUserForm',
    onSubmit: (payload, dispatch, props) => {
      props.onSearch(payload.name);
    },
  })(SearchUserForm)
);
