import React from 'react';
import UsersSelectField from '../../fields/UsersSelectField';

const SelectUserForm = ({ classes, hasMore, loadMore, options, isLoading, onInputChange }) => {
  return (
    <form className={classes.select}>
      <UsersSelectField
        name="user"
        hasMore={hasMore}
        loadMore={loadMore}
        options={options}
        isLoading={isLoading}
        onInputChange={onInputChange}
      />
    </form>
  );
};

export default SelectUserForm;
