import { connect } from 'react-redux';
import SickListModal from './SickListModal';
import * as modalsAction from '../../../redux/modules/modals/modalsActions';

const mapStateToProps = (state) => ({
  id: state.modals.modalProps.id,
});

const mapDispatchToProps = {
  hideModal: modalsAction.hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(SickListModal);
