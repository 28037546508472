import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import UserRecordCellContainer from './UserRecordCellContainer';
import { getDaysOfMonthWithFormat } from '../../../../helper/momentJsExtensions';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

const UserRecordsRow = ({ records, firstDayOfMonth }) => {
  const dates = getDaysOfMonthWithFormat(firstDayOfMonth, 'YYYY-MM-DD');
  return (
    <Root>
      {dates.map((date, index) => {
        const report = records[date]
          ? records[date]
          : {
              projectHours: 0,
              nonProjectHours: 0,
              vacationHours: 0,
              outOfOfficeHours: 0,
              sickLeaveHours: 0,
              unpaidLeaveHours: 0,
            };
        return <UserRecordCellContainer key={index} dailyReport={report} />;
      })}
    </Root>
  );
};

UserRecordsRow.propTypes = {
  records: PropTypes.object,
  firstDayOfMonth: PropTypes.object,
};

export default UserRecordsRow;
