import React, { useEffect } from 'react';
import PdfReportMonthPickerContainer from './MonthPicker/PdfReportMonthPickerContainer';
import MonthlyPdfReportFormContainer from './MonthlyPdfReportFormContainer';
import Table from '@mui/material/Table';
import TableBodySpinner from '../../../common/TableSpinners/TableBodySpinner';

const MonthlyPdfReport = (props) => {
  const { hideModal, userActivities, isLoading, monthChanged, firstDayOfMonth, userId, getMonthlyUserActivities } =
    props;

  useEffect(() => {
    getMonthlyUserActivities(firstDayOfMonth, userId);
  }, [firstDayOfMonth, getMonthlyUserActivities, userId]);

  return (
    <>
      <PdfReportMonthPickerContainer monthChanged={monthChanged} firstDayOfMonth={firstDayOfMonth} userId={userId} />
      {isLoading ? (
        <Table>
          <TableBodySpinner />
        </Table>
      ) : (
        <MonthlyPdfReportFormContainer
          onCancel={hideModal}
          userId={userId}
          firstDayOfMonth={firstDayOfMonth}
          userActivities={userActivities}
        />
      )}
    </>
  );
};

export default MonthlyPdfReport;
