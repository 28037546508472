import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import ExportWeekChangeHistoryForm from './ExportWeekChangeHistoryForm';
import Notification from '../../common/Notification/Notification';
import { exportEmployeeTimeTrackRecordChangeHistory } from '../../../redux/modules/employees/employeesActions';

const selector = formValueSelector('exportWeekChangeHistoryForm');

const mapStateToProps = (state) => ({
  initialValues: state.modals.modalProps,
  dateRange: selector(state, 'dateRange'),
  userId: selector(state, 'userId'),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'exportWeekChangeHistoryForm',
    onSubmit: (payload, dispatch, props) => {
      dispatch(
        exportEmployeeTimeTrackRecordChangeHistory(props.userId, payload.dateRange[0], payload.dateRange[1])
      ).then(({ status, text }) => {
        if (status === 500 || status === 404 || status === 400) {
          Notification('warning', text)();
        }
      });
    },
  })(ExportWeekChangeHistoryForm)
);
