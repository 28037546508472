import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const PREFIX = 'YearlyTotalsCell';

const classes = {
  hoursCell: `${PREFIX}-hoursCell`,
  cellWithAllActivityTypes: `${PREFIX}-cellWithAllActivityTypes`,
  projectHoursCell: `${PREFIX}-projectHoursCell`,
  nonProjectHoursCell: `${PREFIX}-nonProjectHoursCell`,
  outOfOfficeHoursCell: `${PREFIX}-outOfOfficeHoursCell`,
  dayOffCell: `${PREFIX}-dayOffCell`,
  totalCell: `${PREFIX}-totalCell`,
  recordHoursText: `${PREFIX}-recordHoursText`,
  recordEmptyHoursText: `${PREFIX}-recordEmptyHoursText`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '63px',
  minWidth: '240px',

  [`& .${classes.hoursCell}`]: {
    margin: '2.5px',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.cellWithAllActivityTypes}`]: {
    marginTop: '0.5px',
    marginBottom: '0.5px',
  },

  [`& .${classes.projectHoursCell}`]: {
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.nonProjectHoursCell}`]: {
    backgroundColor: theme.palette.red.main,
  },

  [`& .${classes.outOfOfficeHoursCell}`]: {
    backgroundColor: theme.palette.yellow.main,
  },

  [`& .${classes.dayOffCell}`]: {
    backgroundColor: theme.palette.white,
  },

  [`& .${classes.totalCell}`]: {
    backgroundColor: theme.palette.whiteblue,
  },

  [`& .${classes.recordHoursText}`]: {
    color: theme.palette.white,
  },

  [`& .${classes.recordEmptyHoursText}`]: {
    color: theme.palette.grey.dark,
  },
}));

const yearIsEmpty = (report) => {
  return report.projectHours === 0 && report.outOfOfficeHours === 0 && report.nonProjectHours === 0;
};

const yearWithAllActivityTypes = (report) => {
  return report.projectHours !== 0 && report.outOfOfficeHours !== 0 && report.nonProjectHours !== 0;
};

const YearlyTotalsCell = ({ yearlyReport, ...props }) => {
  const cellWithAllActivityTypes = yearWithAllActivityTypes(yearlyReport) && classes.cellWithAllActivityTypes;
  if (yearIsEmpty(yearlyReport)) {
    return (
      <Root {...props}>
        <div className={`${classes.hoursCell} ${classes.totalCell}`}>
          <Typography className={classes.recordEmptyHoursText}>0</Typography>
        </div>
      </Root>
    );
  } else {
    return (
      <Root {...props}>
        {yearlyReport.projectHours !== 0 && (
          <div className={`${classes.hoursCell} ${classes.projectHoursCell} ${cellWithAllActivityTypes}`}>
            <Typography className={classes.recordHoursText}>{yearlyReport.projectHours}</Typography>
          </div>
        )}
        {yearlyReport.outOfOfficeHours !== 0 && (
          <div className={`${classes.hoursCell} ${classes.outOfOfficeHoursCell} ${cellWithAllActivityTypes}`}>
            <Typography className={classes.recordHoursText}>{yearlyReport.outOfOfficeHours}</Typography>
          </div>
        )}
        {yearlyReport.nonProjectHours !== 0 && (
          <div className={`${classes.hoursCell} ${classes.nonProjectHoursCell} ${cellWithAllActivityTypes}`}>
            <Typography className={classes.recordHoursText}>{yearlyReport.nonProjectHours}</Typography>
          </div>
        )}
      </Root>
    );
  }
};

export default YearlyTotalsCell;
