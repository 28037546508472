import React from 'react';
import { styled } from '@mui/material/styles';
import ActivitiesContainer from './ActivitiesContainer';
import HeaderBarContainer from '../../common/HeaderBarWrapper/HeaderBar/HeaderBarContainer';
import PageTitle from '../../common/PageTitle/PageTitle';
import { NotificationContainer } from 'react-notifications';

const PREFIX = 'ActivitiesPage';

const classes = {
  root: `${PREFIX}-root`,
  contentWrapper: `${PREFIX}-contentWrapper`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
  height: '100%',

  [`& .${classes.contentWrapper}`]: {
    display: 'flex',
    height: 'calc(100vh - 58px)',
    flexDirection: 'column',
    backgroundColor: theme.palette.whitegrey,
    padding: '30px 0',
    overflow: 'auto',
  },
}));

const ActivitiesPage = () => {
  const pageTitle = 'Manage activities';
  return (
    <>
      <PageTitle pageTitle={pageTitle} />
      <Root>
        <HeaderBarContainer pageTitle={pageTitle} />
        <div className={classes.contentWrapper}>
          <ActivitiesContainer />
          <NotificationContainer />
        </div>
      </Root>
    </>
  );
};

export default ActivitiesPage;
