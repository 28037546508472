import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { NotificationContainer } from 'react-notifications';
import { compose } from 'redux';
import { connect } from 'react-redux';
import HeaderBarContainer from '../../common/HeaderBarWrapper/HeaderBar/HeaderBarContainer';
import MonthlyReportContainer from './MonthlyReportPage/MonthlyReportContainer';
import YearlyReportContainer from './YearlyReportPage/YearlyReportContainer';
import PageTitle from '../../common/PageTitle/PageTitle';
import PagesSwitcher from '../../common/PagesSwitcher/PagesSwitcher';
import Button from '@mui/material/Button';
import { getMonthlyExcelReport, getYearlyExcelReport } from '../../../redux/modules/report/reportActions';
import moment from '../../../../node_modules/moment/moment';
import { useEffect } from 'react';
import { getFirstDayOfMonth, getYear } from '../../helper/getDateFromQuery';
import Notification from './../../common/Notification/Notification';
import { useMatch, useNavigate, useLocation } from 'react-router-dom';

const PREFIX = 'ReportsPage';

const classes = {
  contentWrapper: `${PREFIX}-contentWrapper`,
  exportButton: `${PREFIX}-exportButton`,
  buttonsContainer: `${PREFIX}-buttonsContainer`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: theme.palette.whitegrey,

  [`& .${classes.contentWrapper}`]: {
    display: 'flex',
    height: 'calc(100vh - 58px)',
    flexDirection: 'column',
    padding: '5px 20px',
    overflow: 'auto',
  },

  [`& .${classes.exportButton}`]: {
    fontSize: '18px',
  },

  [`& .${classes.buttonsContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    alignItems: 'center',
    height: '70px',
    padding: '0 20px',
  },
}));

const ReportsPage = (props) => {
  const { getMonthlyExcelReport, getYearlyExcelReport } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const matchPath = useMatch({
    path: '/reports/yearly',
    exact: true,
    strict: false,
  });

  const [selectedPage, setSelectedPage] = useState(matchPath ? 'Yearly report' : 'Monthly report');
  const [firstDayOfMonth, setFirstDayOfMonth] = useState(moment().startOf('month'));
  const [year, setYear] = useState(moment().year());

  const pages = [
    {
      title: 'Monthly report',
      description: 'Monthly',
      url: '/reports/monthly',
    },
    {
      title: 'Yearly report',
      description: 'Yearly',
      url: '/reports/yearly',
    },
  ];

  const handlePageChange = (pageTitle, url) => {
    navigate(url);
    setSelectedPage(pageTitle);
  };

  const handleExportToExcel = () => {
    if (selectedPage === pages[0].title) {
      getMonthlyExcelReport(firstDayOfMonth);
    } else {
      getYearlyExcelReport(moment([year]).startOf('year'));
    }
  };

  useEffect(() => {
    if (selectedPage === pages[0].title) {
      const gotDate = getFirstDayOfMonth(location.search);
      setFirstDayOfMonth(gotDate);
    } else {
      const gotYear = getYear(location.search);
      setYear(gotYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <>
      <PageTitle pageTitle={selectedPage} />
      <Root>
        <HeaderBarContainer pageTitle={selectedPage} />
        <div className={classes.buttonsContainer}>
          <PagesSwitcher selectedPage={selectedPage} pages={pages} handlePageChange={handlePageChange} />
          <Button onClick={handleExportToExcel} variant="contained" color="primary" className={classes.exportButton}>
            Export to Excel
          </Button>
        </div>
        <div className={classes.contentWrapper}>
          {selectedPage === pages[1].title ? (
            <YearlyReportContainer location={location} navigate={navigate} />
          ) : (
            <MonthlyReportContainer location={location} navigate={navigate} />
          )}
        </div>
      </Root>
      <NotificationContainer />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMonthlyExcelReport: (firstDayOfPeriod) =>
      dispatch(getMonthlyExcelReport(firstDayOfPeriod)).then(({ status, text }) => {
        if (status === 500 || status === 404 || status === 400) {
          Notification('warning', text)();
        }
      }),
    getYearlyExcelReport: (firstDayOfPeriod) =>
      dispatch(getYearlyExcelReport(firstDayOfPeriod)).then(({ status, text }) => {
        if (status === 500 || status === 404 || status === 400) {
          Notification('warning', text)();
        }
      }),
  };
};

export default compose(connect(null, mapDispatchToProps))(ReportsPage);
