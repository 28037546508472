import React, { useEffect, useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ActivitiesList from './ActivitiesList';
import AddActivityFormContainer from './AddActivityForm/AddActivityFormContainer';
import ActivitiesFormContainer from './ActivitiesSearchForm/ActivitiesSearchFormContainer';
import paginationConstants from '../../helper/paginationConstants';
import activityTypesForSelect from '../../helper/activityTypesForSelect';

const PREFIX = 'Activities';

const classes = {
  root: `${PREFIX}-root`,
  headlineContainer: `${PREFIX}-headlineContainer`,
  headline: `${PREFIX}-headline`,
  activitiesSubheading: `${PREFIX}-activitiesSubheading`,
  formContainer: `${PREFIX}-formContainer`,
  header: `${PREFIX}-header`,
};

const Root = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  height: '100%',
  width: '60%',
  marginLeft: 'auto',
  marginRight: 'auto',

  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1vh',
    padding: '2vh 25px',
    backgroundColor: theme.palette.grey.light,
    height: '45vh',
    maxHeight: '45vh',
    minHeight: '200px',
    overflow: 'hidden',
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
  },

  [`& .${classes.headlineContainer}`]: {
    display: 'flex',
    marginTop: '3vh',
    justifyContent: 'left',
  },

  [`& .${classes.headline}`]: {
    color: theme.palette.grey.dark,
    fontWeight: 'bold',
  },

  [`& .${classes.activitiesSubheading}`]: {
    color: theme.palette.grey.dark,
  },

  [`& .${classes.header}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Activities = (props) => {
  const {
    activities,
    clearActivities,
    deleteActivity,
    getActivities,
    showUpdateActivityModal,
    totalActivities,
    isLoading,
  } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState('');
  const itemsPerPage = paginationConstants.itemsPerPage;

  useEffect(() => {
    return () => {
      clearActivities();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getActivities(pageNumber, itemsPerPage, searchString);
  }, [getActivities, itemsPerPage, pageNumber, searchString]);

  const onSearch = useCallback(
    (name) => {
      setSearchString(name);
      setPageNumber(1);
    },
    [setPageNumber, setSearchString]
  );

  const handleActivityUpdateClick = useCallback(
    (activity) => {
      showUpdateActivityModal(
        activity.id,
        activity.name,
        activityTypesForSelect
          .map((activityType) => ({ value: activityType.id, label: activityType.name }))
          .find((activityType) => activityType.value === activity.activityType),
        activity.description
      );
    },
    [showUpdateActivityModal]
  );

  const loadMore = useCallback(() => {
    setPageNumber((prev) => prev + 1);
  }, [setPageNumber]);

  return (
    <Root>
      <div className={classes.headlineContainer}>
        <Typography className={classes.headline} variant="h5">
          Create, edit and delete activities
        </Typography>
      </div>
      <Paper className={classes.root} elevation={2}>
        <div className={classes.header}>
          <Typography className={classes.activitiesSubheading} variant="subtitle1">
            Activities
          </Typography>
          <ActivitiesFormContainer pageSize={itemsPerPage} onSearch={onSearch} />
        </div>
        <ActivitiesList
          activities={activities}
          onDeleteButtonClicked={deleteActivity}
          onEditButtonClicked={handleActivityUpdateClick}
          itemCanBeEdited={true}
          isLoading={isLoading}
          loadMore={loadMore}
          hasMore={activities.length < totalActivities}
        />
      </Paper>
      <AddActivityFormContainer />
    </Root>
  );
};

export default Activities;
