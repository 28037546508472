import PRODUCTION_CALENDAR_CONSTANTS from './productionCalendarConstants';

const defaultState = {
  country: '',
  list: [],
};

const recordsReducers = (state = defaultState, action) => {
  switch (action.type) {
    case PRODUCTION_CALENDAR_CONSTANTS.GET_PRODUCTION_CALENDAR:
      return handleGetSetProductionCalendar(state, action.payload);
    case PRODUCTION_CALENDAR_CONSTANTS.SET_PRODUCTION_CALENDAR:
      return handleGetSetProductionCalendar(state, action.payload);
    case PRODUCTION_CALENDAR_CONSTANTS.GET_PRODUCTION_CALENDARS_LIST:
      return handleGetProductionCalendarsList(state, action.payload);
    default:
      return state;
  }
};

function handleGetSetProductionCalendar(state, payload) {
  return {
    ...state,
    country: payload,
  };
}

function handleGetProductionCalendarsList(state, payload) {
  return {
    ...state,
    list: payload,
  };
}

export default recordsReducers;
