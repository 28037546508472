import React from 'react';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import UserRecordCellContainer from '../RecordsColumn/UserRecordCellContainer';

const PREFIX = 'TotalsColumn';

const classes = {
  totalsColumnContainer: `${PREFIX}-totalsColumnContainer`,
  headerContainer: `${PREFIX}-headerContainer`,
  headerText: `${PREFIX}-headerText`,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 0px !important',
  border: 'none',

  [`& .${classes.totalsColumnContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.headerContainer}`]: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0px',
    backgroundColor: theme.palette.grey.light,
  },

  [`& .${classes.headerText}`]: {
    color: theme.palette.grey.dark,
  },
}));

const StyledUserRecordCellContainer = styled(UserRecordCellContainer)(({ theme }) => ({
  minWidth: '58px',
}));

const TotalsColumn = ({ totals }) => {
  return (
    <StyledTableCell>
      <div className={classes.headerContainer}>
        <Typography className={classes.headerText}>Totals</Typography>
      </div>
      <div className={classes.totalsColumnContainer}>
        {totals.map((total) => (
          <StyledUserRecordCellContainer key={total.userId} dailyReport={total.total} isTotal />
        ))}
      </div>
    </StyledTableCell>
  );
};

TotalsColumn.propTypes = {
  totals: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TotalsColumn;
