import USERS_CONSTANTS from './usersConstants';

const defaultState = {
  users: {
    items: [],
    totalItems: 0,
  },
  allUsers: {
    items: [],
    totalItems: 0,
  },
  currentUserId: null,
  userInfo: null,
  isLoading: false,
};

const usersReducers = (state = defaultState, action) => {
  switch (action.type) {
    case USERS_CONSTANTS.USERS_PENDING:
      return handleUsersPending(state, action.payload);
    case USERS_CONSTANTS.GET_USERS:
      return handleGetUsers(state, action.payload);
    case USERS_CONSTANTS.SET_CURRENT_USER:
      return handleSetCurrentUser(state, action.payload);
    case USERS_CONSTANTS.CLEAR_CURRENT_USER:
      return handleClearCurrentUser(state);
    case USERS_CONSTANTS.CLEAR_USERS:
      return handleClearUsers(state);
    case USERS_CONSTANTS.GET_USER_BY_ID:
      return handleGetUserById(state, action.payload);
    case USERS_CONSTANTS.GET_ALL_USERS:
      return handleGetAllUsers(state, action.payload);
    case USERS_CONSTANTS.CLEAR_ALL_USERS:
      return handleClearAllUsers(state);
    case USERS_CONSTANTS.CLEAR_USER_INFO:
      return handleClearUserInfo(state);
    default:
      return state;
  }
};

function handleUsersPending(state, isLoading) {
  return {
    ...state,
    isLoading: isLoading,
  };
}

function handleGetUsers(state, { users, totalItems }) {
  return {
    ...state,
    users: {
      items: [...state.users.items, ...users],
      totalItems: totalItems,
    },
  };
}

function handleGetAllUsers(state, { allUsers, totalItems }) {
  return {
    ...state,
    allUsers: {
      items: [...state.allUsers.items, ...allUsers],
      totalItems: totalItems,
    },
  };
}

function handleGetUserById(state, payload) {
  return {
    ...state,
    userInfo: payload,
  };
}

function handleSetCurrentUser(state, id) {
  return { ...state, currentUserId: id };
}

function handleClearCurrentUser(state) {
  return { ...state, currentUserId: null };
}

function handleClearUsers(state) {
  return {
    ...state,
    users: defaultState.users,
  };
}

function handleClearAllUsers(state) {
  return {
    ...state,
    allUsers: defaultState.allUsers,
  };
}

function handleClearUserInfo(state) {
  return {
    ...state,
    userInfo: defaultState.userInfo,
  };
}

export default usersReducers;
