import moment from 'moment';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { getUserYearlyExcelReport } from '../../../../redux/modules/report/reportActions';
import ExcelReportForm from '../ExcelReportForm/ExcelReportForm';
import Notification from './../../../common/Notification/Notification';

const mapStateToProps = state => ({
  formValues: getFormValues('ExcelReportForm')(state),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'ExcelReportForm',
    onSubmit: (payload, dispatch, props) => {
      const firstDayOfYear = moment([props.year]).startOf('year');
      dispatch(getUserYearlyExcelReport(props.userId, firstDayOfYear)).then(({ status, text }) => {
        if (status === 500 || status === 404 || status === 400) {
          Notification('warning', text)();
        }
      });
    },
  })(ExcelReportForm)
);
