import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const PREFIX = 'ActivityTypesLegendBar';

const classes = {
  projectTypeCell: `${PREFIX}-projectTypeCell`,
  vacationTypeCell: `${PREFIX}-vacationTypeCell`,
  nonProjectTypeCell: `${PREFIX}-nonProjectTypeCell`,
  colorBar: `${PREFIX}-colorBar`,
  typeName: `${PREFIX}-typeName`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  margin: '25px 17px',
  justifyContent: 'flex-start',
  alignItems: 'center',

  [`& .${classes.projectTypeCell}`]: {
    backgroundColor: theme.palette.primary.main,
    marginRight: '10px',
  },

  [`& .${classes.vacationTypeCell}`]: {
    backgroundColor: theme.palette.yellow.main,
    marginRight: '10px',
  },

  [`& .${classes.nonProjectTypeCell}`]: {
    backgroundColor: theme.palette.red.main,
  },

  [`& .${classes.colorBar}`]: {
    width: '70px',
    height: '30px',
    marginLeft: '10px',
    margin: '0 30px 0 10px',
  },

  [`& .${classes.typeName}`]: {
    color: theme.palette.grey.dark,
  },
}));

const ActivityTypesLegendBar = () => {
  return (
    <Root>
      <Typography className={classes.typeName}>Project Work: </Typography>
      <div className={`${classes.colorBar} ${classes.projectTypeCell}`} />
      <Typography className={classes.typeName}>Out Of Office: </Typography>
      <div className={`${classes.colorBar} ${classes.vacationTypeCell}`} />
      <Typography className={classes.typeName}>Non-project Work: </Typography>
      <div className={`${classes.colorBar} ${classes.nonProjectTypeCell}`} />
    </Root>
  );
};

export default ActivityTypesLegendBar;
