import { createSelector } from 'reselect';

export const monthlyReportSelector = state => state.report.monthlyUserReports;
export const yearlyReportSelector = state => state.report.yearlyUserReports;

export const getUsersForMonthlyReport = createSelector([monthlyReportSelector], userReports => {
  const users = userReports.reports.map(userReport => ({
    name: userReport.name,
    id: userReport.userId,
  }));
  return users;
});

export const getUsersForYearlyReport = createSelector([yearlyReportSelector], userReports => {
  const users = userReports.reports.map(userReport => ({
    name: userReport.name,
    id: userReport.userId,
  }));
  return users;
});

export const getReports = createSelector([monthlyReportSelector], userReports => {
  const reports = userReports.reports.map(userReport => ({
    userId: userReport.userId,
    records: userReport.dailyReportsByDate,
  }));
  return reports;
});

export const getYearlyReports = createSelector([yearlyReportSelector], userReports => {
  const reports = userReports.reports.map(userReport => ({
    userId: userReport.userId,
    records: userReport.yearlyReport,
  }));
  return reports;
});

export const getTotals = createSelector([monthlyReportSelector], userReports => {
  const totals = userReports.reports.map(userReport => {
    const reports = userReport.dailyReportsByDate;
    return {
      userId: userReport.userId,
      total: Object.keys(reports).reduce(
        (total, key) => ({
          projectHours: total.projectHours + reports[key].projectHours,
          nonProjectHours: total.nonProjectHours + reports[key].nonProjectHours,
          outOfOfficeHours: total.outOfOfficeHours + reports[key].outOfOfficeHours,
          sickLeaveHours: total.sickLeaveHours + reports[key].sickLeaveHours,
          unpaidLeaveHours: total.unpaidLeaveHours + reports[key].unpaidLeaveHours,
          vacationHours: total.vacationHours + reports[key].vacationHours,
        }),
        {
          projectHours: 0,
          vacationHours: 0,
          nonProjectHours: 0,
          outOfOfficeHours: 0,
          sickLeaveHours: 0,
          unpaidLeaveHours: 0,
        }
      ),
    };
  });
  return totals;
});
