import 'moment/locale/en-gb';
import { styled } from '@mui/material/styles';
import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import 'rc-calendar/dist/rc-calendar.css';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useCallback } from 'react';

const PREFIX = 'YearPicker';

const classes = {
  container: `${PREFIX}-container`,
  selectedYear: `${PREFIX}-selectedYear`,
  selectYearArrow: `${PREFIX}-selectYearArrow`,
  selectYear: `${PREFIX}-selectYear`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  alignItems: 'center',

  [`& .${classes.selectedYear}`]: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '13px',
    margin: '0 10px',
  },

  [`& .${classes.selectYearArrow}`]: {
    cursor: 'pointer',
    color: theme.palette.white,
  },

  [`& .${classes.selectYear}`]: {
    minWidth: '50px',
    paddingTop: '2px',
    textAlign: 'center',
    color: theme.palette.white,
  },
}));

const YearPicker = (props) => {
  const { year, yearChanged } = props;

  const handleClickPreviousYear = useCallback(() => {
    const previousYear = year - 1;
    yearChanged(previousYear);
  }, [year]);

  const handleClickNextYear = useCallback(() => {
    const nextYear = year + 1;
    yearChanged(nextYear);
  }, [year]);

  return (
    <StyledGrid container direction="row" spacing={0}>
      <Grid item>
        <Typography type="subtitle1">Year:</Typography>
      </Grid>

      <Grid item className={classes.selectedYear}>
        <KeyboardArrowLeft className={classes.selectYearArrow} onClick={handleClickPreviousYear} />
        <Typography type="subtitle1" className={classes.selectYear}>
          {year}
        </Typography>
        <KeyboardArrowRight className={classes.selectYearArrow} onClick={handleClickNextYear} />
      </Grid>
    </StyledGrid>
  );
};

export default YearPicker;
