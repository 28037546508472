import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import paginationConstants from '../../helper/paginationConstants';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect';
import {
  addSubordinateToUser,
  clearEmployees,
  getSubordinatesByUserId,
  removeSubordinateFromUser,
} from '../../../redux/modules/employees/employeesActions';
import {
  clearAllUsers,
  clearCurrentUser,
  clearUsers,
  getAllUsers,
  getUsers,
  setCurrentUser,
} from '../../../redux/modules/users/usersActions';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllUsers, selectCurrentUserId, selectUsers } from '../../../redux/modules/users/usersSelectors';
import SubordinatesList from './SubordinatesList';
import { selectEmployees } from '../../../redux/modules/employees/employeesSelectors';
import Button from '@mui/material/Button';
import Notification from '../../common/Notification/Notification';
import employeeStatuses from '../../helper/employeeStatuses';
import AsyncPaginateSelect from '../../common/InfiniteSelect/AsyncPaginateSelect';
import { selectEmployeesIsLoading } from '../../../redux/modules/employees/employeesSelectors';

const PREFIX = 'ManageSubordinates';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  flexContainer: `${PREFIX}-flexContainer`,
  headlineContainer: `${PREFIX}-headlineContainer`,
  select: `${PREFIX}-select`,
  headline: `${PREFIX}-headline`,
  subheading: `${PREFIX}-subheading`,
  formContainer: `${PREFIX}-formContainer`,
};

const Root = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  height: '100%',
  width: '60%',
  marginLeft: 'auto',
  marginRight: 'auto',

  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '25px',
    paddingTop: '10px',
    backgroundColor: theme.palette.grey.light,
    height: '62vh',
    maxHeight: '77vh',
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
    minHeight: '400px',
    overflow: 'hidden',
  },

  [`& .${classes.button}`]: {
    fontSize: '18px',
    margin: '5px 0px',
    padding: '4px 16px',
    width: '25%',
  },

  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 0',
  },

  [`& .${classes.headlineContainer}`]: {
    display: 'flex',
    marginTop: '3vh',
    justifyContent: 'left',
  },

  [`& .${classes.select}`]: {
    border: 'none',
    width: '50%',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '5px 5% 5px 0px',
    fontFamily: theme.typography.fontFamily,
  },

  [`& .${classes.headline}`]: {
    color: theme.palette.grey.dark,
    fontWeight: 'bold',
  },

  [`& .${classes.subheading}`]: {
    color: theme.palette.grey.dark,
  },
}));

const ManageSubordinates = (props) => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const currentUser = useSelector(selectCurrentUserId);
  const subordinates = useSelector(selectEmployees);
  const allUsers = useSelector(selectAllUsers);
  const subordinatesIsLoading = useSelector(selectEmployeesIsLoading);

  const pageSize = paginationConstants.itemsPerPage;
  const [usersPagination, setUsersPagination] = useState({
    page: 1,
    isLoading: false,
    search: '',
  });
  const [allUsersPagination, setAllUsersPagination] = useState({
    page: 1,
    isLoading: false,
    search: '',
  });
  const [subordinateId, setSubordinateId] = useState(null);
  const [selectedManagerOption, setSelectedManagerOption] = useState(null);

  const usersOptions = useMemo(() => users.items?.map((user) => ({ value: user.id, label: user.name })), [users.items]);
  const allUsersOptions = useMemo(
    () =>
      allUsers.items
        .filter(
          (user) => user.id !== currentUser && !subordinates.map((subordinate) => subordinate.id).includes(user.id)
        )
        .map((user) => ({ value: user.id, label: user.name })),
    [allUsers.items, currentUser, subordinates]
  );

  const selectedUsersSubordinates = useMemo(
    () =>
      subordinates.map((subordinate) => ({
        id: subordinate.id,
        name: `${subordinate.firstName} ${subordinate.lastName}`,
      })),
    [subordinates]
  );

  const handleUsersSearchChange = useCallback(
    (value) => {
      if (value !== usersPagination.search) {
        dispatch(clearUsers());
        setUsersPagination({ page: 1, search: value, isLoading: true });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usersPagination.search]
  );
  const handleUsersPageChange = useCallback(() => {
    setUsersPagination((prev) => ({ ...prev, page: prev.page + 1, isLoading: true }));
  }, []);

  const handleAllUsersSearchChange = useCallback(
    (value) => {
      if (value !== allUsersPagination.search) {
        dispatch(clearAllUsers());
        setAllUsersPagination({ page: 1, search: value, isLoading: true });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allUsersPagination.search]
  );
  const handleAllUsersPageChange = useCallback(() => {
    setAllUsersPagination((prev) => ({ ...prev, page: prev.page + 1, isLoading: true }));
  }, []);

  useEffect(() => {
    dispatch(clearUsers());
    dispatch(clearAllUsers());
    dispatch(clearEmployees());
    return () => {
      dispatch(clearCurrentUser());
      dispatch(clearUsers());
      dispatch(clearAllUsers());
      dispatch(clearEmployees());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentUser) return;
    dispatch(getSubordinatesByUserId(currentUser, 1, 999, '', employeeStatuses.active));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useDebouncedEffect(
    () => {
      dispatch(getUsers(usersPagination.page, pageSize, usersPagination.search, ['manager']));
      setUsersPagination((prev) => ({ ...prev, isLoading: false }));
    },
    usersPagination.search ? 1000 : 0,
    [usersPagination.page, usersPagination.search]
  );

  useDebouncedEffect(
    () => {
      dispatch(getAllUsers(allUsersPagination.page, pageSize, allUsersPagination.search, employeeStatuses.active));
      setAllUsersPagination((prev) => ({ ...prev, isLoading: false }));
    },
    allUsersPagination.search ? 1000 : 0,
    [allUsersPagination.page, allUsersPagination.search]
  );

  const handleAddSubordinateToCurrentUser = useCallback(
    async (subordinateId) => {
      const { status, text } = await dispatch(addSubordinateToUser(currentUser, subordinateId));
      if (status !== 200) {
        Notification('warning', text)();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  const handleRemoveSubordinateFromUser = useCallback(
    async (subordinateId) => {
      const { status, text } = await dispatch(removeSubordinateFromUser(currentUser, subordinateId));
      if (status !== 200) {
        Notification('warning', text)();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  return (
    <Root>
      <div className={classes.headlineContainer}>
        <Typography className={classes.headline} variant="h5">
          Assign subordinates to users
        </Typography>
      </div>
      <Paper className={classes.root} elevation={2}>
        <>
          <AsyncPaginateSelect
            className={classes.select}
            options={usersOptions}
            loadMore={handleUsersPageChange}
            hasMore={users.totalItems > users.items?.length}
            onInputChange={handleUsersSearchChange}
            isLoading={usersPagination.isLoading}
            onChange={(option) => {
              setSelectedManagerOption(option);
              dispatch(setCurrentUser(option.value));
            }}
            value={selectedManagerOption}
          />
          <Typography className={classes.subheading} variant="subtitle1">
            {currentUser && `Subordinates of user:`}
          </Typography>
        </>

        <SubordinatesList
          onDeleteButtonClicked={handleRemoveSubordinateFromUser}
          subordinates={selectedUsersSubordinates}
          managerName={users.items?.find((x) => x.id === currentUser)?.name}
          isLoading={subordinatesIsLoading}
        />
        <div className={classes.flexContainer}>
          <AsyncPaginateSelect
            className={classes.select}
            options={allUsersOptions}
            loadMore={handleAllUsersPageChange}
            hasMore={allUsers.totalItems > allUsers.items?.length}
            onInputChange={handleAllUsersSearchChange}
            isLoading={allUsersPagination.isLoading}
            isDisabled={!currentUser}
            onChange={(subordinate) => setSubordinateId(subordinate.value)}
            menuPosition={'fixed'}
          />
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => handleAddSubordinateToCurrentUser(subordinateId)}
          >
            Add
          </Button>
        </div>
      </Paper>
    </Root>
  );
};

export default ManageSubordinates;
