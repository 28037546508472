import axios from 'axios';
import { withAPIFormat } from '../components/helper/momentJsExtensions';

export const getTimeTrackWeek = async (firstDayOfWeekDate) => {
  return axios.get('/api/week', {
    params: {
      firstDayOfWeekDate: withAPIFormat(firstDayOfWeekDate),
    },
  });
};

export const getEmployeesTimeTrackWeek = async (userId, firstDayOfWeekDate) => {
  return await axios.get(`/api/employee/${userId}/week`, {
    params: {
      firstDayOfWeekDate: withAPIFormat(firstDayOfWeekDate),
    },
  });
};

export const sendUpdatedTimeTrackWeek = async (userActivities, tasks, timeTrackRecords) => {
  return await axios.post('/api/week', {
    userActivities,
    tasks,
    timeTrackRecords,
  });
};

export const sendUpdatedEmployeesTimeTrackWeek = async (userId, userActivities, tasks, timeTrackRecords) => {
  return axios.post(`/api/employee/${userId}/week`, {
    userActivities,
    tasks,
    timeTrackRecords,
  });
};
