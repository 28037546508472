import { createSelector } from 'reselect';
import actionTypes from '../../../components/helper/actionTypes';
import getTotalTime from '../../../components/helper/totalHoursPerDay';
import activityTypes from '../../../components/helper/activityTypes';
import outOfOfficeActivities from '../../../components/helper/outOfOfficeActivities';

const timeTrackWeekSelector = (state) => state.timeTrackWeek;

const timeTrackWeekTasksSelector = (state) => state.timeTrackWeek.tasks;

const timeTrackWeekTotalsSelector = (state) => state.timeTrackWeek.totals;

const timeTrackWeekRecordsSelector = (state) => state.timeTrackWeek.timeTrackRecords;

export const weekEventsSelector = (state) => state.timeTrackWeek.events;

export const firstDayOfWeekSelector = (state) => state.timeTrackWeek.firstDayOfWeek;

const activityIdSelector = (state, props) => {
  if (props.userActivityId) {
    return props.userActivityId;
  } else if (props.activity.activityId) {
    return props.activity.activityId;
  }
};

const taskIdSelector = (state, props) => props.taskId;

const totalsReducer = (totals, activityTotal) => {
  return {
    totalHoursPerDay: totals.totalHoursPerDay.concat(activityTotal.totalHoursPerDay),
    totalHoursPerWeek: totals.totalHoursPerWeek + activityTotal.totalHoursPerWeek,
  };
};

export const getProjectActivities = createSelector([timeTrackWeekSelector], (timeTrackWeek) => {
  const items = timeTrackWeek.userActivities.filter((activity) => activity.activityType === activityTypes.project);
  return {
    items: items,
    totals: timeTrackWeek.totals
      .filter((total) => total.activityType === activityTypes.project)
      .reduce(totalsReducer, { totalHoursPerDay: [], totalHoursPerWeek: 0 }),
  };
});

export const getNonProjectActivities = createSelector([timeTrackWeekSelector], (timeTrackWeek) => {
  const items = timeTrackWeek.userActivities.filter((activity) => activity.activityType === activityTypes.nonProject);
  return {
    items: items,
    totals: timeTrackWeek.totals
      .filter((total) => total.activityType === activityTypes.nonProject)
      .reduce(totalsReducer, { totalHoursPerDay: [], totalHoursPerWeek: 0 }),
  };
});

export const getOutOfOfficeActivities = createSelector([timeTrackWeekSelector], (timeTrackWeek) => {
  const items = timeTrackWeek.userActivities.filter((activity) => activity.activityType === activityTypes.outOfOffice);
  return {
    items: items,
    totals: timeTrackWeek.totals
      .filter((total) => total.activityType === activityTypes.outOfOffice)
      .reduce(totalsReducer, { totalHoursPerDay: [], totalHoursPerWeek: 0 }),
  };
});

export const getTotals = createSelector([timeTrackWeekTotalsSelector], (totals) =>
  totals.reduce(totalsReducer, { totalHoursPerDay: [], totalHoursPerWeek: 0 })
);

export const getTotalsByActivityId = createSelector(
  [timeTrackWeekTotalsSelector, activityIdSelector],
  (totals, activityId) => totals.find((total) => total.activityId === activityId)
);

export const getTasksByActivityId = createSelector(
  [timeTrackWeekTasksSelector, activityIdSelector],
  (tasks, activityId) =>
    tasks.filter((task) => task.action !== actionTypes.deleted && task.userActivityId === activityId)
);

export const getSickListTasks = createSelector([timeTrackWeekTasksSelector], (tasks) =>
  tasks.filter((task) => task.action !== actionTypes.deleted && task.isSickList)
);

export const getSickLists = createSelector([timeTrackWeekSelector], (timeTrackWeek) => timeTrackWeek.sickLists);

export const getSickLeaveTask = createSelector([timeTrackWeekTasksSelector], (tasks) =>
  tasks.find((task) => task.action !== actionTypes.deleted && task.description === outOfOfficeActivities.sickLeave.name)
);

export const getRecordsByTaskId = createSelector([timeTrackWeekRecordsSelector, taskIdSelector], (records, taskId) =>
  records.filter((record) => record.action !== actionTypes.deleted && record.taskId === taskId)
);

export const getNotEmptyRecords = createSelector([timeTrackWeekRecordsSelector], (records) =>
  records.filter((record) => record.id !== 0)
);

export const getInitialDescriptionValues = createSelector([timeTrackWeekTasksSelector], (tasks) =>
  tasks.reduce((initialValues, task) => {
    const fieldName = `description-${task.id}`;
    initialValues[fieldName] = task.description;
    return initialValues;
  }, {})
);

export const getInitialRecordValues = createSelector([timeTrackWeekSelector], (timeTrackWeek) =>
  timeTrackWeek.tasks.reduce((initialValues, task) => {
    const records = timeTrackWeek.timeTrackRecords.filter((record) => record.taskId === task.id);
    return {
      ...initialValues,
      ...getTotalTime(records).reduce((initialValues, record, index) => {
        const fieldName = `tablecell-${task.id}-${index}`;
        initialValues[fieldName] = record.hours !== 0 ? record.hours : '';
        return initialValues;
      }, {}),
    };
  }, {})
);
