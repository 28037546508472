import React from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import TableCell from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  display: 'table-cell',
  textAlign: 'center',
  border: 'none',
}));

const TableCellSpinner = ({ size = 60, thickness = 2, ...props }) => (
  <StyledTableCell {...props}>
    <CircularProgress size={size} thickness={thickness} color="secondary" />
  </StyledTableCell>
);

export default TableCellSpinner;
