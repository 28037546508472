import React from 'react';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import UpdateActivityForm from './UpdateResourceManagerForm';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../redux/modules/modals/modalsActions';

const PREFIX = 'UpdateResourceManagerModal';

const classes = {
  input: `${PREFIX}-input`,
  select: `${PREFIX}-select`,
  paper: `${PREFIX}-paper`,
  flexContainer: `${PREFIX}-flexContainer`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    position: 'absolute',
    width: theme.spacing(65),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
  },
}));

const UpdateResourceManagerModal = ({ userId }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideModal());
  };

  return (
    <StyledModal open={true}>
      <div className={classes.paper}>
        <UpdateActivityForm onCancel={handleClose} userId={userId} />
      </div>
    </StyledModal>
  );
};

export default UpdateResourceManagerModal;
