import React from 'react';
import TableBody from '@mui/material/TableBody';
import TableRowSpinner from './TableRowSpinner';

const TableBodySpinner = (props) => {
  return (
    <TableBody>
      <TableRowSpinner {...props} />
    </TableBody>
  );
};
export default TableBodySpinner;
