import { connect } from 'react-redux';
import OutOfOfficeActivity from './OutOfOfficeActivity';
import {
  addTask,
  sendUpdatedEmployeesTimeTrackWeek,
  sendUpdatedTimeTrackWeek,
} from '../../../../redux/modules/records/recordsActions';
import { showAddSickListModal } from '../../../../redux/modules/modals/modalsActions';
import {
  getSickLeaveTask,
  getSickLists,
  getSickListTasks,
  getTotalsByActivityId,
} from '../../../../redux/modules/records/recordsSelectors';
import { getCurrentUser } from '../../../../redux/modules/users/usersSelectors';

const mapDispatchToProps = {
  addTask,
  showAddSickListModal,
  sendUpdatedTimeTrackWeek,
  sendUpdatedEmployeesTimeTrackWeek,
};

const mapStateToProps = (state, props) => ({
  totals: getTotalsByActivityId(state, props),
  sickListTasks: getSickListTasks(state),
  sickLists: getSickLists(state),
  sickLeaveTask: getSickLeaveTask(state),
  currentUser: getCurrentUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutOfOfficeActivity);
