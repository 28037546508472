import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  setFirstDayOfWeek,
  sendUpdatedTimeTrackWeek,
  updateTask,
  sendUpdatedEmployeesTimeTrackWeek,
} from '../../../redux/modules/records/recordsActions';
import {
  getProjectActivities,
  getNonProjectActivities,
  getTotals,
  getOutOfOfficeActivities,
  getInitialDescriptionValues,
  getInitialRecordValues,
} from '../../../redux/modules/records/recordsSelectors';
import * as modalsActions from '../../../redux/modules/modals/modalsActions';
import { getCurrentUser } from '../../../redux/modules/users/usersSelectors';
import { getTimeTrackWeek, getEmployeesTimeTrackWeek } from '../../../redux/modules/records/recordsActions';
import UserActivitiesTable from './UserActivitiesTable';
import { getFirstDayOfWeek } from '../../helper/getDateFromQuery';
import Notification from '../../common/Notification/Notification';

const formName = 'records-table';

const mapStateToProps = (state, props) => {
  return {
    isLoading: state.timeTrackWeek.isLoading,
    totals: getTotals(state),
    projectActivities: getProjectActivities(state),
    nonProjectActivities: getNonProjectActivities(state),
    outOfOfficeActivities: getOutOfOfficeActivities(state),
    dataWasChanged: state.timeTrackWeek.dataWasChanged,
    currentUser: getCurrentUser(state),
    firstDayOfWeekFromUrl: getFirstDayOfWeek(props.location.search),
    initialValues: {
      ...getInitialDescriptionValues(state),
      ...getInitialRecordValues(state),
    },
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const userId = props.params.id;
  return {
    setFirstDayOfWeek: (firstDayOfWeek) => dispatch(setFirstDayOfWeek(firstDayOfWeek)),
    showPdfReportModal: (firstDayOfMonth, firstDayOfWeek, userId, userFullName) =>
      dispatch(modalsActions.showPdfReportModal(firstDayOfMonth, firstDayOfWeek, userId, userFullName)),
    showExcelReportModal: (firstDayOfMonth, year, userId, userFullName) =>
      dispatch(modalsActions.showExcelReportModal(firstDayOfMonth, year, userId, userFullName)),
    showExportWeekChangeHistoryModal: (userId, title) =>
      dispatch(modalsActions.showExportWeekChangeHistoryModal(userId, title)),
    getTimeTrackWeek: (firstDayOfWeek) =>
      userId ? dispatch(getEmployeesTimeTrackWeek(userId, firstDayOfWeek)) : dispatch(getTimeTrackWeek(firstDayOfWeek)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    onSubmit: (values, dispatch, props) => {
      const userId = props.params.id;
      if (userId) {
        dispatch(sendUpdatedEmployeesTimeTrackWeek(userId)).then(({ status, text }) => {
          if (status === 500 || status === 404 || status === 400) {
            Notification('warning', text)();
          } else {
            Notification('success')();
          }
        });
      } else {
        dispatch(sendUpdatedTimeTrackWeek()).then(({ status, text }) => {
          if (status === 500 || status === 404 || status === 400) {
            Notification('warning', text)();
          } else {
            Notification('success')();
          }
        });
      }
    },
    onChange: (values, dispatch, props) => {
      if (props.dirty) {
        const key = Object.keys(values).find(
          (key) => key.includes('description') && values[key] !== props.initialValues[key]
        );
        if (key) {
          const id = key.substring(key.indexOf('-') + 1);
          dispatch(updateTask(id, values[key]));
        }
      }
    },
    enableReinitialize: true,
  })
)(UserActivitiesTable);
