import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import InfiniteList from '../../common/InfiniteList/InfiniteList';
import ConfirmationModal from '../../common/ConfirmationModal/ConfirmationModal';
import DeleteIcon from '../../../assets/icons-svg/basket.svg';

const PREFIX = 'SubordinatesList';

const classes = {
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
  listItemText: `${PREFIX}-listItemText`,
  iconButton: `${PREFIX}-iconButton`,
};

const StyledInfiniteList = styled(InfiniteList)(({ theme }) => ({
  boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
  height: '370px',
  minHeight: '150px',
  overflowY: 'auto',
  backgroundColor: theme.palette.white,
  margin: '10px 0',
  padding: '10px 10px 0 0',

  [`& .${classes.listItem}`]: {
    height: '36px',
  },

  [`& .${classes.listItemText}`]: {
    fontSize: '18px',
    margin: '5px 0',
    color: `${theme.palette.secondary.main} !important`,
  },

  [`& .${classes.iconButton}`]: {
    width: '36px',
    height: '36px',
  },
}));

// TODO: merge with ActivitiesList.js and reuse
const SubordinatesList = ({ subordinates, onDeleteButtonClicked, isLoading, loadMore, hasMore, managerName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubordinateId, setSelectedSubordinateId] = useState(0);

  const handleOpenModal = (id) => {
    setIsModalOpen(true);
    setSelectedSubordinateId(id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSubordinateId(0);
  };

  const handleSubmitModal = () => {
    onDeleteButtonClicked(selectedSubordinateId);
    handleCloseModal();
  };

  const renderSubordinate = (subordinate) => (
    <ListItem key={subordinate.id} className={classes.listItem} dense button>
      <Typography className={classes.listItemText}>{subordinate.name}</Typography>
      <ListItemSecondaryAction>
        <IconButton onClick={() => handleOpenModal(subordinate.id)} className={classes.iconButton} size="large">
          <img alt={'delete'} src={DeleteIcon} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <>
      <StyledInfiniteList
        className={classes.list}
        component="nav"
        isLoading={isLoading}
        hasMore={hasMore}
        loadMore={loadMore}
      >
        {subordinates.map(renderSubordinate)}
      </StyledInfiniteList>
      <ConfirmationModal
        isOpen={isModalOpen}
        confirmButtonName={'Delete'}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitModal}
        modalTitle={`Are you sure you want to remove subordinate ${
          subordinates.find((x) => x.id === selectedSubordinateId)?.name
        } from manager ${managerName}?`}
      />
    </>
  );
};

export default SubordinatesList;
