import { connect } from 'react-redux';
import UsersColumn from './UsersColumn';
import { monthlyReportSelector, getUsersForMonthlyReport } from '../../../../redux/modules/report/reportSelectors';

const mapStateToProps = (state) => ({
  users: getUsersForMonthlyReport(state),
  totalUsers: monthlyReportSelector(state).totalItems,
});

export default connect(mapStateToProps)(UsersColumn);
