import { connect } from 'react-redux';
import * as modalsAction from '../../../redux/modules/modals/modalsActions';
import PdfReportModal from './PdfReportModal';

const mapStateToProps = (state) => ({
  userId: state.modals.modalProps.userId,
  userFullName: state.modals.modalProps.userFullName,
  firstDayOfMonth: state.modals.modalProps.firstDayOfMonth,
  firstDayOfWeek: state.modals.modalProps.firstDayOfWeek,
});

const mapDispatchToProps = {
  hideModal: modalsAction.hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PdfReportModal);
