import * as employeesRequests from '../../../axios/employees';
import * as usersRequests from '../../../axios/users';
import { downloadExcelFile } from '../../../components/helper/downloadExcelFile';
import { parseErrors } from '../../../components/helper/requestsHelpers';
import EMPLOYEES_CONSTANTS from './employeesConstants';

const setPending = (isPending) => (dispatch) => {
  dispatch({
    type: EMPLOYEES_CONSTANTS.EMPLOYEES_PENDING,
    payload: isPending,
  });
};

export const getEmployees = (pageNumber, pageSize, search, status, resourceManagerId) => async (dispatch) => {
  dispatch(setPending(true));

  const response = await employeesRequests.getEmployees(pageNumber, pageSize, search, status, resourceManagerId);

  dispatch({
    type: EMPLOYEES_CONSTANTS.GET_EMPLOYEES,
    payload: { employees: response.data.items, totalItems: response.data.totalItems },
  });
  dispatch(setPending(false));
};

export const getSubordinatesByUserId =
  (userId, pageNumber, pageSize, search, status, resourceManagerId) => async (dispatch) => {
    dispatch(setPending(true));

    const response = await employeesRequests.getSubordinatesByUserId(
      userId,
      pageNumber,
      pageSize,
      search,
      status,
      resourceManagerId
    );

    dispatch({
      type: EMPLOYEES_CONSTANTS.GET_SUBORDINATES_BY_USER_ID,
      payload: { employees: response.data.items, totalItems: response.data.totalItems },
    });
    dispatch(setPending(false));
  };

export const getManagersByUserId =
  (userId, pageNumber, pageSize, search, status, resourceManagerId) => async (dispatch) => {
    dispatch(setPending(true));

    const response = await employeesRequests.getManagersByUserId(
      userId,
      pageNumber,
      pageSize,
      search,
      status,
      resourceManagerId
    );

    dispatch({
      type: EMPLOYEES_CONSTANTS.GET_MANAGERS_BY_USER_ID,
      payload: { employees: response.data.items, totalItems: response.data.totalItems },
    });
    dispatch(setPending(false));
  };

export const getMyManagers = () => async (dispatch) => {
  dispatch(setPending(true));

  const response = await employeesRequests.getMyManagers();

  dispatch({
    type: EMPLOYEES_CONSTANTS.GET_MY_MANAGERS,
    payload: response.data,
  });
  dispatch(setPending(false));
};

export const addSubordinateToUser = (managerId, subordinateId) => async (dispatch, getState) => {
  dispatch(setPending(true));

  try {
    const manager = getState().users.allUsers.items.find((x) => x.id === managerId);
    const subordinate = getState().users.allUsers.items.find((x) => x.id === subordinateId);

    const response = await employeesRequests.addSubordinateToUser(managerId, subordinateId);
    dispatch({
      type: EMPLOYEES_CONSTANTS.ADD_SUBORDINATE_TO_USER,
      payload: {
        manager,
        subordinate,
      },
    });
    return { status: response.status };
  } catch (e) {
    return await parseErrors(e);
  } finally {
    dispatch(setPending(false));
  }
};

export const removeSubordinateFromUser = (managerId, subordinateId) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    const response = await employeesRequests.removeSubordinateFromUser(managerId, subordinateId);
    dispatch({
      type: EMPLOYEES_CONSTANTS.REMOVE_SUBORDINATE_FROM_USER,
      payload: {
        managerId,
        subordinateId,
      },
    });
    return { status: response.status };
  } catch (e) {
    return await parseErrors(e);
  } finally {
    dispatch(setPending(false));
  }
};

export const archiveUser = (userId) => async (dispatch) => {
  try {
    const response = await usersRequests.archiveUser(userId);
    dispatch({ type: EMPLOYEES_CONSTANTS.ARCHIVE_USER, payload: userId });
    return response.status;
  } catch (e) {
    return e.response.status;
  }
};

export const unarchiveUser = (userId) => async (dispatch) => {
  try {
    const response = await usersRequests.unarchiveUser(userId);
    dispatch({ type: EMPLOYEES_CONSTANTS.UNARCHIVE_USER, payload: userId });
    return response.status;
  } catch (e) {
    return e.response.status;
  }
};

export const getResourceManagers = () => async (dispatch) => {
  dispatch(setPending(true));

  const response = await employeesRequests.getResourceManagers();

  dispatch({
    type: EMPLOYEES_CONSTANTS.GET_RESOURCE_MANAGERS,
    payload: response.data,
  });
  dispatch(setPending(false));
};

export const updateUserResourceManager = (userId, resourceManagerId) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    const response = await employeesRequests.updateUserResourceManager(userId, resourceManagerId);

    let resourceManager = null;

    if (resourceManagerId) {
      const getResourceManagerResponse = await usersRequests.getUserById(resourceManagerId);
      resourceManager = getResourceManagerResponse.data;
    }

    dispatch({
      type: EMPLOYEES_CONSTANTS.UPDATE_RESOURCE_MANAGER,
      payload: { userId, resourceManager },
    });
    return { status: response.status };
  } catch (e) {
    return await parseErrors(e);
  } finally {
    dispatch(setPending(false));
  }
};

export const exportEmployeeTimeTrackRecordChangeHistory = (userId, startDate, endDate) => async (dispatch) => {
  try {
    const response = await employeesRequests.exportEmployeeTimeTrackRecordChangeHistory(userId, startDate, endDate);
    downloadExcelFile(response);
    return { status: response.status };
  } catch (e) {
    return await parseErrors(e);
  }
};

export const clearEmployees = () => (dispatch) => {
  dispatch({ type: EMPLOYEES_CONSTANTS.CLEAR_EMPLOYEES });
};
