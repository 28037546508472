import { connect } from 'react-redux';
import MonthPicker from '../../../../common/DatePickers/MonthPicker';
import { getMonthlyUserActivities } from '../../../../../redux/modules/activities/activitiesActions';
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getMonthlyReport: firstDayOfMonth => getMonthlyUserActivities(firstDayOfMonth, ownProps.userId),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(MonthPicker);
