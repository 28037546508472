import { connect } from 'react-redux';
import Task from './Task';
import {
  removeTask,
  sendUpdatedEmployeesTimeTrackWeek,
  sendUpdatedTimeTrackWeek,
} from '../../../../redux/modules/records/recordsActions';
import { removeSickList } from '../../../../redux/modules/sickLists/sickListsActions';
import {
  getRecordsByTaskId,
  getSickLists,
  getTasksByActivityId,
} from '../../../../redux/modules/records/recordsSelectors';
import { showUpdateSickListModal } from '../../../../redux/modules/modals/modalsActions';
import { getCurrentUser } from '../../../../redux/modules/users/usersSelectors';

const mapStateToProps = (state, props) => ({
  tasks: getTasksByActivityId(state, props),
  timeTrackRecords: getRecordsByTaskId(state, props),
  sickLists: getSickLists(state),
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = {
  removeTask,
  removeSickList,
  showUpdateSickListModal,
  sendUpdatedTimeTrackWeek,
  sendUpdatedEmployeesTimeTrackWeek,
};

export default connect(mapStateToProps, mapDispatchToProps)(Task);
