import React, { useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CloseIcon from '@mui/icons-material/Close';

import HoursRowContainer, { ROW_TYPE } from '../HoursRow/HoursRowContainer';
import MultilineInputField from '../../../fields/MutilineInputField';

const PREFIX = 'Task';

const classes = {
  description: `${PREFIX}-description`,
  descriptionWrapper: `${PREFIX}-descriptionWrapper`,
  closeIcon: `${PREFIX}-closeIcon`,
  isNotLined: `${PREFIX}-isNotLined`,
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.description}`]: {
    paddingLeft: '3.6vw',
    paddingRight: '0px',
  },

  [`& .${classes.descriptionWrapper}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.closeIcon}`]: {
    color: 'red',
    cursor: 'pointer',
  },

  [`& .${classes.isNotLined}`]: {
    border: 'none !important',
  },
}));

const Task = (props) => {
  const {
    isUnderlined,
    focusDescriptionOnMount,
    isReadonly,
    isOnlyDescription,
    tasks,
    taskId,
    removeSickList,
    showUpdateSickListModal,
    sickLists,
    userId,
    currentUser,
    sendUpdatedTimeTrackWeek,
    sendUpdatedEmployeesTimeTrackWeek,
  } = props;
  const [task] = useState(tasks.find((task) => task.id === taskId));

  const uniqueName = useMemo(
    () => (value) =>
      tasks.find((task) => task.description === value && task.id !== taskId) ? 'Should be unique' : undefined,
    []
  );

  const notEmpty = useMemo(() => (value) => value ? undefined : 'Required', []);

  const handleUpdateSickLists = () => {
    if (userId === currentUser.id) {
      sendUpdatedTimeTrackWeek();
    } else {
      sendUpdatedEmployeesTimeTrackWeek(userId);
    }
  };

  const removeTask = () => {
    if (task.isSickList) {
      removeSickList(task.sickListId);
      handleUpdateSickLists();
    } else {
      props.removeTask(taskId);
    }
  };

  const openUpdateSickListModal = () => {
    if (!task.isSickList) return;
    const sickList = sickLists.find((x) => x.id === task.sickListId);
    showUpdateSickListModal(sickList, handleUpdateSickLists);
  };

  return (
    <StyledTableRow>
      <TableCell className={`${classes.description} ${!isUnderlined && classes.isNotLined}`}>
        <div className={classes.descriptionWrapper}>
          <MultilineInputField
            placeholder="New description"
            name={`description-${taskId}`}
            validate={[notEmpty, uniqueName]}
            style={{ width: '100%', cursor: task.isSickList ? 'pointer' : 'auto' }}
            focusOnMount={focusDescriptionOnMount}
            InputProps={{ readOnly: isReadonly }}
            onClick={openUpdateSickListModal}
          />
          <CloseIcon className={classes.closeIcon} onClick={removeTask} />
        </div>
      </TableCell>

      {!isOnlyDescription && (
        <HoursRowContainer
          display={ROW_TYPE.INPUT}
          totalHoursPerDay={props.timeTrackRecords}
          totalHoursPerWeek={props.timeTrackRecords.reduce((total, record) => total + record.hours, 0)}
          activityType={props.activityType}
          indexTimeTrackInfo={props.indexTimeTrackInfo}
          taskId={taskId}
          userActivityId={props.userActivityId}
          isUnderlined={isUnderlined}
          isReadonly={isReadonly}
        />
      )}
    </StyledTableRow>
  );
};

Task.propTypes = {
  description: PropTypes.string,
  timeTrackRecords: PropTypes.arrayOf(PropTypes.object).isRequired,
  activityType: PropTypes.number,
  indexTimeTrackInfo: PropTypes.number,
};

export default React.memo(Task);
