import REPORT_CONSTANTS from './reportConstants';

const defaultState = {
  monthlyUserReports: {
    totalItems: 0,
    reports: [],
  },
  yearlyUserReports: {
    totalItems: 0,
    reports: [],
  },
  isLoading: true,
  isYearlyReportLoading: true,
};

const reportReducer = (state = defaultState, action) => {
  switch (action.type) {
    case REPORT_CONSTANTS.GET_MONTHLY_REPORT:
      return handleGetMonthlyReport(state, action.payload);
    case REPORT_CONSTANTS.MONTHLY_REPORT_PENDING:
      return { ...state, isLoading: action.payload };
    case REPORT_CONSTANTS.GET_YEARLY_REPORT:
      return handleGetYearlyReport(state, action.payload);
    case REPORT_CONSTANTS.YEARLY_REPORT_PENDING:
      return { ...state, isYearlyReportLoading: action.payload };
    case REPORT_CONSTANTS.CLEAR_MONTHLY_REPORT:
      return handleClearMonthlyReport(state);
    case REPORT_CONSTANTS.CLEAR_YEARLY_REPORT:
      return handleClearYearlyReport(state);
    default:
      return state;
  }
};

const handleGetMonthlyReport = (state, payload) => {
  return {
    ...state,
    monthlyUserReports: {
      ...payload,
      reports: [...state.monthlyUserReports.reports, ...payload.reports],
    },
    isLoading: false,
  };
};

const handleGetYearlyReport = (state, payload) => {
  return {
    ...state,
    yearlyUserReports: {
      ...payload,
      reports: [...state.yearlyUserReports.reports, ...payload.reports],
    },
    isYearlyReportLoading: false,
  };
};

const handleClearMonthlyReport = (state) => {
  return {
    ...state,
    monthlyUserReports: defaultState.monthlyUserReports,
  };
};

const handleClearYearlyReport = (state) => {
  return {
    ...state,
    yearlyUserReports: defaultState.yearlyUserReports,
  };
};

export default reportReducer;
