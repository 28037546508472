const weeks = [
  { id: 1, day: 'Mon' },
  { id: 2, day: 'Tue' },
  { id: 3, day: 'Wed' },
  { id: 4, day: 'Thu' },
  { id: 5, day: 'Fri' },
  { id: 6, day: 'Sat' },
  { id: 7, day: 'Sun' },
];
export default weeks;
