import React from 'react';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import InputField from '../../../fields/InputField';

const PREFIX = 'ActivitiesSearchForm';

const classes = {
  input: `${PREFIX}-input`,
};

const Root = styled('form')(({ theme }) => ({
  flex: '0 50%',

  [`& .${classes.input}`]: {
    border: 'none',
    overflow: 'auto',
    width: '100%',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '-10px 5% 5px 0px',
  },
}));

const ActivitiesForm = (props) => {
  const { handleSubmit } = props;
  return (
    <Root onSubmit={handleSubmit}>
      <InputField
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        className={classes.input}
        name="name"
        label="Search"
      />
    </Root>
  );
};

export default ActivitiesForm;
