import React from 'react';
import AsyncPaginateSelect from '../common/InfiniteSelect/AsyncPaginateSelect';

const InnerAsyncPaginateSelectField = ({ input, label, ...custom }) => {
  return (
    <AsyncPaginateSelect
      value={input.value}
      onChange={(value) => {
        if (value) {
          input.onChange(value);
        } else {
          input.onChange(null);
        }
      }}
      {...custom}
    />
  );
};

export default InnerAsyncPaginateSelectField;
