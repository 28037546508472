import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const PREFIX = 'MenuItemLink';

const classes = {
  root: `${PREFIX}-root`,
  logoWrapper: `${PREFIX}-logoWrapper`,
  logo: `${PREFIX}-logo`,
  menuWrapper: `${PREFIX}-menuWrapper`,
  menuItem: `${PREFIX}-menuItem`,
  menuLink: `${PREFIX}-menuLink`,
  menuItemIcon: `${PREFIX}-menuItemIcon`,
  logoutButton: `${PREFIX}-logoutButton`,
  menuItemText: `${PREFIX}-menuItemText`,
  smallDevicesWrapper: `${PREFIX}-smallDevicesWrapper`,
  menuItemTextMargin: `${PREFIX}-menuItemTextMargin`,
};

const Root = styled('div')(({ theme }) => ({
  flex: 1,
  outline: 'none',
  padding: '5px 0',

  [`& .${classes.logoWrapper}`]: {
    padding: '16px 0 20px',
  },

  [`& .${classes.logo}`]: {
    height: '3rem',
    width: '9rem',
    backgroundImage: `url('/logo.svg')`,
    backgroundSize: 'cover',
  },

  [`& .${classes.menuWrapper}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.menuLink}`]: {
    '&:hover': {
      backgroundColor: 'none',
    },
    textDecoration: 'none',
    display: 'flex',
  },

  [`& .${classes.menuItemIcon}`]: {
    marginRight: '10px',
  },

  [`& .${classes.logoutButton}`]: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
  },

  [`& .${classes.menuItemText}`]: {
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
      color: 'rgba(255, 255, 255, 1)',
    },
  },

  [`& .${classes.menuItemTextMargin}`]: {
    marginLeft: '22px',
  },

  [`& .${classes.smallDevicesWrapper}`]: {
    position: 'absolute',
    height: 'calc(100% - 58px)',
    top: '58px',
    padding: '0 20px',
    background: `linear-gradient(to bottom, ${theme.palette.secondary.dark}, ${theme.palette.secondary.light})`,
  },
}));

const MenuItemLink = (props) => {
  const { link, text, Icon, onItemClick } = props;
  return (
    <Root onClick={() => onItemClick()}>
      <Link className={classes.menuLink} to={link}>
        {Icon && <img className={classes.menuItemIcon} alt={link} src={Icon} />}
        <Typography className={`${classes.menuItemText} ${!Icon && classes.menuItemTextMargin}`}>{text}</Typography>
      </Link>
    </Root>
  );
};

MenuItemLink.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default MenuItemLink;
