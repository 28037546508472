import React from 'react';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

const PREFIX = 'OOOActivitiesModal';

const classes = {
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
  listItemText: `${PREFIX}-listItemText`,
  paper: `${PREFIX}-paper`,
  flexContainer: `${PREFIX}-flexContainer`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.list}`]: {
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
    height: '110px',
    overflowY: 'auto',
    backgroundColor: theme.palette.white,
    margin: '10px 0',
    padding: '10px 10px 10px 0',
  },

  [`& .${classes.listItem}`]: {
    height: '36px',
  },

  [`& .${classes.listItemText}`]: {
    fontSize: '18px',
    margin: '5px 0',
    color: `${theme.palette.secondary.main} !important`,
  },

  [`& .${classes.paper}`]: {
    position: 'absolute',
    width: theme.spacing(35),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },

  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5% 0px',
  },
}));

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const OOOActivitiesModal = (props) => {
  const { vacationHours, sickLeaveHours, unpaidLeaveHours, hideModal } = props;

  return (
    <StyledModal open={true}>
      <div style={getModalStyle()} className={classes.paper}>
        <div className={classes.flexContainer}>
          <List className={classes.list} component="nav">
            <ListItem className={classes.listItem} dense button>
              <Typography className={classes.listItemText}>Vacation : {vacationHours} hours</Typography>
            </ListItem>
            <ListItem className={classes.listItem} dense button>
              <Typography className={classes.listItemText}>Sick Leave : {sickLeaveHours} hours</Typography>
            </ListItem>
            <ListItem className={classes.listItem} dense button>
              <Typography className={classes.listItemText}>Unpaid Leave : {unpaidLeaveHours} hours</Typography>
            </ListItem>
          </List>
        </div>
        <div className={classes.flexContainer}>
          <Button onClick={hideModal}>Close</Button>
        </div>
      </div>
    </StyledModal>
  );
};

export default OOOActivitiesModal;
