import React from 'react';
import TextField from '@mui/material/TextField';

const InnerInputField = ({ isCentered, focusOnMount, input, label, required, meta: { touched, error }, ...custom }) => {
  let styles = {};
  if (isCentered) {
    styles.textAlign = 'center';
  }
  styles.fontSize = 14;
  if (input.value === 0) {
    styles.color = '#7f7f7f';
  } else if (!isNaN(Number.parseFloat(input.value))) {
    styles.fontWeight = 600;
  }
  const innerProps = { style: styles };

  return (
    <TextField
      variant="standard"
      autoFocus={focusOnMount}
      label={touched && error ? error : label}
      error={touched && error !== undefined}
      inputProps={innerProps}
      required={required}
      {...input}
      {...custom}
    />
  );
};

export default InnerInputField;
