import { connect } from 'react-redux';
import { getYear } from '../../../helper/getDateFromQuery';
import YearlyReport from './YearlyReport';
import { getYearlyReport, clearYearlyReport } from '../../../../redux/modules/report/reportActions';

const mapStateToProps = (state, ownProps) => {
  return {
    isYearlyReportLoading: state.report.isYearlyReportLoading,
    year: getYear(ownProps.location.search),
  };
};

const mapDispatchToProps = {
  getYearlyReport,
  clearYearlyReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YearlyReport);
