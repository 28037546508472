export const parseErrors = async (e) => {
  console.error(e);
  const {
    response: { status, request },
  } = e;

  let responseObject;
  if (request.response.constructor.name === 'Blob') {
    responseObject = JSON.parse(await request.response.text());
  } else {
    responseObject = JSON.parse(request.response);
  }

  const responseText = request.response ? [...new Set(Object.values(responseObject.errors)[0])] : '';
  return { status: status, text: responseText };
};
