import VACATION_CONSTANTS from './vacationsConstants';
import * as vacationRequests from '../../../axios/vacation';

const setPending = (isPending) => (dispatch) => {
  dispatch({
    type: VACATION_CONSTANTS.VACATION_PENDING,
    payload: isPending,
  });
};

export const getVacationHistory = (vacationType, pageNumber, pageSize, startDate, endDate) => async (dispatch) => {
  dispatch(setPending(true));
  const response = await vacationRequests.getVacationHistory(vacationType, pageNumber, pageSize, startDate, endDate);

  dispatch({
    type: VACATION_CONSTANTS.GET_VACATION_HISTORY,
    payload: response.data,
  });
  dispatch(setPending(false));
};
