import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import UpdateActivityForm from './UpdateActivityForm';
import { updateActivity } from '../../../redux/modules/activities/activitiesActions';
import Notification from './../../common/Notification/Notification';

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.activityType) {
    errors.activityType = 'Required';
  }
  return errors;
};

const selector = formValueSelector('updateActivityForm');

const mapStateToProps = (state) => ({
  initialValues: state.modals.modalProps,
  name: selector(state, 'name'),
  activityType: selector(state, 'activityType'),
  description: selector(state, 'description'),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'updateActivityForm',
    validate,
    onSubmit: (payload, dispatch, props) => {
      dispatch(updateActivity(props.id, payload.name, payload.activityType.value, payload.description)).then(
        ({ status, text }) => {
          if (status === 500 || status === 404 || status === 400) {
            Notification('warning', text)();
          } else {
            Notification('success')();
          }
        }
      );
    },
  })(UpdateActivityForm)
);
