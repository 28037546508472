import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { loadUser } from 'redux-oidc';
import { composeWithDevTools } from '@redux-devtools/extension';
import rootReducer from './reducers/rootReducer';
import userManager from '../oidc/userManager';
import accessTokenHandlerMiddleware from '../middlewares/accessTokenHandlerMiddleware';

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, accessTokenHandlerMiddleware)));
loadUser(store, userManager);

export default store;
