import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import { styled } from '@mui/material/styles';
import PageTitle from '../../common/PageTitle/PageTitle';
import HeaderBarContainer from '../../common/HeaderBarWrapper/HeaderBar/HeaderBarContainer';
import VacationHistoryTable from './VacationHistoryTable';
import {
  selectVacationHistory,
  selectVacationIsLoading,
  selectVacationTotalItems,
} from '../../../redux/modules/vacation/vacationSelectors';
import paginationConstants from '../../helper/paginationConstants';
import { getVacationHistory } from '../../../redux/modules/vacation/vacationActions';
import FiltersFormContainer from './Filters/FiltersFormContainer';

const PREFIX = 'VacationHistoryPage';

const classes = {
  contentWrapper: `${PREFIX}-contentWrapper`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
  height: '100%',

  [`& .${classes.contentWrapper}`]: {
    display: 'flex',
    height: 'calc(100vh - 58px)',
    flexDirection: 'column',
    backgroundColor: theme.palette.whitegrey,
    padding: '30px 250px',
    overflow: 'auto',
  },

  [`& .${classes.filtersContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.whitegrey,
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
    padding: '5px 10px',
  },
}));

const VacationHistoryPage = () => {
  const pageTitle = 'Vacation History';
  const itemsPerPage = paginationConstants.itemsPerPage;

  const dispatch = useDispatch();
  const vacationHistory = useSelector(selectVacationHistory);
  const vacationIsLoading = useSelector(selectVacationIsLoading);
  const totalVacationRecords = useSelector(selectVacationTotalItems);

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    totalItems: totalVacationRecords,
    itemsPerPage: itemsPerPage,
  });

  const [filters, setFilters] = useState({ vacationType: null, dateRange: null });

  useEffect(() => {
    setPagination((prev) => ({ ...prev, totalItems: totalVacationRecords }));
  }, [totalVacationRecords]);

  useEffect(() => {
    let startDate;
    let endDate;
    if (filters.dateRange) {
      startDate = filters.dateRange[0];
      endDate = filters.dateRange[1];
    }

    dispatch(
      getVacationHistory(filters.vacationType, pagination.pageNumber, pagination.itemsPerPage, startDate, endDate)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.itemsPerPage, pagination.pageNumber, filters.vacationType, filters.dateRange]);

  const handlePageChange = useCallback((event, page) => {
    event.preventDefault();
    setPagination((prev) => ({ ...prev, pageNumber: page }));
  }, []);

  const handleFiltersChange = useCallback((filters) => {
    setFilters(filters);
    setPagination((prev) => ({ ...prev, pageNumber: 1 }));
  }, []);

  return (
    <>
      <PageTitle pageTitle={pageTitle} />
      <Root>
        <HeaderBarContainer pageTitle={pageTitle} />
        <div className={classes.contentWrapper}>
          <FiltersFormContainer className={classes.filtersContainer} onFiltersChange={handleFiltersChange} />
          <VacationHistoryTable
            history={vacationHistory}
            isLoading={vacationIsLoading}
            pagination={pagination}
            onPageChange={handlePageChange}
          />
        </div>
      </Root>
      <NotificationContainer />
    </>
  );
};

export default VacationHistoryPage;
