import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import DateRangePickerField from '../../fields/DateRangePickerFIeld';

const PREFIX = 'SickListModalForm';

const classes = {
  flexContainer: `${PREFIX}-flexContainer`,
  button: `${PREFIX}-button`,
};

const Root = styled('form')(({ theme }) => ({
  [`& .${classes.button}`]: {
    margin: '5px 0px 0 10px',
    padding: '4px 16px',
    fontSize: '18px',
    minWidth: 180,
  },

  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5% 0px',
  },
}));

const ExportWeekChangeHistoryForm = ({ handleSubmit, onCancel }) => (
  <Root onSubmit={handleSubmit}>
    <DateRangePickerField name="dateRange" />
    <div className={classes.flexContainer}>
      <Button onClick={onCancel} color="cancelButton" variant="contained" className={classes.button}>
        Cancel
      </Button>
      <Button color="secondary" type="submit" variant="contained" className={classes.button}>
        Export
      </Button>
    </div>
  </Root>
);

export default ExportWeekChangeHistoryForm;
