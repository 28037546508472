import axios from 'axios';
import { withAPIFormat } from '../components/helper/momentJsExtensions';

export const getActivities = async (pageNumber, pageSize, name, excludeIds) => {
  const excludeIdsString = excludeIds && excludeIds.map((id) => `excludeIds=${id}`).join('&');
  return await axios.get(`/api/activities?${excludeIdsString}`, {
    params: {
      pageNumber,
      pageSize,
      name,
    },
  });
};

export const getUserActivities = async (userId) => {
  return await axios.get(`/api/employee/${userId}/activities`);
};

export const getMonthlyUserActivities = async (firstDayOfMonth, userId) => {
  return await axios.get(`/api/employee/${userId}/activities/month`, {
    params: { firstDayOfMonth: withAPIFormat(firstDayOfMonth) },
  });
};

export const getWeeklyUserActivities = async (firstDayOfWeek, userId) => {
  return await axios.get(`/api/employee/${userId}/activities/week`, {
    params: { firstDayOfWeek: withAPIFormat(firstDayOfWeek) },
  });
};

export const getYearlyUserActivities = async (year, userId) => {
  return await axios.get(`/api/employee/${userId}/activities/year`, { params: { year: year } });
};

export const addActivity = async (name, activityType) => {
  return await axios.post('/api/activities', { name: name, activityType: activityType });
};

export const deleteActivity = async (id) => {
  return await axios.delete('/api/activities', { params: { id } });
};

export const updateActivity = async (id, name, activityType, description) => {
  return await axios.put(
    '/api/activities',
    { name: name, activityType: activityType, description: description },
    { params: { id } }
  );
};

export const addUserToActivity = async (activityId, userId) => {
  return await axios.post('/api/users/addactivity', { activityId, userId });
};

export const deleteUserFromActivity = async (activityId, userId) => {
  return await axios.post('/api/users/deleteactivity', {
    activityId: activityId,
    userId: userId,
  });
};
