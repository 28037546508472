import React from 'react';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import PagesSwitcher from '../../common/PagesSwitcher/PagesSwitcher';
import { useState } from 'react';
import MonthlyExcelReportContainer from './MonthlyExcelReport/MonthlyExcelReportContainer';
import YearlyExcelReportContainer from './YearlyExcelReport/YearlyExcelReportContainer';

const PREFIX = 'ExcelReportModal';

const classes = {
  paper: `${PREFIX}-paper`,
  title: `${PREFIX}-title`,
  userFullName: `${PREFIX}-userFullName`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    position: 'absolute',
    width: theme.spacing(80),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },

  [`& .${classes.title}`]: {
    fontSize: '20px',
    textAlign: 'center',
    margin: '10px',
  },

  [`& .${classes.userFullName}`]: {
    fontSize: '16px',
    textAlign: 'center',
    margin: '10px',
  },
}));

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const ExcelReportModal = (props) => {
  const { hideModal, userId, userFullName } = props;

  const pages = [
    {
      title: 'Monthly user Excel report',
      description: 'Monthly',
    },
    {
      title: 'Yearly user Excel report',
      description: 'Yearly',
    },
  ];

  const [selectedPage, setSelectedPage] = useState('Monthly user Excel report');
  const [firstDayOfMonth, setFirstDayOfMonth] = useState(props.firstDayOfMonth);
  const [year, setYear] = useState(props.year);

  const handlePageChange = (pageTitle) => {
    setSelectedPage(pageTitle);
  };

  const handleMonthChange = (firstDayOfMonth) => {
    setFirstDayOfMonth(firstDayOfMonth);
  };
  const handleYearChange = (firstDayOfYear) => {
    setYear(firstDayOfYear);
  };

  return (
    <StyledModal open>
      <div style={getModalStyle()} className={classes.paper}>
        <Typography className={classes.title}>{selectedPage}</Typography>
        {userFullName && <Typography className={classes.userFullName}>{userFullName}</Typography>}
        <PagesSwitcher pages={pages} selectedPage={selectedPage} handlePageChange={handlePageChange} />
        {selectedPage === pages[0].title ? (
          <MonthlyExcelReportContainer
            hideModal={hideModal}
            userId={userId}
            firstDayOfMonth={firstDayOfMonth}
            monthChanged={handleMonthChange}
          />
        ) : (
          <YearlyExcelReportContainer
            hideModal={hideModal}
            userId={userId}
            year={year}
            yearChanged={handleYearChange}
          />
        )}
      </div>
    </StyledModal>
  );
};

export default ExcelReportModal;
