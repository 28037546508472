export default Object.freeze({
  GET_TIMETRACK_WEEK: 'GET_TIMETRACK_WEEK',
  TIMETRACK_WEEK_PENDING: 'TIMETRACK_WEEK_PENDING',
  REMOVE_TASK: 'REMOVE_TASK',
  UPDATE_TASK: 'UPDATE_TASK',
  ADD_TASK: 'ADD_TASK',
  UPDATE_TIMETRACK_RECORD: 'UPDATE_TIMETRACK_RECORD',
  ADD_TIMETRACK_RECORD: 'ADD_TIMETRACK_RECORD',
  DELETE_TIMETRACK_RECORD: 'DELETE_TIMETRACK_RECORD',
  SET_FIRST_DAY_OF_WEEK: 'SET_FIRST_DAY_OF_WEEK',
  UPDATE_EMPTY_TIMETRACK_RECORD: 'UPDATE_EMPTY_TIMETRACK_RECORD',
  ADD_UPDATE_SICK_LIST: 'ADD_UPDATE_SICK_LIST',
});
