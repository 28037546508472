import axios from 'axios';

export const getProductionCalendarByUserId = async (userId) => {
  return axios.get(`/api/production-calendar/${userId}`);
};

export const setProductionCalendarByUserId = async (userId, productionCalendarCountry) => {
  return axios.put(`/api/production-calendar/${userId}`, {
    country: productionCalendarCountry,
  });
};

export const getCurrentUserProductionCalendar = async () => {
  return axios.get('/api/production-calendar');
};

export const getProductionCalendarsList = async () => {
  return axios.get('/api/production-calendar/list');
};
