import React from 'react';
import Select from 'react-select';
import InfiniteSelectMenuList from './InfiniteSelectMenuList';

const AsyncPaginateSelect = ({ loadMore, hasMore, isLoading, ...custom }) => {
  return (
    <Select
      components={{ MenuList: InfiniteSelectMenuList }}
      innerProps={{ loadMore: loadMore, hasMore: hasMore, isLoading: isLoading }}
      {...custom}
    />
  );
};

export default AsyncPaginateSelect;
