import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import FilterForm from './FilterForm';
import employeeStatusesForSelect from '../../../helper/employeeStatusesForSelect';

const selector = formValueSelector('filterUserForm');

const mapStateToProps = (state) => ({
  status: selector(state, 'status'),
  onlyMySubordinates: selector(state, 'onlyMySubordinates'),
  name: selector(state, 'name'),
  resourceManager: selector(state, 'resourceManager'),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'filterUserForm',
    initialValues: { status: { value: employeeStatusesForSelect[0].id, label: employeeStatusesForSelect[0].name } },
    onChange: (payload, dispatch, props) => {
      const filters = {
        status: payload.status ? payload.status.value : null,
        onlyMySubordinates: payload.onlyMySubordinates ? payload.onlyMySubordinates : false,
        name: payload.name ?? '',
        resourceManager: payload.resourceManager ? payload.resourceManager.value : null,
      };
      props.onFiltersChange(filters);
    },
  })(FilterForm)
);
