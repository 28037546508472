import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../../../redux/modules/modals/modalsActions';
import { getMyManagers } from '../../../redux/modules/employees/employeesActions';
import { getCurrentUser } from '../../../redux/modules/users/usersSelectors';
import { selectEmployeesIsLoading, selectMyManagers } from '../../../redux/modules/employees/employeesSelectors';
import Spinner from '../../common/Spinner/Spinner';

const PREFIX = 'MyManagersModal';

const classes = {
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
  listItemText: `${PREFIX}-listItemText`,
  paper: `${PREFIX}-paper`,
  flexContainer: `${PREFIX}-flexContainer`,
  closeButtonContainer: `${PREFIX}-closeButtonContainer`,
  closeButton: `${PREFIX}-closeButton`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.list}`]: {
    overflowY: 'auto',
    backgroundColor: theme.palette.white,
    margin: '10px 0',
  },

  [`& .${classes.listItem}`]: {
    height: '36px',
  },

  [`& .${classes.listItemText}`]: {
    fontSize: '22px',
    margin: '5px 0',
    color: `${theme.palette.secondary.main} !important`,
  },

  [`& .${classes.paper}`]: {
    position: 'absolute',
    width: theme.spacing(35),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },

  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5% 0px',
  },
  [`& .${classes.closeButtonContainer}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  [`& .${classes.closeButton}`]: {
    cursor: 'pointer',
    width: '32px',
    height: '32px',
  },
}));

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    width: '340px',
  };
};

const MyManagersModal = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const managers = useSelector(selectMyManagers);
  const isLoading = useSelector(selectEmployeesIsLoading);

  useEffect(() => {
    dispatch(getMyManagers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleClose = () => {
    dispatch(hideModal());
  };

  return (
    <StyledModal open={true}>
      <div style={getModalStyle()} className={classes.paper}>
        <div className={classes.closeButtonContainer}>
          <CloseIcon className={classes.closeButton} onClick={handleClose} color="grey" />
        </div>
        <div className={classes.flexContainer}>
          {isLoading ? (
            <Spinner />
          ) : (
            <List className={classes.list} component="nav">
              {managers.length > 0 ? (
                managers.map((manager) => (
                  <ListItem key={manager.id} className={classes.listItem} dense>
                    <Typography className={classes.listItemText}>{manager.name}</Typography>
                  </ListItem>
                ))
              ) : (
                <ListItem className={classes.listItem} dense>
                  <Typography className={classes.listItemText}>You don't have managers</Typography>
                </ListItem>
              )}
            </List>
          )}
        </div>
      </div>
    </StyledModal>
  );
};

export default MyManagersModal;
