import React, { useEffect } from 'react';
import PdfReportWeekPickerContainer from './WeekPicker/PdfReportWeekPickerContainer';
import WeeklyPdfReportFormContainer from './WeeklyPdfReportFormContainer';
import TableBodySpinner from '../../../common/TableSpinners/TableBodySpinner';
import Table from '@mui/material/Table';

const WeeklyPdfReport = (props) => {
  const { hideModal, userId, firstDayOfWeek, weekChanged, userActivities, isLoading, getWeeklyUserActivities } = props;

  useEffect(() => {
    getWeeklyUserActivities(firstDayOfWeek, userId);
  }, [firstDayOfWeek, getWeeklyUserActivities, userId]);

  return (
    <>
      <PdfReportWeekPickerContainer firstDayOfWeek={firstDayOfWeek} userId={userId} onWeekChanged={weekChanged} />
      {isLoading ? (
        <Table>
          <TableBodySpinner />
        </Table>
      ) : (
        <WeeklyPdfReportFormContainer
          onCancel={hideModal}
          userId={userId}
          firstDayOfWeek={firstDayOfWeek}
          userActivities={userActivities}
        />
      )}
    </>
  );
};

export default WeeklyPdfReport;
