import { connect } from 'react-redux';
import Activity from './Activity';
import { addTask } from '../../../../redux/modules/records/recordsActions';
import { getTotalsByActivityId, getTasksByActivityId } from '../../../../redux/modules/records/recordsSelectors';

const mapStateToProps = (state, props) => ({
  totals: getTotalsByActivityId(state, props),
  tasks: getTasksByActivityId(state, props),
});

const mapDispatchToProps = {
  addTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
