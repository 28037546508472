import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentUser } from '../redux/modules/users/usersSelectors';

const withRoles = (allowedRoles) => (WrappedComponent) => {
  const WithRoles = (props) => {
    const { user, dispatch, ...other } = props;
    if (user && !user.authorization.expired && allowedRoles.some((role) => user.role.includes(role))) {
      return <WrappedComponent {...other} />;
    } else {
      return null;
    }
  };

  WithRoles.propTypes = {
    user: PropTypes.object,
  };

  const mapStateToProps = (state) => ({
    user: getCurrentUser(state),
  });

  return connect(mapStateToProps)(WithRoles);
};

export default withRoles;
