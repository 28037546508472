import React from 'react';
import { styled } from '@mui/material/styles';
import HeaderBarContainer from '../../common/HeaderBarWrapper/HeaderBar/HeaderBarContainer';
import PageTitle from '../../common/PageTitle/PageTitle';
import ManageSubordinates from './ManageSubordinates';
import { NotificationContainer } from '../../../../node_modules/react-notifications/lib/index';

const PREFIX = 'UserActivitiesPage';

const classes = {
  contentWrapper: `${PREFIX}-contentWrapper`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
  height: '100%',

  [`& .${classes.contentWrapper}`]: {
    display: 'flex',
    height: 'calc(100vh - 58px)',
    flexDirection: 'column',
    backgroundColor: theme.palette.whitegrey,
    padding: '30px 0',
    overflow: 'auto',
  },
}));

const ManageSubordinatesPage = () => {
  const pageTitle = 'Manage subordinates';
  return (
    <>
      <PageTitle pageTitle={pageTitle} />
      <Root>
        <HeaderBarContainer pageTitle={pageTitle} />
        <div className={classes.contentWrapper}>
          <ManageSubordinates />
        </div>
      </Root>
      <NotificationContainer />
    </>
  );
};

export default ManageSubordinatesPage;
