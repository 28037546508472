import employeeStatuses from '../../../components/helper/employeeStatuses';
import EMPLOYEES_CONSTANTS from './employeesConstants';

const defaultState = {
  employees: [],
  resourceManagers: [],
  myManagers: [],
  totalItems: 0,
  isLoading: false,
};

const employeesReducers = (state = defaultState, action) => {
  switch (action.type) {
    case EMPLOYEES_CONSTANTS.EMPLOYEES_PENDING:
      return { ...state, isLoading: action.payload };
    case EMPLOYEES_CONSTANTS.GET_EMPLOYEES:
      return handleGetEmployees(state, action.payload);
    case EMPLOYEES_CONSTANTS.GET_SUBORDINATES_BY_USER_ID:
      return handleGetSubordinatesByUserId(state, action.payload);
    case EMPLOYEES_CONSTANTS.GET_MANAGERS_BY_USER_ID:
      return handleGetManagersByUserId(state, action.payload);
    case EMPLOYEES_CONSTANTS.CLEAR_EMPLOYEES:
      return handleClearEmployees();
    case EMPLOYEES_CONSTANTS.ADD_SUBORDINATE_TO_USER:
      return handleAddSubordinateToUser(state, action.payload);
    case EMPLOYEES_CONSTANTS.REMOVE_SUBORDINATE_FROM_USER:
      return handleRemoveSubordinateFromUser(state, action.payload);
    case EMPLOYEES_CONSTANTS.ARCHIVE_USER:
      return handleArchiveUser(state, action.payload);
    case EMPLOYEES_CONSTANTS.UNARCHIVE_USER:
      return handleUnarchiveUser(state, action.payload);
    case EMPLOYEES_CONSTANTS.GET_RESOURCE_MANAGERS:
      return handleGetResourceManagers(state, action.payload);
    case EMPLOYEES_CONSTANTS.UPDATE_RESOURCE_MANAGER:
      return handleUpdateUserResourceManager(state, action.payload);
    case EMPLOYEES_CONSTANTS.GET_MY_MANAGERS:
      return handleGetMyManagers(state, action.payload);
    default:
      return state;
  }
};

const handleGetEmployees = (state, payload) => {
  return {
    ...state,
    employees: payload.employees,
    totalItems: payload.totalItems,
  };
};

const handleGetSubordinatesByUserId = (state, payload) => {
  return {
    ...state,
    employees: payload.employees,
    totalItems: payload.totalItems,
  };
};

const handleGetManagersByUserId = (state, payload) => {
  return {
    ...state,
    employees: payload.employees,
    totalItems: payload.totalItems,
  };
};

const handleArchiveUser = (state, payload) => {
  return {
    ...state,
    employees: state.employees.map((employee) =>
      employee.id === payload ? { ...employee, status: employeeStatuses.archived } : employee
    ),
  };
};

const handleUnarchiveUser = (state, payload) => {
  return {
    ...state,
    employees: state.employees.map((employee) =>
      employee.id === payload ? { ...employee, status: employeeStatuses.active } : employee
    ),
  };
};

const handleClearEmployees = () => {
  return defaultState;
};

const handleAddSubordinateToUser = (state, payload) => {
  return {
    ...state,
    employees: [
      ...state.employees,
      {
        id: payload.subordinate.id,
        firstName: payload.subordinate.name.split(' ')[0],
        lastName: payload.subordinate.name.split(' ')[1],
      },
    ],
  };
};

const handleRemoveSubordinateFromUser = (state, payload) => {
  return {
    ...state,
    employees: state.employees.filter((emp) => emp.id !== payload.subordinateId),
  };
};

const handleGetResourceManagers = (state, payload) => {
  return {
    ...state,
    resourceManagers: payload,
  };
};

const handleUpdateUserResourceManager = (state, payload) => {
  const { userId, resourceManager } = payload;

  return {
    ...state,
    employees: state.employees.map((emp) =>
      emp.id === userId
        ? {
            ...emp,
            resourceManager: resourceManager
              ? {
                  id: resourceManager.id,
                  firstName: resourceManager.name.split(' ')[0],
                  lastName: resourceManager.name.split(' ')[1],
                }
              : null,
          }
        : emp
    ),
  };
};

const handleGetMyManagers = (state, payload) => {
  return {
    ...state,
    myManagers: payload,
  };
};

export default employeesReducers;
