export default Object.freeze({
  ADD_ACTIVITY: 'ADD_ACTIVITY',
  DELETE_ACTIVITY: 'DELETE_ACTIVITY',
  UPDATE_ACTIVITY: 'UPDATE_ACTIVITY',
  GET_ACTIVITIES: 'GET_ACTIVITIES',
  GET_USER_ACTIVITIES: 'GET_USER_ACTIVITIES',
  GET_MONTHLY_USER_ACTIVITIES: 'GET_MONTHLY_USER_ACTIVITIES',
  GET_WEEKLY_USER_ACTIVITIES: 'GET_WEEKLY_USER_ACTIVITIES',
  GET_YEARLY_USER_ACTIVITIES: 'GET_YEARLY_USER_ACTIVITIES',
  ADD_USER_TO_ACTIVITY: 'ADD_USER_TO_ACTIVITY',
  DELETE_USER_FROM_ACTIVITY: 'DELETE_USER_FROM_ACTIVITY',
  ACTIVITIES_PENDING: 'ACTIVITIES_PENDING',
  MONTHLY_USER_ACTIVITIES_PENDING: 'MONTHLY_USER_ACTIVITIES_PENDING',
  WEEKLY_USER_ACTIVITIES_PENDING: 'WEEKLY_USER_ACTIVITIES_PENDING',
  YEARLY_USER_ACTIVITIES_PENDING: 'YEARLY_USER_ACTIVITIES_PENDING',
  CLEAR_ACTIVITIES: 'CLEAR_ACTIVITIES',
  CLEAR_USER_ACTIVITIES: 'CLEAR_USER_ACTIVITIES',
});
