import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CallbackComponent } from 'redux-oidc';
import userManager from '../../../oidc/userManager';
import FullScreenLoader from '../../common/FullScreenLoader/FullScreenLoader';

const MyCallbackPage = () => {
  const navigate = useNavigate();

  const successCallback = (user) => {
    navigate(user.state.redirectUrl || '/');
  };

  const errorCallback = (error) => {
    console.error('There was an error handling the token callback: ' + error.message);
  };

  return (
    <CallbackComponent
      successCallback={successCallback}
      errorCallback={errorCallback}
      userManager={userManager}
      route="/"
    >
      <FullScreenLoader />
    </CallbackComponent>
  );
};

export default MyCallbackPage;
