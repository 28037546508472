import { createSelector } from 'reselect';

const employeesStateSelector = (state) => state.employees;

export const selectEmployees = createSelector([employeesStateSelector], (state) => state.employees);

export const selectMyManagers = createSelector([employeesStateSelector], (state) => state.myManagers);

export const selectEmployeesIsLoading = createSelector([employeesStateSelector], (state) => state.isLoading);

export const selectEmployeesTotalItems = createSelector([employeesStateSelector], (state) => state.totalItems);

export const selectResourceManagers = createSelector([employeesStateSelector], (state) => state.resourceManagers);
