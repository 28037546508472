import { downloadBlob } from './downloadBlob';

export const downloadExcelFile = (response) => {
  const blob = new Blob([response.data]);

  //get file name from response content disposition section
  const contentDisposition = response.headers['content-disposition'];
  const fileNameBegin = contentDisposition.substring(contentDisposition.indexOf('filename') + 'filename'.length + 1);
  const fileName = fileNameBegin.substring(0, fileNameBegin.indexOf(';'));

  downloadBlob(blob, fileName);
};
