import React from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const PREFIX = 'FullScreenLoader';

const classes = {
  root: `${PREFIX}-root`,
  progressWrapper: `${PREFIX}-progressWrapper`,
};

const Root = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.white,
  left: 0,
  top: 0,
  zIndex: 100,

  [`& .${classes.progressWrapper}`]: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const FullScreenLoader = (props) => {
  return (
    <Root>
      <div className={classes.progressWrapper}>
        <CircularProgress size={300} thickness={1} color="secondary" />
      </div>
    </Root>
  );
};

export default FullScreenLoader;
