import moment from 'moment';

export const getDaysOfMonthWithFormat = (firstDayOfMonth, format) => {
  const maxDate = moment(firstDayOfMonth).add(1, 'month');
  const result = [];
  for (let date = moment(firstDayOfMonth); date < maxDate; date.add(1, 'day')) {
    result.push(date.format(format));
  }
  return result;
};

export const withAPIFormat = (date) => {
  return date ? date.format('YYYY-MM-DD') : null;
};

export const getFirstDayOfMonth = (value) => {
  if (value) {
    return moment(value).startOf('month');
  }
};
