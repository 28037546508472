import { connect } from 'react-redux';
import * as modalsAction from '../../../../../redux/modules/modals/modalsActions';
import UserRecordCell from './UserRecordCell';

const mapDispatchToProps = {
  showOOOActivitiesModal: modalsAction.showOOOActivitiesModal,
};

export default connect(
  null,
  mapDispatchToProps
)(UserRecordCell);
