import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import ActionsMenu from './ActionsMenu';
import employeeStatuses from '../../../helper/employeeStatuses';
import TableBodySpinner from '../../../common/TableSpinners/TableBodySpinner';
import withRoles from '../../../../hocs/withRoles';
import roleConstants from '../../../helper/roleConstants';

const PREFIX = 'EmployeesTable';

const classes = {
  table: `${PREFIX}-table`,
  tableBody: `${PREFIX}-tableBody`,
  tableHeader: `${PREFIX}-tableHeader`,
  headerText: `${PREFIX}-headerText`,
  centeredCell: `${PREFIX}-centeredCell`,
  link: `${PREFIX}-link`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.whitegrey,
  boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',

  [`& .${classes.table}`]: {
    minWidth: 700,
  },

  [`& .${classes.tableBody}`]: {
    minHeight: '30vh',
    overflowY: 'auto',
  },

  [`& .${classes.tableHeader}`]: {
    marginBottom: '10px',
    padding: '20px 5px',
    paddingLeft: '25px',
    backgroundColor: theme.palette.grey.light,
  },

  [`& .${classes.headerText}`]: {
    color: theme.palette.grey.dark,
  },

  [`& .${classes.centeredCell}`]: {
    textAlign: 'center',
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
    color: theme.palette.secondary.dark,
  },
}));

const getStatusColor = (status) => (status === employeeStatuses.active ? 'primary' : 'default');

const EmployeesTable = ({ employees, isLoading, pagination, onPageChange }) => {
  const navigate = useNavigate();
  const numberOfPages = Math.ceil(pagination.totalItems / pagination.itemsPerPage);

  const handleSubordinatesButtonClick = (userId) => {
    navigate(`/employees/${userId}/subordinates`);
  };

  const HeaderCell = ({ children, ...props }) => (
    <TableCell {...props}>
      <Typography className={classes.headerText}>{children}</Typography>
    </TableCell>
  );

  const UserEditorTableCell = withRoles([roleConstants.userEditor, roleConstants.superManager])(TableCell);

  const renderRow = (employee) => (
    <TableRow key={employee.id}>
      <TableCell>
        <a className={classes.link} href={`${process.env.REACT_APP_AUTHORITY_URL}/users/${employee.id}`}>
          {employee.firstName} {employee.lastName}
        </a>
      </TableCell>
      <TableCell className={classes.centeredCell}>
        <Chip label={employee.status} color={getStatusColor(employee.status)} />
      </TableCell>
      <TableCell className={classes.centeredCell}>{employee.email}</TableCell>
      <TableCell className={classes.centeredCell}>
        {employee.managers && employee.managers.length > 0 && (
          <a className={classes.link} href={`/employees/${employee.id}/managers`}>
            {`${employee.managers[0].firstName} ${employee.managers[0].lastName}`}
            {employee.managers.length > 1 && `, ${employee.managers.length - 1} more`}
          </a>
        )}
      </TableCell>
      <TableCell className={classes.centeredCell}>
        {employee.amountOfSubordinates > 0 ? (
          <Button
            size="small"
            variant="outlined"
            color="black"
            onClick={() => handleSubordinatesButtonClick(employee.id)}
          >
            {employee.amountOfSubordinates}
          </Button>
        ) : (
          employee.amountOfSubordinates
        )}
      </TableCell>
      <TableCell className={classes.centeredCell}>
        {employee.resourceManager?.firstName} {employee.resourceManager?.lastName}
      </TableCell>
      <UserEditorTableCell>
        <ActionsMenu
          userId={employee.id}
          userIsArchived={employee.status === employeeStatuses.archived}
          resourceManager={employee.resourceManager}
        />
      </UserEditorTableCell>
    </TableRow>
  );

  return (
    <>
      <StyledPaper elevation={2}>
        <Table className={classes.table}>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <HeaderCell>Employee</HeaderCell>
              <HeaderCell className={classes.centeredCell}>Status</HeaderCell>
              <HeaderCell className={classes.centeredCell}>Email</HeaderCell>
              <HeaderCell className={classes.centeredCell}>Manager</HeaderCell>
              <HeaderCell className={classes.centeredCell}>Subordinates</HeaderCell>
              <HeaderCell className={classes.centeredCell}>Resource Manager</HeaderCell>
              <UserEditorTableCell />
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBodySpinner colSpan={7} />
          ) : (
            <TableBody>
              {employees && employees.length > 0 ? (
                employees.map(renderRow)
              ) : (
                <TableRow>
                  <TableCell className={classes.centeredCell} colSpan={6}>
                    <Typography fontSize="large" color="grey">
                      No records found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        <Pagination count={numberOfPages} page={pagination.pageNumber} onChange={onPageChange} />
      </StyledPaper>
    </>
  );
};

export default EmployeesTable;
