import React from 'react';
import { Field } from 'redux-form';
import InnerSelectField from './InnerSelectField';
import employeeStatusesForSelect from '../helper/employeeStatusesForSelect';

const EmployeeStatusSelectField = (props) => (
  <Field
    name={props.name}
    options={employeeStatusesForSelect.map((type) => {
      return { value: type.id, label: type.name };
    })}
    component={InnerSelectField}
    isClearable={true}
    isSearchable={false}
    placeholder="Status"
    {...props}
  />
);

export default EmployeeStatusSelectField;
