import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import SelectUserForm from './SelectUserForm';
import { setCurrentUser } from '../../../redux/modules/users/usersActions';

const selector = formValueSelector('selectUserForm');

const mapStateToProps = (state) => ({
  user: selector(state, 'user'),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'selectUserForm',
    onSubmit: (payload, dispatch) => {
      dispatch(setCurrentUser(payload.user.value));
    },
    onChange: (values, dispatch, props) => {
      props.submit();
    },
  })(SelectUserForm)
);
