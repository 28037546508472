import React from 'react';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import UpdateActivityFormContainer from './UpdateActivityFormContainer';

const PREFIX = 'UpdateActivityModal';

const classes = {
  input: `${PREFIX}-input`,
  select: `${PREFIX}-select`,
  paper: `${PREFIX}-paper`,
  flexContainer: `${PREFIX}-flexContainer`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    position: 'absolute',
    width: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
}));

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const UpdateActivityModal = ({ id, hideModal }) => (
  <StyledModal open={true}>
    <div style={getModalStyle()} className={classes.paper}>
      <UpdateActivityFormContainer id={id} onCancel={hideModal} />
    </div>
  </StyledModal>
);

export default UpdateActivityModal;
