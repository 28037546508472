import ACTIVITIES_CONSTANTS from './activitiesConstants';

const defaultState = {
  activities: [],
  userActivities: [],
  monthlyUserActivities: [],
  weeklyUserActivities: [],
  yearlyUserActivities: [],
  isLoading: true,
  totalItems: 0,
};

const activitiesReducers = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIVITIES_CONSTANTS.ADD_ACTIVITY:
      return handleAddActivity(state, action.payload);

    case ACTIVITIES_CONSTANTS.DELETE_ACTIVITY:
      return handleDeleteActivity(state, action.payload);

    case ACTIVITIES_CONSTANTS.UPDATE_ACTIVITY:
      return handleUpdateActivity(state, action.payload);

    case ACTIVITIES_CONSTANTS.GET_ACTIVITIES:
      return handleGetActivities(state, action.payload);

    case ACTIVITIES_CONSTANTS.ADD_USER_TO_ACTIVITY:
      return handleAddUserToActivity(state, action.payload);

    case ACTIVITIES_CONSTANTS.DELETE_USER_FROM_ACTIVITY:
      return handleDeleteUserFromActivity(state, action.payload);

    case ACTIVITIES_CONSTANTS.GET_MONTHLY_USER_ACTIVITIES:
      return handleGetMonthlyUserActivities(state, action.payload);

    case ACTIVITIES_CONSTANTS.GET_WEEKLY_USER_ACTIVITIES:
      return handleGetWeeklyUserActivities(state, action.payload);

    case ACTIVITIES_CONSTANTS.GET_YEARLY_USER_ACTIVITIES:
      return handleGetYearlyUserActivities(state, action.payload);

    case ACTIVITIES_CONSTANTS.GET_USER_ACTIVITIES:
      return handleGetUserActivities(state, action.payload);

    case ACTIVITIES_CONSTANTS.MONTHLY_USER_ACTIVITIES_PENDING:
      return { ...state, isLoading: action.payload };

    case ACTIVITIES_CONSTANTS.WEEKLY_USER_ACTIVITIES_PENDING:
      return { ...state, isLoading: action.payload };

    case ACTIVITIES_CONSTANTS.YEARLY_USER_ACTIVITIES_PENDING:
      return { ...state, isLoading: action.payload };

    case ACTIVITIES_CONSTANTS.ACTIVITIES_PENDING:
      return { ...state, isLoading: action.payload };

    case ACTIVITIES_CONSTANTS.CLEAR_ACTIVITIES:
      return handleClearActivities(state);

    case ACTIVITIES_CONSTANTS.CLEAR_USER_ACTIVITIES:
      return handleClearUserActivities(state);

    default:
      return state;
  }
};

function handleDeleteUserFromActivity(state, payload) {
  return {
    ...state,
    userActivities: state.userActivities.filter((activity) => activity.id !== payload.activityId),
  };
}

function handleAddUserToActivity(state, payload) {
  return {
    ...state,
    userActivities: [...state.userActivities, payload.activity],
    activities: state.activities.map((activity) => {
      if (activity.id === payload.activity.id) {
        const userIds = activity.userIds.concat(payload.userId);
        return { ...activity, userIds };
      }
      return activity;
    }),
  };
}

function handleGetActivities(state, { activities, ...payload }) {
  return {
    ...state,
    ...payload,
    activities: [...state.activities, ...activities],
    isLoading: false,
  };
}

function handleGetMonthlyUserActivities(state, monthlyUserActivities) {
  return {
    ...state,
    monthlyUserActivities,
    isLoading: false,
  };
}

function handleGetWeeklyUserActivities(state, weeklyUserActivities) {
  return {
    ...state,
    weeklyUserActivities,
    isLoading: false,
  };
}

function handleGetYearlyUserActivities(state, yearlyUserActivities) {
  return {
    ...state,
    yearlyUserActivities,
    isLoading: false,
  };
}

function handleUpdateActivity(state, payload) {
  return {
    ...state,
    activities: state.activities.map((activity) => {
      if (activity.id === payload.id) {
        return {
          ...activity,
          name: payload.name,
          activityType: payload.activityType,
          description: payload.description,
        };
      }
      return activity;
    }),
  };
}

function handleDeleteActivity(state, payload) {
  return {
    ...state,
    activities: state.activities.filter((activity) => activity.id !== payload.id),
  };
}

function handleAddActivity(state, payload) {
  return {
    ...state,
    activities: [
      {
        name: payload.name,
        id: payload.id,
        activityType: payload.type,
        userIds: [],
      },
      ...state.activities,
    ],
  };
}

function handleGetUserActivities(state, payload) {
  return {
    ...state,
    userActivities: payload,
  };
}

function handleClearActivities(state) {
  return {
    ...state,
    activities: [],
  };
}

function handleClearUserActivities(state) {
  return {
    ...state,
    userActivities: [],
  };
}

export default activitiesReducers;
