import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import InputField from '../../fields/InputField';
import ActivityTypesSelectField from '../../fields/ActivityTypesSelectField';
import { TextareaAutosize } from '@mui/material';
import withRoles from '../../../hocs/withRoles';
import roleConstants from '../../helper/roleConstants';

const PREFIX = 'UpdateActivityForm';

const classes = {
  input: `${PREFIX}-input`,
  select: `${PREFIX}-select`,
  flexContainer: `${PREFIX}-flexContainer`,
  textArea: `${PREFIX}-textArea`,
};

const Root = styled('form')(({ theme }) => ({
  [`& .${classes.input}`]: {
    border: 'none',
    width: '45%',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '-10px 5% 5px 0px',
    fontFamily: theme.typography.fontFamily,
  },
  [`& .${classes.select}`]: {
    border: 'none',
    width: '45%',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '5px 5% 5px 0px',
    fontFamily: theme.typography.fontFamily,
  },
  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5% 0px',
    gap: '5%',
  },
  [`& .${classes.textArea}`]: {
    width: '95%',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '0.875rem',
}));

const SuperManagerInputField = withRoles([roleConstants.superManager])(InputField);

const UpdateActivityForm = ({ handleSubmit, onCancel }) => (
  <Root onSubmit={handleSubmit}>
    <div className={classes.flexContainer}>
      <InputField className={classes.input} name="name" label="Enter activity name..." />
      <ActivityTypesSelectField className={classes.select} name="activityType" />
    </div>
    <SuperManagerInputField
      className={classes.textArea}
      slots={{ textarea: TextareaAutosize }}
      variant="outlined"
      multiline
      minRows={2}
      maxRows={10}
      label="Description"
      name="description"
    />
    <div className={classes.flexContainer}>
      <StyledButton color="cancelButton" variant="contained" onClick={onCancel}>
        Cancel
      </StyledButton>
      <StyledButton type="submit" color="secondary" variant="contained">
        Save
      </StyledButton>
    </div>
  </Root>
);

export default UpdateActivityForm;
