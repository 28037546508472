export default Object.freeze({
  GET_USERS: 'GET_USERS',
  GET_ALL_USERS: 'GET_ALL_USERS',
  GET_USER_BY_ID: 'GET_USER_BY_ID',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  CLEAR_CURRENT_USER: 'CLEAR_CURRENT_USER',
  CLEAR_USERS: 'CLEAR_USERS',
  CLEAR_ALL_USERS: 'CLEAR_ALL_USERS',
  CLEAR_USER_INFO: 'CLEAR_USER_INFO',
  USERS_PENDING: 'USERS_PENDING',
});
