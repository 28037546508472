import { reduxForm, getFormValues } from 'redux-form';
import { getWeeklyPdfReport } from '../../../../redux/modules/report/reportActions';
import PdfReportForm from '../PdfReportForm/PdfReportForm';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  formValues: getFormValues('PdfReportForm')(state),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'PdfReportForm',
    onSubmit: (payload, dispatch, props) => {
      const activitiesIds = props.userActivities
        .filter(act => payload[`activity-${act.activityId}`])
        .map(act => act.activityId);
      dispatch(getWeeklyPdfReport(props.userId, props.firstDayOfWeek, activitiesIds));
    },
  })(PdfReportForm)
);
