import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import { styled } from '@mui/material/styles';
import EmployeesTable from './EmployeeTable/EmployeesTable';
import PageTitle from '../../common/PageTitle/PageTitle';
import {
  clearEmployees,
  getManagersByUserId,
  getResourceManagers,
} from '../../../redux/modules/employees/employeesActions';
import HeaderBarContainer from '../../common/HeaderBarWrapper/HeaderBar/HeaderBarContainer';
import { getUserById } from '../../../redux/modules/users/usersActions';
import { selectUserInfo } from '../../../redux/modules/users/usersSelectors';
import FiltersFormContainer from './Filters/FiltersFormContainer';
import paginationConstants from '../../helper/paginationConstants';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect';
import {
  selectEmployees,
  selectEmployeesIsLoading,
  selectEmployeesTotalItems,
} from '../../../redux/modules/employees/employeesSelectors';

const PREFIX = 'ManagersPage';

const classes = {
  contentWrapper: `${PREFIX}-contentWrapper`,
  filtersContainer: `${PREFIX}-filtersContainer`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
  height: '100%',

  [`& .${classes.contentWrapper}`]: {
    display: 'flex',
    height: 'calc(100vh - 58px)',
    flexDirection: 'column',
    backgroundColor: theme.palette.whitegrey,
    padding: '30px 50px',
    overflow: 'auto',
  },
  [`& .${classes.filtersContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.white,
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
    padding: '5px 10px',
  },
}));

const ManagersPage = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const userId = params.id;
  const userInfo = useSelector(selectUserInfo);

  const employees = useSelector(selectEmployees);
  const totalEmployees = useSelector(selectEmployeesTotalItems);
  const isLoading = useSelector(selectEmployeesIsLoading);

  const pageTitle = userInfo ? `Managers of ${userInfo.name}` : '';

  const [filters, setFilters] = useState({
    status: null,
    name: '',
    resourceManager: null,
  });

  const itemsPerPage = paginationConstants.itemsPerPage;
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    totalItems: totalEmployees,
    itemsPerPage: itemsPerPage,
  });

  useEffect(() => {
    setPagination((prev) => ({ ...prev, totalItems: totalEmployees }));
  }, [totalEmployees]);

  useEffect(() => {
    dispatch(getUserById(userId));
  }, [userId]);

  useDebouncedEffect(
    () => {
      dispatch(
        getManagersByUserId(
          userId,
          pagination.pageNumber,
          itemsPerPage,
          filters.name,
          filters.status,
          filters.resourceManager
        )
      );
      dispatch(getResourceManagers());
    },
    filters.name ? 1000 : 0,
    [userId, filters.name, filters.status, filters.resourceManager, itemsPerPage, pagination.pageNumber]
  );

  const handleFiltersChange = useCallback((filters) => {
    dispatch(clearEmployees());
    setFilters(filters);
    setPagination((prev) => ({ ...prev, pageNumber: 1 }));
  }, []);

  const handlePageChange = useCallback((event, page) => {
    event.preventDefault();
    setPagination((prev) => ({ ...prev, pageNumber: page }));
  }, []);

  return (
    <>
      <PageTitle pageTitle={pageTitle} />
      <Root>
        <HeaderBarContainer pageTitle={pageTitle} withBackButton />
        <div className={classes.contentWrapper}>
          <div className={classes.filtersContainer}>
            <FiltersFormContainer onFiltersChange={handleFiltersChange} />
          </div>
          <EmployeesTable
            isLoading={isLoading}
            employees={employees}
            pagination={pagination}
            onPageChange={handlePageChange}
          />
        </div>
      </Root>
      <NotificationContainer />
    </>
  );
};

export default ManagersPage;
