import { connect } from 'react-redux';
import MonthlyReport from './MonthlyReport';
import { getFirstDayOfMonth } from '../../../helper/getDateFromQuery';
import { getMonthlyReport, clearMonthlyReport } from '../../../../redux/modules/report/reportActions';

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.report.isLoading,
    firstDayOfMonth: getFirstDayOfMonth(ownProps.location.search),
  };
};

const mapDispatchToProps = {
  getMonthlyReport,
  clearMonthlyReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyReport);
