import { connect } from 'react-redux';
import UserActivities from './UserActivities';
import * as usersAction from '../../../redux/modules/users/usersActions';
import * as activitiesAction from '../../../redux/modules/activities/activitiesActions';
import { getUnassignedActivities } from '../../../redux/modules/activities/activitiesSelectors';

const mapStateToProps = (state) => ({
  userActivities: state.activities.userActivities,
  currentUser: state.users.currentUserId,
  users: state.users.users.items,
  totalUsers: state.users.users.totalItems,
  activities: state.activities.activities,
  unassignedActivities: getUnassignedActivities(state),
  totalActivities: state.activities.totalItems,
});

const mapDispatchToProps = {
  getUsers: usersAction.getUsers,
  getUserActivities: activitiesAction.getUserActivities,
  getActivities: activitiesAction.getActivities,

  clearUsers: usersAction.clearUsers,
  clearActivities: activitiesAction.clearActivities,
  clearUserActivities: activitiesAction.clearUserActivities,
  clearCurrentUser: usersAction.clearCurrentUser,

  deleteUserFromActivity: activitiesAction.deleteUserFromActivity,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserActivities);
