import { ACTION_TYPES } from './modalsConstants';

const defaultState = {
  modalType: null,
  modalProps: {},
};

const modalsReducers = (state = defaultState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SHOW_MODAL:
      return {
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
      };
    case ACTION_TYPES.HIDE_MODAL: {
      return defaultState;
    }
    default:
      return state;
  }
};

export default modalsReducers;
