import * as sickListsRequests from '../../../axios/sickLists';
import { parseErrors } from '../../../components/helper/requestsHelpers';
import RECORDS_CONSTANTS from '../records/recordsConstants';

export const addSickList = (userId, dateRange) => async (dispatch) => {
  try {
    const response = await sickListsRequests.addSickList(userId, dateRange);
    dispatch({
      type: RECORDS_CONSTANTS.ADD_UPDATE_SICK_LIST,
      payload: { dateRange },
    });

    return { status: response.status };
  } catch (e) {
    return await parseErrors(e);
  }
};

export const addSickListToEmployee = (userId, dateRange) => async (dispatch) => {
  try {
    const response = await sickListsRequests.addSickList(userId, dateRange);
    dispatch({
      type: RECORDS_CONSTANTS.ADD_UPDATE_SICK_LIST,
      payload: { dateRange },
    });

    return { status: response.status };
  } catch (e) {
    return await parseErrors(e);
  }
};

export const updateSickList = (sickListId, dateRange) => async (dispatch) => {
  try {
    const response = await sickListsRequests.updateSickList(sickListId, dateRange);
    dispatch({
      type: RECORDS_CONSTANTS.ADD_UPDATE_SICK_LIST,
      payload: { dateRange },
    });

    return { status: response.status };
  } catch (e) {
    return await parseErrors(e);
  }
};

export const removeSickList = (sickListId) => (dispatch) => sickListsRequests.deleteSickList(sickListId);
