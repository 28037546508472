import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import AddUserToActivityForm from './AddUserToActivityForm';
import { addUserToActivity } from '../../../redux/modules/activities/activitiesActions';
import { getActivities } from '../../../redux/modules/activities/activitiesSelectors';

const validate = (values) => {
  const errors = {};
  if (!values.activity) {
    errors.activity = 'Required';
  }
  return errors;
};

const selector = formValueSelector('addUserToActivityForm');

const mapStateToProps = (state) => ({
  activity: selector(state, 'activity'),
  activities: getActivities(state),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'addUserToActivityForm',
    validate,
    onSubmit: (payload, dispatch, props) => {
      dispatch(addUserToActivity(payload.activity)).then(props.reset('addUserToActivityForm'));
    },
  })(AddUserToActivityForm)
);
