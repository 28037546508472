import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { addSickList, addSickListToEmployee, updateSickList } from '../../../redux/modules/sickLists/sickListsActions';
import { getCurrentUser } from '../../../redux/modules/users/usersSelectors';
import AddUpdateSickListForm from './AddUpdateSickListForm';
import { SickListModalActions } from './SickListModalActions';
import Notification from '../../common/Notification/Notification';

const validate = values => {
  const errors = {};
  if (!values.dateRange) {
    errors.dateRange = 'Required';
  }
  return errors;
};

const selector = formValueSelector('addUpdateSickListForm');

const mapStateToProps = state => ({
  initialValues: state.modals.modalProps,
  dateRange: selector(state, 'dateRange'),
  userId: selector(state, 'userId'),
  currentUser: getCurrentUser(state),
});

const handleSubmit = (payload, dispatch, props) => {
  switch (props.action) {
    case SickListModalActions.ADD:
      if (props.userId === props.currentUser.id) {
        return dispatch(addSickList(props.userId, payload.dateRange));
      } else {
        return dispatch(addSickListToEmployee(props.userId, payload.dateRange));
      }
    case SickListModalActions.UPDATE:
      return dispatch(updateSickList(payload.sickListId, payload.dateRange));
    default:
      throw new Error('Action not found');
  }
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'addUpdateSickListForm',
    validate,
    onSubmit: (payload, dispatch, props) => {
      handleSubmit(payload, dispatch, props).then(({ status, text }) => {
        if (status === 500 || status === 404 || status === 400) {
          Notification('warning', text)();
        } else {
          Notification('success')();
          props.onSave();
        }
      });
    },
  })(AddUpdateSickListForm)
);
