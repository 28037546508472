import React, { useState, useCallback, useRef } from 'react';
import moment from 'moment';
import 'moment/locale/en-gb';

import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import enUS from 'rc-calendar/lib/locale/en_US';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const PREFIX = 'MonthPicker';

const classes = {
  container: `${PREFIX}-container`,
  monthPickerInput: `${PREFIX}-monthPickerInput`,
  monthCalendar: `${PREFIX}-monthCalendar`,
  selectedMonth: `${PREFIX}-selectedMonth`,
  selectMonthArrows: `${PREFIX}-selectMonthArrows`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  alignItems: 'center',

  [`& .${classes.monthPickerInput}`]: {
    border: 'none',
    textAlign: 'center',
    paddingTop: '4px',
    width: '130px',
    cursor: 'pointer',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.white,
  },

  [`& .${classes.monthCalendar}`]: {
    width: '250px',
  },

  [`& .${classes.selectedMonth}`]: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '13px',
    margin: '0 10px',
  },

  [`& .${classes.selectMonthArrows}`]: {
    cursor: 'pointer',
    color: theme.palette.white,
  },
}));

const format = 'MMM YYYY';

const MonthPicker = ({ firstDayOfMonth, monthChanged }) => {
  const [isOpened, setIsOpened] = useState(false);
  const calendarParentRef = useRef(null);

  const onOpenChange = (value) => {
    setIsOpened(value);
  };

  const setSelectedMonth = (value) => {
    setIsOpened(false);
    monthChanged(value.startOf('month'));
  };

  const handleClickPreviousMonth = useCallback(() => {
    const previousMonth = moment(firstDayOfMonth).startOf('month').subtract(1, 'months');

    monthChanged(previousMonth);
  }, [firstDayOfMonth]);

  const handleClickNextMonth = useCallback(() => {
    const nextMonth = moment(firstDayOfMonth).startOf('month').add(1, 'months');

    monthChanged(nextMonth);
  }, [firstDayOfMonth]);

  const renderMonth = (value) => {
    if (value) {
      return `${value.startOf('month').format(format)}`;
    }
  };

  const renderCalendar = () => {
    return (
      <Calendar
        className={classes.monthCalendar}
        locale={enUS}
        mode="month"
        format={format}
        dateInputPlaceholder="MM yyyy"
        showDateInput={false}
        onChange={setSelectedMonth}
      />
    );
  };

  const renderInputMonthPicker = (value) => {
    return (
      <Grid item>
        <input
          placeholder="MM yyyy"
          readOnly
          className={classes.monthPickerInput}
          value={value && (renderMonth(value.value) || '')}
        />
      </Grid>
    );
  };

  const calendar = renderCalendar();

  return (
    <StyledGrid container direction="row" spacing={0}>
      <Grid item>
        <Typography type="subtitle1">Month:</Typography>
      </Grid>

      <>
        <Grid item className={classes.selectedMonth} ref={calendarParentRef}>
          <KeyboardArrowLeft className={classes.selectMonthArrows} onClick={handleClickPreviousMonth} />
          <DatePicker
            onOpenChange={onOpenChange}
            open={isOpened}
            calendar={calendar}
            getCalendarContainer={() => calendarParentRef.current}
            value={firstDayOfMonth}
          >
            {(value) => renderInputMonthPicker(value)}
          </DatePicker>
          <KeyboardArrowRight className={classes.selectMonthArrows} onClick={handleClickNextMonth} />
        </Grid>
      </>
    </StyledGrid>
  );
};

export default MonthPicker;
