import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import FilterForm from './FilterForm';

const selector = formValueSelector('filterVacationForm');

const mapStateToProps = (state) => ({
  vacationType: selector(state, 'vacationType'),
  dateRange: selector(state, 'dateRange'),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'filterVacationForm',
    initialValues: {
      dateRange: [],
    },
    onChange: (payload, dispatch, props) => {
      const filters = {
        vacationType: payload.vacationType ? payload.vacationType.value : null,
        dateRange: payload.dateRange && payload.dateRange.length ? payload.dateRange : null,
      };
      props.onFiltersChange(filters);
    },
  })(FilterForm)
);
