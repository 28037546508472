import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CheckboxField from '../../../fields/CheckboxField';
import ListItemText from '@mui/material/ListItemText';

const PREFIX = 'PdfReportForm';

const classes = {
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
  listItemText: `${PREFIX}-listItemText`,
  title: `${PREFIX}-title`,
  formField: `${PREFIX}-formField`,
  flexContainer: `${PREFIX}-flexContainer`,
  button: `${PREFIX}-button`,
};

const Root = styled('form')(({ theme }) => ({
  [`& .${classes.list}`]: {
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
    height: '370px',
    minHeight: '150px',
    overflowY: 'auto',
    backgroundColor: theme.palette.white,
    margin: '10px 0',
    padding: '10px 10px 0 0',
  },

  [`& .${classes.listItem}`]: {
    height: '36px',
  },

  [`& .${classes.listItemText}`]: {
    fontSize: '18px',
    margin: '5px 0',
    color: `${theme.palette.secondary.main} !important`,
  },

  [`& .${classes.title}`]: {
    fontSize: '20px',
    textAlign: 'center',
    margin: '10px',
  },

  [`& .${classes.formField}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },

  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5% 0px',
  },

  [`& .${classes.button}`]: {
    margin: '5px 0px 0 10px',
    padding: '4px 16px',
    fontSize: '18px',
    minWidth: 180,
  },
}));

const PdfReportForm = (props) => {
  const { onCancel, userActivities, formValues, handleSubmit } = props;

  const IsActivitySelected = (formValues) => {
    if (formValues) {
      const values = Object.values(formValues);
      return values.some((value) => value === true);
    }
    return false;
  };

  const generateReportButtonIsDisabled = !userActivities.length || !IsActivitySelected(formValues);

  return (
    <Root onSubmit={handleSubmit}>
      {userActivities.length ? (
        <List className={classes.list} component="nav">
          {userActivities.map((act) => {
            return (
              <ListItem key={act.activityId} className={classes.listItem} dense button>
                <ListItemText key={act.activityId}>
                  <CheckboxField
                    label={<Typography className={classes.listItemText}>{act.activityName}</Typography>}
                    name={`activity-${act.activityId}`}
                    className={classes.formField}
                  />
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Typography className={classes.title}>The user doesn't have any activities for the selected period</Typography>
      )}
      <div className={classes.flexContainer}>
        <Button color="cancelButton" onClick={onCancel} variant="contained" className={classes.button}>
          Cancel
        </Button>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          className={classes.button}
          disabled={generateReportButtonIsDisabled}
        >
          Generate report
        </Button>
      </div>
    </Root>
  );
};

export default PdfReportForm;
