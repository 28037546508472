import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import EditableRow from '../../../common/EditableRow/EditableRow';
import StaticRow from '../../../common/StaticRow/StaticRow';

const HoursRow = (props) => {
  const {
    isUnderlined,
    isTotalsHighlighted,
    isReadonly,
    isSickLeave,
    deleteTimeTrackRecord,
    updateTimeTrackRecord,
    updateEmptyTimeTrackRecord,
    addTimeTrackRecord,
    taskId,
    firstDayOfWeek,
    totalHoursPerDay,
    totalHoursPerWeek,
    display,
  } = props;

  const handleChangeInput = (record, value, cellIndex) => {
    const normalizedValue = Math.ceil(value / 0.25) * 0.25;
    if (record.id) {
      updateTimeTrackRecord(normalizedValue, record.id, taskId);
    } else {
      const currentDate = moment(firstDayOfWeek).weekday(cellIndex);
      if (record.id === 0) {
        updateEmptyTimeTrackRecord(normalizedValue, moment(currentDate), taskId);
      } else {
        addTimeTrackRecord(normalizedValue, moment(currentDate), taskId);
      }
    }
  };

  const handleBlur = (record) => {
    if (record.hours === 0) {
      deleteTimeTrackRecord(record.id, taskId);
    }
  };

  return (
    <>
      <EditableRow
        inputItems={totalHoursPerDay}
        handleChangeInput={handleChangeInput}
        display={display}
        rowIndex={taskId}
        isUnderlined={isUnderlined}
        isReadonly={isReadonly}
        isSickLeave={isSickLeave}
        handleBlur={handleBlur}
      />
      <TableCell style={isUnderlined ? { width: '9%' } : { border: 'none', width: '9%' }}>
        <StaticRow isBold={isTotalsHighlighted} hours={totalHoursPerWeek} />
      </TableCell>
    </>
  );
};

HoursRow.propTypes = {
  totalHoursPerDay: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalHoursPerWeek: PropTypes.number.isRequired,
  display: PropTypes.string.isRequired,
  activityType: PropTypes.number,
  indexTimeTrackInfo: PropTypes.number,
};

export default React.memo(HoursRow);
