import 'moment/locale/en-gb';
import { styled } from '@mui/material/styles';
import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const PREFIX = 'PagesSwitcher';

const classes = {
  text: `${PREFIX}-text`,
  switchButton: `${PREFIX}-switchButton`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '10px',

  [`& .${classes.text}`]: {
    minWidth: '3px',
    paddingTop: '2px',
    textAlign: 'center',
    color: theme.palette.white,
  },

  [`& .${classes.switchButton}`]: {
    display: 'flex',
    borderRadius: '13px',
    padding: '2px, 10px, 2px, 10px',
  },
}));

const PagesSwitcher = (props) => {
  const handleRedirectToFirstPage = () => handlePageChange(pages[0].title, pages[0].url);
  const handleRedirectToSecondPage = () => handlePageChange(pages[1].title, pages[1].url);
  const { handlePageChange, selectedPage, pages } = props;
  const firstPageIsSelected = selectedPage === pages[0].title;

  return (
    <Root>
      <Button
        disabled={firstPageIsSelected}
        variant="contained"
        color="secondary"
        type="submit"
        className={classes.switchButton}
        onClick={handleRedirectToFirstPage}
      >
        <Typography className={classes.text}>{pages[0].description}</Typography>
      </Button>
      <Button
        disabled={!firstPageIsSelected}
        variant="contained"
        color="secondary"
        type="submit"
        className={classes.switchButton}
        onClick={handleRedirectToSecondPage}
      >
        <Typography className={classes.text}>{pages[1].description}</Typography>
      </Button>
    </Root>
  );
};

export default PagesSwitcher;
