import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import YearlyRecordsRow from './YearlyRecordsRow';
import withDragScrolling from '../../../../../hocs/withDragScrolling';

const PREFIX = 'YearlyRecordsColumn';

const classes = {
  recordsColumn: `${PREFIX}-recordsColumn`,
  bodyContainer: `${PREFIX}-bodyContainer`,
  headerContainer: `${PREFIX}-headerContainer`,
  headerDateCell: `${PREFIX}-headerDateCell`,
  scrollBar: `${PREFIX}-scrollBar`,
  thumb: `${PREFIX}-thumb`,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${classes.recordsColumn}`]: {
    flex: '2 1 70%',
    padding: '4px 0px',
    overflow: 'auto',
    border: 'none',
  },

  [`& .${classes.bodyContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.headerContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
    width: '100%',
  },

  [`& .${classes.headerDateCell}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '240px',
    height: '60px',
    backgroundColor: theme.palette.grey.light,
    color: theme.palette.grey.dark,
  },

  [`& .${classes.scrollBar}`]: {
    width: 'auto',
    height: 'auto',
  },

  [`& .${classes.thumb}`]: {
    cursor: 'pointer',
    borderRadius: 'inherit',
    backgroundColor: theme.palette.grey.main,
  },
}));

const YearlyRecordsColumn = (props) => {
  const { reports, onMouseDown, forwardedRef } = props;
  return (
    <StyledTableCell className={classes.recordsColumn} onMouseDown={onMouseDown}>
      <Scrollbars
        className={classes.scrollBar}
        ref={forwardedRef}
        renderThumbHorizontal={(props) => <div {...props} className={classes.thumb} />}
        renderThumbVertical={(props) => <div {...props} className={classes.thumb} />}
      >
        <div className={classes.headerContainer}>
          <div>
            <Typography className={classes.headerDateCell}>Project Work</Typography>
          </div>
          <div>
            <Typography className={classes.headerDateCell}>Non-project Work</Typography>
          </div>
          <div>
            <Typography className={classes.headerDateCell}>Vacation</Typography>
          </div>
          <div>
            <Typography className={classes.headerDateCell}>Sick Leave</Typography>
          </div>
          <div>
            <Typography className={classes.headerDateCell}>Unpaid Leave</Typography>
          </div>
        </div>
        <div className={classes.bodyContainer}>
          {reports.map((report) => (
            <YearlyRecordsRow key={report.userId} records={report.records} />
          ))}
        </div>
      </Scrollbars>
    </StyledTableCell>
  );
};

YearlyRecordsColumn.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withDragScrolling(YearlyRecordsColumn);
