import React, { useCallback, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import DaysOfWeek from './DaysOfWeek/DaysOfWeek';
import ActivityType from './ActivityType/ActivityType';
import OutOfOfficeActivityType from './ActivityType/OutOfOfficeActivityType';
import HoursRowContainer, { ROW_TYPE } from './HoursRow/HoursRowContainer';
import withRoles from '../../../hocs/withRoles';
import roleConstants from '../../helper/roleConstants';
import { getFirstDayOfMonth } from '../../helper/momentJsExtensions';
import WeekPicker from '../../common/DatePickers/WeekPicker';
import TableRowSpinner from '../../common/TableSpinners/TableRowSpinner';
import { productionCalendarCountrySelector } from '../../../redux/modules/productionCalendar/productionCalendarSelectors';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../redux/modules/users/usersSelectors';

const PREFIX = 'UserActivitiesTable';

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  button: `${PREFIX}-button`,
  exportButton: `${PREFIX}-exportButton`,
  buttonWrapper: `${PREFIX}-buttonWrapper`,
  daysOfWeekRow: `${PREFIX}-daysOfWeekRow`,
  totalRow: `${PREFIX}-totalRow`,
  activityRow: `${PREFIX}-activityRow`,
  loadingCell: `${PREFIX}-loadingCell`,
  loadingWrapper: `${PREFIX}-loadingWrapper`,
};

const Root = styled('form')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '95%',
    marginTop: '3vh',
    overflowX: 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
    maxHeight: '78vh',
    backgroundColor: theme.palette.white,
  },

  [`& .${classes.table}`]: {
    minWidth: 700,
  },

  [`& .${classes.button}`]: {
    margin: '30px 0 20px 0',
    fontSize: '18px',
    minWidth: 180,
    padding: '4px 16px',
  },

  [`& .${classes.exportButton}`]: {
    marginLeft: '20px',
  },

  [`& .${classes.buttonWrapper}`]: {
    width: '95%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },

  [`& .${classes.daysOfWeekRow}`]: {
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
  },

  [`& .${classes.totalRow}`]: {
    backgroundColor: theme.palette.lightblue,
  },

  [`& .${classes.activityRow}`]: {
    backgroundColor: theme.palette.whiteblue,
    border: 'none',
    width: '15%',
  },

  [`& .${classes.loadingCell}`]: {
    border: 'none',
    paddingTop: '50px',
    paddingBottom: '50px',
    backgroundColor: theme.palette.white,
  },

  [`& .${classes.loadingWrapper}`]: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
  },
}));

const ManagerButton = withRoles([roleConstants.superManager, roleConstants.manager])(Button);

const UserActivitiesTable = (props) => {
  const {
    totals,
    projectActivities,
    nonProjectActivities,
    outOfOfficeActivities,
    isLoading,
    handleSubmit,
    params,
    firstDayOfWeekFromUrl,
    showPdfReportModal,
    showExcelReportModal,
    currentUser,
    setFirstDayOfWeek,
    dataWasChanged,
    getTimeTrackWeek,
    showExportWeekChangeHistoryModal,
  } = props;
  const selectedUserId = params.id || currentUser.id;
  const navigate = useNavigate();
  const productionCalendarCountry = useSelector(productionCalendarCountrySelector);
  const userInfo = useSelector(selectUserInfo);

  const handleWeekChange = (date) => {
    if (!dataWasChanged || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      navigate({
        search: `firstDayOfWeek=${date.format('YYYY-MM-DD')}`,
      });
    }
  };

  const handleKeyDown = useCallback(
    (e) => {
      if (e.srcElement.nodeName !== 'TEXTAREA' && e.srcElement.nodeName !== 'INPUT' && e.keyCode === 37) {
        const previousWeek = moment(firstDayOfWeekFromUrl).startOf('week').subtract(1, 'weeks');
        handleWeekChange(previousWeek);
      }
      if (e.srcElement.nodeName !== 'TEXTAREA' && e.srcElement.nodeName !== 'INPUT' && e.keyCode === 39) {
        const nextWeek = moment(firstDayOfWeekFromUrl).startOf('week').add(1, 'weeks');
        handleWeekChange(nextWeek);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firstDayOfWeekFromUrl.format()]
  );

  useEffect(() => {
    if (!dataWasChanged) return;
    window.onbeforeunload = (e) => {
      const message = 'Are you sure you want leave?';
      e.returnValue = message;
      return message;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [dataWasChanged]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    setFirstDayOfWeek(firstDayOfWeekFromUrl);
    getTimeTrackWeek(firstDayOfWeekFromUrl);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDayOfWeekFromUrl.format(), handleKeyDown, productionCalendarCountry]);

  const renderForm = () => (
    <>
      {totals && (
        <TableRow>
          <TableCell style={{ borderBottom: 'none', width: '15%' }}>
            <Typography type="subtitle1">Total Time</Typography>
          </TableCell>
          <HoursRowContainer
            display={ROW_TYPE.TYPOGRAPHY}
            totalHoursPerDay={totals.totalHoursPerDay}
            totalHoursPerWeek={totals.totalHoursPerWeek}
            isUnderlined={false}
            isTotalsHighlighted={true}
            firstDayOfWeek={firstDayOfWeekFromUrl}
          />
        </TableRow>
      )}

      {projectActivities.items.length !== 0 && (
        <ActivityType
          label="Project activities"
          activities={projectActivities.items}
          totals={projectActivities.totals}
          classes={classes}
        />
      )}
      {nonProjectActivities.items.length !== 0 && (
        <ActivityType
          label="Non-project activities"
          activities={nonProjectActivities.items}
          totals={nonProjectActivities.totals}
          classes={classes}
        />
      )}
      {outOfOfficeActivities.items.length !== 0 && (
        <OutOfOfficeActivityType
          label="Out of office activities"
          activities={outOfOfficeActivities.items}
          totals={outOfOfficeActivities.totals}
          classes={classes}
          userId={selectedUserId}
        />
      )}
    </>
  );

  const renderPageContent = () => {
    if (isLoading) {
      return <TableRowSpinner colSpan={9} />;
    } else {
      return renderForm();
    }
  };

  return (
    <>
      <Root onSubmit={handleSubmit} noValidate>
        <div className={classes.root}>
          <Table className={classes.table} size="small">
            <TableHead className={classes.daysOfWeekRow}>
              <TableRow>
                <TableCell style={{ borderBottom: 'none', padding: '4px 0 4px 24px ', width: '28%' }}>
                  <WeekPicker firstDayOfWeek={firstDayOfWeekFromUrl} onWeekChanged={handleWeekChange} />
                </TableCell>
                <DaysOfWeek firstDayOfWeek={firstDayOfWeekFromUrl} />
              </TableRow>
            </TableHead>
            <TableBody>{renderPageContent()}</TableBody>
          </Table>
        </div>
        <div className={classes.buttonWrapper}>
          <Button variant="contained" color="secondary" type="submit" className={classes.button}>
            SAVE
          </Button>
          <ManagerButton
            onClick={() =>
              showPdfReportModal(
                getFirstDayOfMonth(firstDayOfWeekFromUrl),
                moment(firstDayOfWeekFromUrl),
                selectedUserId
              )
            }
            variant="contained"
            color="primary"
            className={`${classes.button} ${classes.exportButton}`}
          >
            Export to PDF
          </ManagerButton>
          <ManagerButton
            onClick={() =>
              showExcelReportModal(getFirstDayOfMonth(firstDayOfWeekFromUrl), moment().year(), selectedUserId)
            }
            variant="contained"
            color="primary"
            className={`${classes.button} ${classes.exportButton}`}
          >
            Export to Excel
          </ManagerButton>
          <ManagerButton
            onClick={() =>
              showExportWeekChangeHistoryModal(
                selectedUserId,
                params.id ? userInfo.name : `${currentUser.firstName} ${currentUser.lastName}`
              )
            }
            variant="contained"
            color="primary"
            className={`${classes.button} ${classes.exportButton}`}
          >
            Export Change History
          </ManagerButton>
        </div>
      </Root>
      <NotificationContainer />
    </>
  );
};

export default UserActivitiesTable;
