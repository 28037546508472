import axios from 'axios';
import { withAPIFormat } from '../components/helper/momentJsExtensions';

export const getEmployees = async (pageNumber, pageSize, search, status, resourceManagerId) => {
  return await axios.get('/api/employee', {
    params: {
      pageNumber,
      pageSize,
      search,
      status,
      resourceManagerId,
    },
  });
};

export const getSubordinatesByUserId = async (userId, pageNumber, pageSize, search, status, resourceManagerId) => {
  return await axios.get(`/api/employee/${userId}/subordinates`, {
    params: {
      pageNumber,
      pageSize,
      search,
      status,
      resourceManagerId,
    },
  });
};

export const getManagersByUserId = async (userId, pageNumber, pageSize, search, status, resourceManagerId) => {
  return await axios.get(`/api/employee/${userId}/managers`, {
    params: {
      pageNumber,
      pageSize,
      search,
      status,
      resourceManagerId,
    },
  });
};

export const getMyManagers = async () => {
  return await axios.get(`/api/employee/my-managers`);
};

export const addSubordinateToUser = async (managerId, subordinateId) => {
  return await axios.post(`/api/employee/${managerId}/subordinates`, {
    subordinateId,
  });
};

export const removeSubordinateFromUser = async (managerId, subordinateId) => {
  return await axios.delete(`/api/employee/${managerId}/subordinates`, {
    data: { subordinateId },
  });
};

export const getResourceManagers = async () => {
  return await axios.get('/api/employee/resourcemanagers');
};

export const updateUserResourceManager = async (userId, resourceManagerId) => {
  return await axios.put(`/api/employee/${userId}/resourcemanager`, {
    resourceManagerId,
  });
};

export const exportEmployeeTimeTrackRecordChangeHistory = async (userId, startDate, endDate) => {
  return await axios({
    url: `/api/employee/${userId}/week/history/excel`,
    data: {
      startDate: withAPIFormat(startDate),
      endDate: withAPIFormat(endDate),
    },
    method: 'POST',
    responseType: 'blob',
  });
};
