import { connect } from 'react-redux';
import * as modalsAction from '../../../redux/modules/modals/modalsActions';
import ExcelReportModal from './ExcelReportModal';

const mapStateToProps = (state) => ({
  userId: state.modals.modalProps.userId,
  firstDayOfMonth: state.modals.modalProps.firstDayOfMonth,
  year: state.modals.modalProps.year,
  userFullName: state.modals.modalProps.userFullName,
});

const mapDispatchToProps = {
  hideModal: modalsAction.hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExcelReportModal);
