import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment/moment';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import RecordsColumnContainer from './RecordsColumn/RecordsColumnContainer';
import TotalsColumnContainer from './TotalsColumn/TotalsColumnContainer';
import SearchUserFormContainer from '../SearchUserForm/SearchUserFormContainer';
import MonthlyUsersColumnContainer from '../UsersColumn/MonthlyUsersColumnContainer';
import ActivityTypesLegendBar from '../ActivityTypesLegendBar/ActivityTypesLegendBar';
import MonthPicker from '../../../common/DatePickers/MonthPicker';
import TableRowSpinner from '../../../common/TableSpinners/TableRowSpinner';
import paginationConstants from '../../../helper/paginationConstants';

const PREFIX = 'MonthlyReport';

const classes = {
  table: `${PREFIX}-table`,
  borderlessCell: `${PREFIX}-borderlessCell`,
  flexContainer: `${PREFIX}-flexContainer`,
  container: `${PREFIX}-container`,
  tableBody: `${PREFIX}-tableBody`,
  tableHeader: `${PREFIX}-tableHeader`,
  loadingTable: `${PREFIX}-loadingTable`,
  loadingCell: `${PREFIX}-loadingCell`,
  loadingWrapper: `${PREFIX}-loadingWrapper`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.whitegrey,
  boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',

  [`& .${classes.table}`]: {
    minWidth: 700,
  },

  [`& .${classes.borderlessCell}`]: {
    border: 'none',
  },

  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    height: 'auto',
  },

  [`& .${classes.container}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  [`& .${classes.tableBody}`]: {
    minHeight: '30vh',
    overflowY: 'auto',
  },

  [`& .${classes.tableHeader}`]: {
    backgroundColor: theme.palette.white,
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
  },

  [`& .${classes.loadingTable}`]: {
    height: '30vh',
  },

  [`& .${classes.loadingCell}`]: {
    border: 'none',
    paddingTop: '160px',
  },

  [`& .${classes.loadingWrapper}`]: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingTop: '160px',
    height: '60vh',
    maxHeight: '82vh',
  },
}));

const MonthlyReport = (props) => {
  const { firstDayOfMonth, loadMore, isLoading } = props;
  return (
    <TableBody className={classes.tableBody}>
      <TableRow className={classes.flexContainer}>
        <MonthlyUsersColumnContainer firstDayOfMonth={firstDayOfMonth} loadMore={loadMore} isLoading={isLoading} />
        <RecordsColumnContainer firstDayOfMonth={firstDayOfMonth} />
        <TotalsColumnContainer />
      </TableRow>
      {isLoading && <TableRowSpinner />}
    </TableBody>
  );
};

const MonthlyReportPage = (props) => {
  const { firstDayOfMonth, getMonthlyReport, clearMonthlyReport, isLoading, navigate } = props;

  const pageSize = paginationConstants.itemsPerPage;
  const [usersPagination, setUsersPagination] = useState({
    page: 1,
    search: '',
  });
  const firstDayOfMonthString = firstDayOfMonth.format('YYYY-MM-DD');

  const handleMonthChange = useCallback(
    (date) => {
      clearMonthlyReport();
      setUsersPagination((prev) => ({ ...prev, page: 1 }));
      navigate(`/reports/monthly?firstDayOfMonth=${date.format('YYYY-MM-DD')}`, { replace: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleLoadUsers = useCallback(() => {
    setUsersPagination((prev) => ({ ...prev, page: prev.page + 1 }));
  }, []);
  const handleSearchUsers = useCallback(
    (name) => {
      clearMonthlyReport();
      setUsersPagination((prev) => ({ ...prev, search: name, page: 1 }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    return () => {
      clearMonthlyReport();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMonthlyReport(firstDayOfMonth, usersPagination.search, usersPagination.page, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDayOfMonthString, usersPagination.search, usersPagination.page, getMonthlyReport, pageSize]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 37) {
        const previousMonth = moment(firstDayOfMonth).startOf('month').subtract(1, 'month');
        handleMonthChange(previousMonth);
      }
      if (e.keyCode === 39) {
        const nextMonth = moment(firstDayOfMonth).startOf('month').add(1, 'month');
        handleMonthChange(nextMonth);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDayOfMonth]);

  return (
    <StyledPaper className={classes.root} elevation={2}>
      <Table className={classes.table}>
        <TableHead className={classes.tableHeader}>
          <TableRow className={classes.container}>
            <TableCell className={classes.borderlessCell}>
              <MonthPicker firstDayOfMonth={firstDayOfMonth} monthChanged={handleMonthChange} />
            </TableCell>
            <TableCell className={classes.borderlessCell}>
              <SearchUserFormContainer onSearch={handleSearchUsers} />
            </TableCell>
          </TableRow>
        </TableHead>
        <MonthlyReport
          classes={classes}
          firstDayOfMonth={firstDayOfMonth}
          loadMore={handleLoadUsers}
          isLoading={isLoading}
        />
      </Table>
      <ActivityTypesLegendBar />
    </StyledPaper>
  );
};

export default MonthlyReportPage;
