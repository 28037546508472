import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { formatHours } from '../../helper/hoursFormatter';

const StyledTypography = styled(Typography, { shouldForwardProp: (prop) => prop !== 'isBold' })(
  ({ theme, isBold }) => ({
    textAlign: 'center',
    fontSize: '14px',
    color: isBold ? theme.palette.black.main : theme.palette.grey.dark,
    lineHeight: '17px',
    fontWeight: isBold ? '600' : '400',
  })
);

const StaticRow = (props) => {
  const { isBold, hours } = props;
  return <StyledTypography isBold={isBold}>{formatHours(hours)}</StyledTypography>;
};

export default StaticRow;
