import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import OutOfOfficeActivityContainer from '../OutOfOfficeActivity/OutOfOfficeActivityContainer';
import HoursRowContainer, { ROW_TYPE } from '../HoursRow/HoursRowContainer';

const OutOfOfficeActivityType = (props) => {
  const { classes, label, totals, activities, userId } = props;
  return (
    <>
      <TableRow className={classes.activityRow}>
        <TableCell style={{ borderBottom: 'none' }}>
          <Typography type="subtitle1">{label}</Typography>
        </TableCell>
        <HoursRowContainer
          display={ROW_TYPE.TYPOGRAPHY}
          totalHoursPerDay={totals.totalHoursPerDay}
          totalHoursPerWeek={totals.totalHoursPerWeek}
          isUnderlined={false}
          isTotalsHighlighted={true}
        />
      </TableRow>
      {activities.map((act) => (
        <OutOfOfficeActivityContainer key={act.activityId} label={act.activityTitle} activity={act} userId={userId} />
      ))}
    </>
  );
};

OutOfOfficeActivityType.propTypes = {
  label: PropTypes.string.isRequired,
  activities: PropTypes.arrayOf(PropTypes.object),
  totals: PropTypes.shape({
    totalHoursPerDay: PropTypes.arrayOf(PropTypes.object).isRequired,
    totalHoursPerWeek: PropTypes.number.isRequired,
  }),
};

export default React.memo(OutOfOfficeActivityType);
