import React from 'react';
import { Helmet } from 'react-helmet';

const PageTitle = (props) => {
  const { pageTitle } = props;
  return (
    <Helmet>
      <title>{`${pageTitle} | Sunmait Tech.`}</title>
    </Helmet>
  );
};

export default PageTitle;
