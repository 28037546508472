import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import HeaderBarContainer from '../../common/HeaderBarWrapper/HeaderBar/HeaderBarContainer';
import PageTitle from '../../common/PageTitle/PageTitle';
import UserActivitiesTableContainer from './UserActivitiesTableContainer';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo } from '../../../redux/modules/users/usersSelectors';
import { clearUserInfo, getUserById } from '../../../redux/modules/users/usersActions';
import ProductionCalendar from './ProductionCalendar/ProductionCalendar';

const PREFIX = 'UserActivitiesTablePage';

const classes = {
  contentWrapper: `${PREFIX}-contentWrapper`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
  height: '100%',

  [`& .${classes.contentWrapper}`]: {
    display: 'flex',
    height: 'calc(100vh - 58px)',
    flexDirection: 'column',
    backgroundColor: theme.palette.whitegrey,
    padding: '30px 0',
    overflow: 'auto',
  },
}));

const UserWeekTimesheetPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  const userId = params.id;
  const userInfo = useSelector(selectUserInfo);

  useEffect(() => {
    if (!userId) return;
    dispatch(getUserById(userId));
    return () => {
      dispatch(clearUserInfo());
    };
  }, [userId]);

  const pageTitle = userInfo ? `Week Timesheet of ${userInfo.name}` : 'Week Timesheet';
  return (
    <>
      <PageTitle pageTitle={pageTitle} />
      <Root>
        <HeaderBarContainer pageTitle={pageTitle} withBackButton={!!userId} />
        <div className={classes.contentWrapper}>
          <ProductionCalendar params={params} />
          <UserActivitiesTableContainer location={location} params={params} />
        </div>
      </Root>
    </>
  );
};

export default UserWeekTimesheetPage;
