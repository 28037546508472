import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import StaticRow from '../StaticRow/StaticRow';
import { ROW_TYPE } from '../../pages/UserWeekTimesheetPage/HoursRow/HoursRowContainer';
import InputField from '../../fields/InputField';
import { firstDayOfWeekSelector, weekEventsSelector } from '../../../redux/modules/records/recordsSelectors';

const PREFIX = 'EditableRow';

const classes = {
  editableCell: `${PREFIX}-editableCell`,
  inputDescription: `${PREFIX}-inputDescription`,
  flexContainer: `${PREFIX}-flexContainer`,
  hoursContainer: `${PREFIX}-hoursContainer`,
  sickLeaveIcon: `${PREFIX}-sickLeaveIcon`,
};

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isUnderlined' && prop !== 'isWeekEnd' && prop !== 'event',
})(({ theme, isUnderlined, isWeekEnd, event }) => {
  let bgColor;
  if (event && event.isHoliday) {
    bgColor = theme.palette.calendar.holiday;
  } else if (!event && isWeekEnd) {
    bgColor = theme.palette.calendar.weekEnd;
  } else {
    bgColor = 'inherit';
  }

  return {
    width: '9%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '40px',
    borderBottom: isUnderlined ? '1px solid rgba(224, 224, 224, 1);' : 'none',
    backgroundColor: bgColor,

    [`& .${classes.inputDescription}`]: {
      width: '100%',
    },

    [`& .${classes.flexContainer}`]: {
      width: '50%',
      margin: 'auto',
    },

    [`& .${classes.hoursContainer}`]: {
      position: 'relative',
    },

    [`& .${classes.sickLeaveIcon}`]: {
      position: 'absolute',
      fontSize: '20px',
      right: '0.15rem',
      bottom: '-10%',
      [theme.breakpoints.down('md')]: {
        right: '-0.95rem',
      },
    },
  };
});

const numeric = (value) => {
  const parsedValue = parseFloat(value);
  return isNaN(parsedValue) ? '' : parsedValue;
};

const EditableRow = (props) => {
  const { inputItems, rowIndex, display, handleChangeInput, handleBlur, isUnderlined, isReadonly, isSickLeave } = props;
  const firstDayOfWeek = useSelector(firstDayOfWeekSelector);
  const events = useSelector(weekEventsSelector);

  return inputItems.map((item, index) => {
    const date = moment(firstDayOfWeek).add(index, 'd');
    const event = events.find((e) => date >= moment(e.startDate) && date <= moment(e.endDate).subtract(1, 'd'));

    return (
      <StyledTableCell key={index} isUnderlined={isUnderlined} isWeekEnd={index === 5 || index === 6} event={event}>
        {display === ROW_TYPE.INPUT && !isReadonly && !item.isReadonly && (
          <div className={classes.flexContainer}>
            <InputField
              onChange={(event) => handleChangeInput(item, +event.target.value, index)}
              normalize={numeric}
              name={`tablecell-${rowIndex}-${index}`}
              className={classes.inputDescription}
              isCentered
              placeholder="0"
              type="number"
              onBlur={() => handleBlur(item)}
            />
          </div>
        )}
        {(display === ROW_TYPE.TYPOGRAPHY || item.isReadonly || isReadonly) && (
          <div className={classes.hoursContainer}>
            <StaticRow hours={item.hours} />
            {isSickLeave && item.isSickList && <LocalHospitalIcon color="primary" className={classes.sickLeaveIcon} />}
          </div>
        )}
      </StyledTableCell>
    );
  });
};

EditableRow.propTypes = {
  inputItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  display: PropTypes.string.isRequired,
};

export default EditableRow;
