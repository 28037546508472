import React from 'react';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import InputField from '../../../fields/InputField';

const PREFIX = 'SearchUserForm';

const classes = {
  input: `${PREFIX}-input`,
};

const Root = styled('form')(({ theme }) => ({
  backgroundColor: theme.palette.white,

  [`& .${classes.input}`]: {
    border: 'none',
    overflow: 'auto',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    width: '80%',
    fontFamily: theme.typography.fontFamily,
  },
}));

// TODO: prevent search form from forcing re-rendering the parent component onChange, onBlur and onFocus
const SearchUserForm = (props) => {
  const { handleSubmit } = props;
  return (
    <Root onSubmit={handleSubmit}>
      <InputField
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        className={classes.input}
        name="name"
        label="Search"
      />
    </Root>
  );
};

export default SearchUserForm;
