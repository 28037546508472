import { connect } from 'react-redux';
import * as modalsAction from '../../../redux/modules/modals/modalsActions';
import UpdateActivityModal from './UpdateActivityModal';

const mapStateToProps = (state) => ({
  id: state.modals.modalProps.id,
});

const mapDispatchToProps = {
  hideModal: modalsAction.hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateActivityModal);
