import React from 'react';
import { components } from 'react-select';
import IntersectionObserverComponent from '../IntersectionObserverComponent/IntersectionObserverComponent';
import Spinner from '../Spinner/Spinner';

const InfiniteSelectMenuList = ({ children, ...menuListProps }) => {
  const { loadMore, hasMore, isLoading } = menuListProps.selectProps.innerProps;
  return (
    <components.MenuList {...menuListProps}>
      {!children.length && isLoading ? (
        <Spinner />
      ) : (
        React.Children.map(children, (child, index) =>
          children.length - 1 === index && hasMore ? (
            <IntersectionObserverComponent onIntersect={loadMore} isLoading={isLoading}>
              {child}
            </IntersectionObserverComponent>
          ) : (
            child
          )
        )
      )}
    </components.MenuList>
  );
};

export default InfiniteSelectMenuList;
