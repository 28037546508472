import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import YearlyRecordCell from './YearlyRecordCell';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

const YearlyRecordsRow = (props) => {
  const { records } = props;
  return (
    <Root>
      <YearlyRecordCell hours={records.projectHours} />
      <YearlyRecordCell hours={records.nonProjectHours} />
      <YearlyRecordCell hours={records.vacationHours} />
      <YearlyRecordCell hours={records.sickLeaveHours} />
      <YearlyRecordCell hours={records.unpaidLeaveHours} />
    </Root>
  );
};

YearlyRecordsRow.propTypes = {
  records: PropTypes.object,
};

export default YearlyRecordsRow;
