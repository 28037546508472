import 'moment/locale/en-gb';
import moment from 'moment';
import RECORDS_CONSTANTS from './recordsConstants';
import generateId from '../../../components/helper/numericIdGenerator';
import actionTypes from '../../../components/helper/actionTypes';
import outOfOfficeActivities from '../../../components/helper/outOfOfficeActivities';

const defaultState = {
  userActivities: [],
  tasks: [],
  timeTrackRecords: [],
  totals: [],
  sickLists: [],
  events: [],
  productionCalendar: '',
  productionCalendars: [],
  firstDayOfWeek: moment().startOf('week'),
  dataWasChanged: false,
  isLoading: true,
};

const getActivityTotals = ({ userActivities, tasks, timeTrackRecords }) => {
  return userActivities.map((activity) => {
    const activityId = activity.activityId;
    const activityType = activity.activityType;

    const activityTasks = tasks.filter((task) => task.userActivityId === activityId);
    const records = activityTasks.reduce(
      (records, task) =>
        records.concat(
          timeTrackRecords.filter((record) => record.action !== actionTypes.deleted && record.taskId === task.id)
        ),
      []
    );

    const totalHoursPerDay = records.reduce((total, record) => {
      const dayIndex = total.findIndex((day) => day.date === record.date);
      if (dayIndex !== -1) {
        total[dayIndex].hours += record.hours;
      } else {
        if (record.id !== 0) total.push({ ...record });
      }
      return total;
    }, []);

    const totalHoursPerWeek = totalHoursPerDay.reduce((hours, record) => hours + record.hours, 0);

    return {
      activityId,
      activityType,
      totalHoursPerDay,
      totalHoursPerWeek,
    };
  });
};

const recordsReducers = (state = defaultState, action) => {
  switch (action.type) {
    case RECORDS_CONSTANTS.GET_TIMETRACK_WEEK:
      return handleGetTimeTrackWeek(state, action.payload);

    case RECORDS_CONSTANTS.TIMETRACK_WEEK_PENDING:
      return handleTimeTrackWeekPending(state, action.payload);

    case RECORDS_CONSTANTS.UPDATE_TIMETRACK_WEEK:
      return handleUpdateTimeTrackWeek(state, action.payload);

    case RECORDS_CONSTANTS.REMOVE_TASK:
      return handleRemoveTask(state, action.payload);

    case RECORDS_CONSTANTS.UPDATE_TASK:
      return handleUpdateTask(state, action.payload);

    case RECORDS_CONSTANTS.ADD_TASK:
      return handleAddTask(state, action.payload);

    case RECORDS_CONSTANTS.UPDATE_TIMETRACK_RECORD:
      return handleUpdateTimeTrackRecord(state, action.payload);

    case RECORDS_CONSTANTS.UPDATE_EMPTY_TIMETRACK_RECORD:
      return handleUpdateEmptyTimeTrackRecord(state, action.payload);

    case RECORDS_CONSTANTS.ADD_TIMETRACK_RECORD:
      return handleAddTimeTrackRecord(state, action.payload);

    case RECORDS_CONSTANTS.DELETE_TIMETRACK_RECORD:
      return handleDeleteTimeTrackRecord(state, action.payload);

    case RECORDS_CONSTANTS.SET_FIRST_DAY_OF_WEEK:
      return handleSetFirstDayOfWeek(state, action.payload);

    case RECORDS_CONSTANTS.ADD_UPDATE_SICK_LIST:
      return handleAddUpdateSickList(state, action.payload);

    case RECORDS_CONSTANTS.GET_PRODUCTION_CALENDAR:
      return handleGetSetProductionCalendar(state, action.payload);

    case RECORDS_CONSTANTS.SET_PRODUCTION_CALENDAR:
      return handleGetSetProductionCalendar(state, action.payload);

    case RECORDS_CONSTANTS.GET_PRODUCTION_CALENDARS:
      return handleGetSetProductionCalendars(state, action.payload);

    default:
      return state;
  }
};

function handleSetFirstDayOfWeek(state, date) {
  return {
    ...state,
    firstDayOfWeek: moment(date),
  };
}

function handleGetTimeTrackWeek(state, timeTrackWeek) {
  return {
    ...state,
    ...timeTrackWeek,
    dataWasChanged: false,
    totals: getActivityTotals(timeTrackWeek),
    isLoading: false,
  };
}

function handleTimeTrackWeekPending(state, pending) {
  return {
    ...state,
    isLoading: pending,
  };
}

function handleUpdateTimeTrackWeek(state, payload) {
  return state;
}

function handleRemoveTask(state, payload) {
  const { taskId, isSickList } = payload;

  const result = {
    ...state,
    dataWasChanged: !isSickList,
    tasks: state.tasks.map((task) => {
      if (task.id === taskId) {
        return {
          ...task,
          action: actionTypes.deleted,
        };
      }
      return task;
    }),
    timeTrackRecords: state.timeTrackRecords.reduce((timeTrackRecords, record) => {
      if (record.taskId === taskId) {
        if (record.isNew) {
          return timeTrackRecords;
        } else {
          return timeTrackRecords.concat({
            ...record,
            action: actionTypes.deleted,
          });
        }
      }
      return timeTrackRecords.concat(record);
    }, []),
  };

  return {
    ...result,
    totals: getActivityTotals(result),
  };
}

function handleUpdateTask(state, payload) {
  const { taskId, description } = payload;

  return {
    ...state,
    dataWasChanged: true,
    tasks: state.tasks.map((task) => {
      if (task.id === taskId.toString()) {
        return {
          ...task,
          description: description,
          action: actionTypes.updated,
        };
      }
      return task;
    }),
  };
}

function handleAddTask(state, payload) {
  const { description, userActivityId } = payload;

  const taskId = generateId().toString();

  return {
    ...state,
    tasks: state.tasks.concat({
      id: taskId,
      description: description,
      userActivityId: userActivityId,
      timeTrackRecordIds: [],
      action: actionTypes.added,
    }),
    userActivities: state.userActivities.map((activity) => {
      if (activity.activityId === userActivityId) {
        return {
          ...activity,
          taskIds: activity.taskIds.concat(taskId),
        };
      }
      return activity;
    }),
  };
}

function handleUpdateTimeTrackRecord(state, payload) {
  const { value, timeTrackRecordId } = payload;

  const result = {
    ...state,
    dataWasChanged: true,
    timeTrackRecords: state.timeTrackRecords.map((record) => {
      if (record.id === timeTrackRecordId) {
        return {
          ...record,
          action: actionTypes.updated,
          hours: value,
        };
      }
      return record;
    }),
  };

  return {
    ...result,
    totals: getActivityTotals(result),
  };
}

function handleAddTimeTrackRecord(state, payload) {
  const { value, date, taskId } = payload;

  const recordId = generateId();

  const result = {
    ...state,
    dataWasChanged: true,
    timeTrackRecords: state.timeTrackRecords.concat({
      id: recordId,
      date: date,
      hours: value,
      taskId: taskId,
      isNew: true,
      action: actionTypes.added,
    }),
    tasks: state.tasks.map((task) => {
      if (task.id === taskId) {
        return {
          ...task,
          timeTrackRecordIds: task.timeTrackRecordIds.concat(recordId),
        };
      }
      return task;
    }),
  };

  return {
    ...result,
    totals: getActivityTotals(result),
  };
}

function handleUpdateEmptyTimeTrackRecord(state, payload) {
  const { value, date, taskId } = payload;

  const result = {
    ...state,
    dataWasChanged: true,
    timeTrackRecords: state.timeTrackRecords.map((record) => {
      if (record.date === date && record.taskId === taskId) {
        return {
          ...record,
          id: generateId(),
          action: actionTypes.added,
          hours: value,
          isNew: true,
        };
      }
      return record;
    }),
  };

  return {
    ...result,
    totals: getActivityTotals(result),
  };
}

function handleDeleteTimeTrackRecord(state, payload) {
  const { timeTrackRecordId, taskId } = payload;

  const result = {
    ...state,
    dataWasChanged: true,
    timeTrackRecords: state.timeTrackRecords.reduce((timeTrackRecords, record) => {
      if (record.id === timeTrackRecordId) {
        if (record.isNew) {
          return timeTrackRecords;
        } else {
          return timeTrackRecords.concat({
            ...record,
            action: actionTypes.deleted,
          });
        }
      }
      return timeTrackRecords.concat(record);
    }, []),
    tasks: state.tasks.map((task) => {
      if (task.id === taskId) {
        return {
          ...task,
          timeTrackRecordIds: task.timeTrackRecordIds.filter((id) => id !== timeTrackRecordId),
        };
      }
      return task;
    }),
  };

  return {
    ...result,
    totals: getActivityTotals(result),
  };
}

function handleAddUpdateSickList(state, payload) {
  const { dateRange } = payload;
  const sickLeaveTaskId = state.tasks.find((task) => task.description === outOfOfficeActivities.sickLeave.name).id;
  const sickLeaveRecordsInDateRange = state.timeTrackRecords.filter(
    (record) =>
      record.taskId === sickLeaveTaskId && moment(record.date).isBetween(dateRange[0], dateRange[1], 'days', '[]')
  );

  return {
    ...state,
    timeTrackRecords: [
      ...state.timeTrackRecords.filter((record) => record.taskId !== sickLeaveTaskId),
      ...sickLeaveRecordsInDateRange.map((record) => {
        if (record.isNew) return { ...record, hours: 0, isNew: false };
        return {
          ...record,
          action: actionTypes.deleted,
        };
      }),
    ],
  };
}

function handleGetSetProductionCalendar(state, payload) {
  return {
    ...state,
    productionCalendar: payload,
  };
}

function handleGetSetProductionCalendars(state, payload) {
  return {
    ...state,
    productionCalendars: payload,
  };
}

export default recordsReducers;
