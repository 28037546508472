import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import TaskContainer from '../Task/TaskContainer';
import HoursRowContainer, { ROW_TYPE } from '../HoursRow/HoursRowContainer';
import outOfOfficeActivities from '../../../helper/outOfOfficeActivities';

const PREFIX = 'OutOfOfficeActivity';

const classes = {
  addSickListButton: `${PREFIX}-addSickListButton`,
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.addSickListButton}`]: {
    color: theme.palette.primary.light,
    fontWeight: 600,
    cursor: 'pointer',
    userSelect: 'none',
    WebkitTouchCallout: 'none',
  },
}));

const OutOfOfficeActivity = (props) => {
  const {
    addTask,
    activity,
    label,
    totals,
    sickListTasks,
    sickLists,
    isLast,
    showAddSickListModal,
    sickLeaveTask,
    userId,
    currentUser,
    sendUpdatedTimeTrackWeek,
    sendUpdatedEmployeesTimeTrackWeek,
  } = props;
  const isSickLeaveActivity = activity.activityTitle === outOfOfficeActivities.sickLeave.name;

  useEffect(() => {
    if (activity.taskIds.length === 0) {
      addTask('', activity.activityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateSickLists = () => {
    if (userId === currentUser.id) {
      sendUpdatedTimeTrackWeek();
    } else {
      sendUpdatedEmployeesTimeTrackWeek(userId);
    }
  };

  const handleAddSickListClick = () => {
    showAddSickListModal(userId, handleUpdateSickLists);
  };

  return (
    <>
      <StyledTableRow>
        <TableCell style={{ borderBottom: 'none' }}>
          <Typography type="subtitle1">{label}</Typography>
          {isSickLeaveActivity && sickLists.length < 4 && (
            <Typography variant="body1" className={classes.addSickListButton} onClick={handleAddSickListClick}>
              Add sick list
            </Typography>
          )}
        </TableCell>
        <HoursRowContainer
          display={ROW_TYPE.INPUT}
          totalHoursPerDay={totals.totalHoursPerDay}
          totalHoursPerWeek={totals.totalHoursPerWeek}
          activityType={activity.activityType}
          userActivityId={activity.activityId}
          taskId={isSickLeaveActivity ? sickLeaveTask.id : activity.taskIds[0]}
          isUnderlined={false}
          isTotalsHighlighted={true}
          isSickLeave={isSickLeaveActivity}
        />
      </StyledTableRow>
      {isSickLeaveActivity &&
        sickListTasks.map((item, index, array) => (
          <TaskContainer
            key={item.id}
            description={item.description}
            taskId={item.id}
            userActivityId={activity.activityId}
            userId={userId}
            isUnderlined={!isLast || (isLast && index !== array.length - 1)}
            isReadonly={true}
            focusDescriptionOnMount={false}
          />
        ))}
    </>
  );
};

OutOfOfficeActivity.propTypes = {
  label: PropTypes.string.isRequired,
  activity: PropTypes.object.isRequired,
  totals: PropTypes.object.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object),
};

export default React.memo(OutOfOfficeActivity);
