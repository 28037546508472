import React, { useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import InfiniteList from '../../common/InfiniteList/InfiniteList';
import ConfirmationModal from '../../common/ConfirmationModal/ConfirmationModal';
import EditIcon from '../../../assets/icons-svg/pencil.svg';
import DeleteIcon from '../../../assets/icons-svg/basket.svg';
import activityTypes from '../../helper/activityTypes';

const PREFIX = 'ActivitiesList';

const classes = {
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
  listItemText: `${PREFIX}-listItemText`,
  iconButton: `${PREFIX}-iconButton`,
};

const StyledInfiniteList = styled(InfiniteList)(({ theme }) => ({
  boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
  height: '370px',
  minHeight: '150px',
  overflowY: 'auto',
  backgroundColor: theme.palette.white,
  margin: '10px 0',
  padding: '10px 10px 0 0',

  [`& .${classes.listItem}`]: {
    height: '36px',
  },

  [`& .${classes.listItemText}`]: {
    fontSize: '18px',
    margin: '5px 0',
    color: `${theme.palette.secondary.main} !important`,
  },

  [`& .${classes.iconButton}`]: {
    width: '36px',
    height: '36px',
  },
}));

const ActivitiesList = ({
  onDeleteButtonClicked,
  activities,
  className,
  itemCanBeEdited,
  onEditButtonClicked,
  isLoading,
  loadMore,
  hasMore,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentActivityId, setCurrentActivityId] = useState(0);

  const handleOpenModal = (id) => {
    setIsModalOpen(true);
    setCurrentActivityId(id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentActivityId(0);
  };

  const handleSubmitModal = () => {
    onDeleteButtonClicked(currentActivityId);
    handleCloseModal();
  };

  const renderActivity = (activity) => (
    <ListItem key={activity.id} className={classes.listItem} dense button>
      <Typography className={classes.listItemText}>{activity.name}</Typography>
      <ListItemSecondaryAction>
        {itemCanBeEdited && (
          <IconButton onClick={() => onEditButtonClicked(activity)} className={className} size="large">
            <img alt={'edit'} src={EditIcon} />
          </IconButton>
        )}
        <IconButton onClick={() => handleOpenModal(activity.id)} className={classes.iconButton} size="large">
          <img alt={'delete'} src={DeleteIcon} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );

  const activitiesFiltered = useMemo(
    () => activities.filter((activity) => activity.activityType !== activityTypes.outOfOffice),
    [activities]
  );

  return (
    <>
      <StyledInfiniteList
        className={classes.list}
        component="nav"
        isLoading={isLoading}
        hasMore={hasMore}
        loadMore={loadMore}
      >
        {activitiesFiltered.map((activity) => renderActivity(activity))}
      </StyledInfiniteList>
      <ConfirmationModal
        isOpen={isModalOpen}
        confirmButtonName={'Delete'}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitModal}
        modalTitle={'Are you sure you want to delete this activity?'}
      />
    </>
  );
};

export default ActivitiesList;
