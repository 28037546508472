import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ActivitiesSelectField from '../../fields/ActivitiesSelectField';

const AddUserToActivityForm = ({
  classes,
  handleSubmit,
  isDisabled,
  hasMore,
  loadMore,
  options,
  isLoading,
  onInputChange,
  activities,
  onSubmit,
}) => {
  const [selectedActivity, setSelectedActivity] = useState(null);
  return (
    <form
      onSubmit={handleSubmit((values, dispatch, props) => {
        onSubmit({ activity: selectedActivity }, dispatch, props);
      })}
    >
      <div className={classes.flexContainer} id="user-activities__select-activities">
        <ActivitiesSelectField
          className={classes.select}
          name="activity"
          isDisabled={isDisabled}
          hasMore={hasMore}
          loadMore={loadMore}
          options={options}
          isLoading={isLoading}
          onInputChange={onInputChange}
          onChange={(option) => setSelectedActivity(activities.find((x) => x.id === option.value))}
          menuPosition={'fixed'}
        />
        <Button variant="contained" color="secondary" type="submit" className={classes.button}>
          ADD ACTIVITY
        </Button>
      </div>
    </form>
  );
};

export default AddUserToActivityForm;
