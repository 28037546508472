import { createSelector } from 'reselect';

const userSelector = (state) => (state.oidc.user ? state.oidc.user : null);

export const selectUserInfo = (state) => state.users.userInfo;

export const selectUsers = (state) => state.users.users;

export const selectAllUsers = (state) => state.users.allUsers;

export const selectCurrentUserId = (state) => state.users.currentUserId;

export const selectUsersIsLoading = (state) => state.users.isLoading;

export const getCurrentUser = createSelector([userSelector], (user) => {
  if (!user) {
    return null;
  }

  return {
    id: user.profile.id,
    email: user.profile.email,
    firstName: user.profile.given_name,
    lastName: user.profile.family_name,
    role: user.profile.role,
    userName: user.profile.nickname,
    authorization: {
      expired: user.expired,
      idToken: user.id_token,
    },
  };
});
