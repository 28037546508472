import RECORDS_CONSTANTS from './recordsConstants';
import { withAPIFormat } from '../../../components/helper/momentJsExtensions';
import { getNotEmptyRecords } from './recordsSelectors';
import * as recordsRequests from '../../../axios/records';
import { parseErrors } from '../../../components/helper/requestsHelpers';

export const getTimeTrackWeek = (firstDayOfWeekDate) => async (dispatch) => {
  dispatch({
    type: RECORDS_CONSTANTS.TIMETRACK_WEEK_PENDING,
    payload: true,
  });

  const response = await recordsRequests.getTimeTrackWeek(firstDayOfWeekDate);

  dispatch({
    type: RECORDS_CONSTANTS.GET_TIMETRACK_WEEK,
    payload: response.data,
  });
};

export const getEmployeesTimeTrackWeek = (userId, firstDayOfWeekDate) => async (dispatch) => {
  dispatch({
    type: RECORDS_CONSTANTS.TIMETRACK_WEEK_PENDING,
    payload: true,
  });

  const response = await recordsRequests.getEmployeesTimeTrackWeek(userId, firstDayOfWeekDate);

  dispatch({
    type: RECORDS_CONSTANTS.GET_TIMETRACK_WEEK,
    payload: response.data,
  });
};

export const setFirstDayOfWeek = (firstDayOfWeekDate) => ({
  type: RECORDS_CONSTANTS.SET_FIRST_DAY_OF_WEEK,
  payload: firstDayOfWeekDate,
});

export const removeTask = (taskId, isSickList) => ({
  type: RECORDS_CONSTANTS.REMOVE_TASK,
  payload: {
    taskId,
    isSickList,
  },
});

export const updateTask = (taskId, description) => ({
  type: RECORDS_CONSTANTS.UPDATE_TASK,
  payload: {
    taskId,
    description,
  },
});

export const addTask = (description, userActivityId) => ({
  type: RECORDS_CONSTANTS.ADD_TASK,
  payload: {
    description,
    userActivityId,
  },
});

export const updateTimeTrackRecord = (value, timeTrackRecordId) => ({
  type: RECORDS_CONSTANTS.UPDATE_TIMETRACK_RECORD,
  payload: {
    value,
    timeTrackRecordId,
  },
});

export const addTimeTrackRecord = (value, date, taskId) => ({
  type: RECORDS_CONSTANTS.ADD_TIMETRACK_RECORD,
  payload: {
    value,
    date: withAPIFormat(date),
    taskId,
  },
});

export const deleteTimeTrackRecord = (timeTrackRecordId, taskId) => ({
  type: RECORDS_CONSTANTS.DELETE_TIMETRACK_RECORD,
  payload: {
    timeTrackRecordId,
    taskId,
  },
});

export const updateEmptyTimeTrackRecord = (value, date, taskId) => ({
  type: RECORDS_CONSTANTS.UPDATE_EMPTY_TIMETRACK_RECORD,
  payload: {
    value,
    date: withAPIFormat(date),
    taskId,
  },
});

export const sendUpdatedTimeTrackWeek = () => async (dispatch, getState) => {
  dispatch({
    type: RECORDS_CONSTANTS.TIMETRACK_WEEK_PENDING,
    payload: true,
  });

  try {
    const timeTrackWeek = getState().timeTrackWeek;

    const response = await recordsRequests.sendUpdatedTimeTrackWeek(
      timeTrackWeek.userActivities,
      timeTrackWeek.tasks,
      getNotEmptyRecords(getState())
    );

    dispatch(getTimeTrackWeek(timeTrackWeek.firstDayOfWeek));
    return { status: response.status };
  } catch (e) {
    return await parseErrors(e);
  } finally {
    dispatch({
      type: RECORDS_CONSTANTS.TIMETRACK_WEEK_PENDING,
      payload: false,
    });
  }
};

export const sendUpdatedEmployeesTimeTrackWeek = (userId) => async (dispatch, getState) => {
  dispatch({
    type: RECORDS_CONSTANTS.TIMETRACK_WEEK_PENDING,
    payload: true,
  });
  try {
    const timeTrackWeek = getState().timeTrackWeek;

    const response = await recordsRequests.sendUpdatedEmployeesTimeTrackWeek(
      userId,
      timeTrackWeek.userActivities,
      timeTrackWeek.tasks,
      getNotEmptyRecords(getState())
    );

    dispatch(getEmployeesTimeTrackWeek(userId, timeTrackWeek.firstDayOfWeek));
    return { status: response.status };
  } catch (e) {
    return await parseErrors(e);
  } finally {
    dispatch({
      type: RECORDS_CONSTANTS.TIMETRACK_WEEK_PENDING,
      payload: false,
    });
  }
};
