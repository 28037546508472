import React from 'react';
import MonthlyExcelReportFormContainer from './MonthlyExcelReportFormContainer';
import Table from '@mui/material/Table';
import TableBodySpinner from '../../../common/TableSpinners/TableBodySpinner';
import MonthPicker from '../../../common/DatePickers/MonthPicker';
import { useEffect } from 'react';

const MonthlyExcelReport = (props) => {
  const { hideModal, userId, firstDayOfMonth, isLoading, monthChanged, getMonthlyUserActivities, userActivities } =
    props;

  useEffect(() => {
    getMonthlyUserActivities(firstDayOfMonth, userId);
  }, [firstDayOfMonth, getMonthlyUserActivities, userId]);

  return (
    <>
      <MonthPicker monthChanged={monthChanged} firstDayOfMonth={firstDayOfMonth} />
      {isLoading ? (
        <Table>
          <TableBodySpinner />
        </Table>
      ) : (
        <MonthlyExcelReportFormContainer
          onCancel={hideModal}
          userId={userId}
          firstDayOfMonth={firstDayOfMonth}
          userActivities={userActivities}
        />
      )}
    </>
  );
};

export default MonthlyExcelReport;
