import { combineReducers } from 'redux';
import activities from '../modules/activities/activitiesReducers';
import recordsReducer from '../modules/records/recordsReducers';
import modals from '../modules/modals/modalsReducers';
import { reducer as oidcReducer } from 'redux-oidc';
import { reducer as formReducer } from 'redux-form';
import users from '../modules/users/usersReducers';
import report from '../modules/report/reportReducer';
import employees from '../modules/employees/employeesReducers';
import vacation from '../modules/vacation/vacationReducers';
import productionCalendar from '../modules/productionCalendar/productionCalendarReducers';

const rootReducer = combineReducers({
  activities,
  timeTrackWeek: recordsReducer,
  modals,
  users,
  report,
  oidc: oidcReducer,
  form: formReducer,
  employees,
  vacation,
  productionCalendar,
});

export default rootReducer;
