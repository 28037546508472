import { createSelector } from 'reselect';

export const getActivities = (state) => state.activities.activities;
const getCurrentUserId = (state) => state.users.currentUserId;

export const getUnassignedActivities = createSelector(
  [getActivities, getCurrentUserId],
  (activities, currentUserId) => {
    if (currentUserId) {
      return activities.filter((activity) => !activity.userIds.includes(currentUserId));
    } else {
      return [];
    }
  }
);
