import React from 'react';
import { connect } from 'react-redux';
import UpdateActivityModalContainer from './modals/UpdateActivityModal/UpdateActivityModalContainer';
import { MODAL_TYPES } from '../redux/modules/modals/modalsConstants';
import OOOActivitiesModalContainer from './modals/OOOActivitiesModal/OOOActivitiesModalContainer';
import PdfReportModalContainer from './modals/PdfReportModal/PdfReportModalContainer';
import ExcelReportModalContainer from './modals/ExcelReportModal/ExcelReportModalContainer';
import SickListModalContainer from './modals/SickListModal/SickListModalContainer';
import UpdateResourceManagerModal from './modals/UpdateResourceManagerModal/UpdateResourceManagerModal';
import MyManagersModal from './modals/MyManagers/MyManagersModal';
import ExportWeekChangeHistoryModalContainer from './modals/ExportWeekChangeHistoryModal/ExportWeekChangeHistoryModalContainer';

const MODAL_COMPONENTS = {
  [MODAL_TYPES.UPDATE_ACTIVITY]: UpdateActivityModalContainer,
  [MODAL_TYPES.OOO_ACTIVITIES_MODAL]: OOOActivitiesModalContainer,
  [MODAL_TYPES.PDF_REPORT_MODAL]: PdfReportModalContainer,
  [MODAL_TYPES.EXCEL_REPORT_MODAL]: ExcelReportModalContainer,
  [MODAL_TYPES.SICK_LIST_MODAL]: SickListModalContainer,
  [MODAL_TYPES.UPDATE_RESOURCE_MANAGER_MODAL]: UpdateResourceManagerModal,
  [MODAL_TYPES.MY_MANAGERS]: MyManagersModal,
  [MODAL_TYPES.EXPORT_WEEK_CHANGE_HISTORY]: ExportWeekChangeHistoryModalContainer,
};

const ModalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    return null;
  }
  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...modalProps} />;
};

export default connect((state) => state.modals)(ModalRoot);
