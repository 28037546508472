import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import InputField from '../../../fields/InputField';
import ActivityTypesSelectField from '../../../fields/ActivityTypesSelectField';

const PREFIX = 'AddActivityForm';

const classes = {
  input: `${PREFIX}-input`,
  select: `${PREFIX}-select`,
  button: `${PREFIX}-button`,
};

const Root = styled('form')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '32px 0px 10vh 0px',

  [`& .${classes.input}`]: {
    border: 'none',
    overflow: 'auto',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '-10px 5% 5px 0px',
    flex: '45%',
  },
  [`& .${classes.select}`]: {
    border: 'none',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '5px 5% 5px 0px',
    flex: '35%',
    fontFamily: theme.typography.fontFamily,
  },
  [`& .${classes.button}`]: {
    fontSize: '18px',
    margin: '5px 0px',
    padding: '4px 16px',
    flex: '20%',
  },
}));

const AddActivityForm = ({ handleSubmit }) => (
  <Root onSubmit={handleSubmit}>
    <InputField className={classes.input} name="name" label="Enter activity name..." />
    <ActivityTypesSelectField className={classes.select} name="activityType" />
    <Button variant="contained" color="secondary" type="submit" className={classes.button}>
      ADD
    </Button>
  </Root>
);

export default AddActivityForm;
