import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const PREFIX = 'UserRecordCell';

const classes = {
  hoursCell: `${PREFIX}-hoursCell`,
  cellWithAllActivityTypes: `${PREFIX}-cellWithAllActivityTypes`,
  projectHoursCell: `${PREFIX}-projectHoursCell`,
  nonProjectHoursCell: `${PREFIX}-nonProjectHoursCell`,
  outOfOfficeHoursCell: `${PREFIX}-outOfOfficeHoursCell`,
  dayOffCell: `${PREFIX}-dayOffCell`,
  totalCell: `${PREFIX}-totalCell`,
  recordHoursText: `${PREFIX}-recordHoursText`,
  recordEmptyHoursText: `${PREFIX}-recordEmptyHoursText`,
  hoursContainer: `${PREFIX}-hoursContainer`,
  outOfOfficeText: `${PREFIX}-outOfOfficeText`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '63px',
  minWidth: '39px',

  [`& .${classes.hoursCell}`]: {
    margin: '2.5px',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.cellWithAllActivityTypes}`]: {
    marginTop: '0.5px',
    marginBottom: '0.5px',
  },

  [`& .${classes.projectHoursCell}`]: {
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.nonProjectHoursCell}`]: {
    backgroundColor: theme.palette.red.main,
  },

  [`& .${classes.outOfOfficeHoursCell}`]: {
    backgroundColor: theme.palette.yellow.main,
  },

  [`& .${classes.dayOffCell}`]: {
    backgroundColor: theme.palette.white,
  },

  [`& .${classes.totalCell}`]: {
    backgroundColor: theme.palette.whiteblue,
  },

  [`& .${classes.recordHoursText}`]: {
    color: theme.palette.white,
  },

  [`& .${classes.recordEmptyHoursText}`]: {
    color: theme.palette.grey.dark,
  },

  [`& .${classes.outOfOfficeText}`]: {
    cursor: 'pointer',
  },
}));

const dayIsEmpty = (report) => {
  return report.projectHours === 0 && report.outOfOfficeHours === 0 && report.nonProjectHours === 0;
};

const dayWithAllActivityTypes = (report) => {
  return report.projectHours !== 0 && report.outOfOfficeHours !== 0 && report.nonProjectHours !== 0;
};

const UserRecordsCell = ({ dailyReport, isTotal, showOOOActivitiesModal, ...props }) => {
  const cellWithAllActivityTypes = dayWithAllActivityTypes(dailyReport) && classes.cellWithAllActivityTypes;
  if (dayIsEmpty(dailyReport)) {
    return (
      <Root {...props}>
        <div className={`${classes.hoursCell} ${isTotal ? classes.totalCell : classes.dayOffCell}`}>
          <Typography className={classes.recordEmptyHoursText}>0</Typography>
        </div>
      </Root>
    );
  } else {
    return (
      <Root {...props}>
        {dailyReport.projectHours !== 0 && (
          <div className={`${classes.hoursCell} ${classes.projectHoursCell} ${cellWithAllActivityTypes}`}>
            <Typography className={classes.recordHoursText}>{dailyReport.projectHours}</Typography>
          </div>
        )}
        {dailyReport.outOfOfficeHours !== 0 && (
          <div className={`${classes.hoursCell} ${classes.outOfOfficeHoursCell} ${cellWithAllActivityTypes}`}>
            <Typography
              onClick={() =>
                showOOOActivitiesModal(
                  dailyReport.vacationHours,
                  dailyReport.sickLeaveHours,
                  dailyReport.unpaidLeaveHours
                )
              }
              className={`${classes.recordHoursText} ${classes.outOfOfficeText}`}
            >
              {dailyReport.outOfOfficeHours}
            </Typography>
          </div>
        )}
        {dailyReport.nonProjectHours !== 0 && (
          <div className={`${classes.hoursCell} ${classes.nonProjectHoursCell} ${cellWithAllActivityTypes}`}>
            <Typography className={classes.recordHoursText}>{dailyReport.nonProjectHours}</Typography>
          </div>
        )}
      </Root>
    );
  }
};

UserRecordsCell.propTypes = {
  dailyReport: PropTypes.object,
};

export default UserRecordsCell;
