import queryString from 'query-string';
import 'moment/locale/en-gb';
import moment from 'moment';

const receiveParamFromUrl = (url, paramName) => {
  return queryString.parse(url)[paramName];
};

export const getFirstDayOfWeek = (url) => {
  const firstDayOfWeekQuery = receiveParamFromUrl(url, 'firstDayOfWeek');
  const queryDate = moment(firstDayOfWeekQuery);

  // if firstDayOfWeekQuery is undefined, moment returns current day
  if (firstDayOfWeekQuery && queryDate.isValid()) {
    return queryDate;
  } else {
    return moment().startOf('week');
  }
};

export const getFirstDayOfMonth = (url) => {
  const firstDayOfMonthQuery = receiveParamFromUrl(url, 'firstDayOfMonth');
  const queryDate = moment(firstDayOfMonthQuery);

  // if firstDayOfMonthQuery is undefined, moment returns current day
  if (firstDayOfMonthQuery && queryDate.isValid()) {
    return queryDate;
  } else {
    return moment().startOf('month');
  }
};

export const getYear = (url) => {
  const yearQuery = receiveParamFromUrl(url, 'year');
  const queryDate = moment(yearQuery, 'YYYY');

  // if year is undefined, moment returns current year
  if (yearQuery && queryDate.isValid()) {
    return queryDate.year();
  } else {
    return moment().year();
  }
};
