import React from 'react';
import { styled } from '@mui/material/styles';
import VacationTypeSelectField from '../../../fields/VacationTypeSelectField';
import DateRangePickerField from '../../../fields/DateRangePickerFIeld';
import { Typography } from '../../../../../node_modules/@mui/material/index';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';

const PREFIX = 'FilterForm';

const classes = {
  select: `${PREFIX}-select`,
  flexContainer: `${PREFIX}-flexContainer`,
  clearText: `${PREFIX}-clearText`,
};

const Root = styled('form')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',

  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.select}`]: {
    border: 'none',
    width: '180px',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '5px 0px 5px 0px',
    fontFamily: theme.typography.fontFamily,
  },
  [`& .${classes.clearText}`]: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const StyledDateRangePickerFiled = styled(DateRangePickerField)(({ theme }) => ({
  '& .DateRangePicker-DateRangePickerInput': {
    textAlign: 'center',
    paddingTop: '4px',
    width: '240px',
    cursor: 'pointer',
    backgroundColor: theme.palette.white,
    color: theme.palette.text.light,
    height: '31px',
    borderRadius: '4px',
    border: 'solid 1px',
    borderColor: theme.palette.border.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: '400',
  },

  '& .DateRangePicker-selectedDate': {
    display: 'flex',
    backgroundColor: theme.palette.white,
    margin: '0 10px',
    border: 'none',
  },
}));

// TODO: prevent search form from forcing re-rendering the parent component onChange, onBlur and onFocus
const SearchUserForm = ({ handleSubmit, ...props }) => {
  const dispatch = useDispatch();
  const handleClearDates = () => {
    dispatch(change(props.form, 'dateRange', []));
  };

  return (
    <Root onSubmit={handleSubmit}>
      <div className={classes.flexContainer}>
        <VacationTypeSelectField className={classes.select} name="vacationType" />
        <StyledDateRangePickerFiled name="dateRange" placeholder="Start Date - End Date" />
        <Typography className={classes.clearText} variant="body1" onClick={handleClearDates}>
          Clear Dates
        </Typography>
      </div>
    </Root>
  );
};

export default SearchUserForm;
