import { connect } from 'react-redux';
import Activities from './Activities';
import * as activitiesAction from '../../../redux/modules/activities/activitiesActions';
import * as modalsAction from '../../../redux/modules/modals/modalsActions';
import { getActivities } from '../../../redux/modules/activities/activitiesSelectors';

const mapStateToProps = (state) => ({
  activities: getActivities(state),
  totalActivities: state.activities.totalItems,
  isLoading: state.activities.isLoading,
});

const mapDispatchToProps = {
  getActivities: activitiesAction.getActivities,
  deleteActivity: activitiesAction.deleteActivity,
  showUpdateActivityModal: modalsAction.showUpdateActivityModal,
  clearActivities: activitiesAction.clearActivities,
};

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
