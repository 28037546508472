import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import AddActivityForm from './AddActivityForm';
import Notification from '../../../common/Notification/Notification';
import { addActivity } from '../../../../redux/modules/activities/activitiesActions';

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.activityType) {
    errors.activityType = 'Required';
  }
  return errors;
};

const selector = formValueSelector('addActivityForm');

const mapStateToProps = (state) => ({
  name: selector(state, 'name'),
  activityType: selector(state, 'activityType'),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'addActivityForm',
    validate,
    onSubmit: (payload, dispatch, props) => {
      if (props.onSearch) {
        props.onSearch();
      }
      dispatch(addActivity(payload.name, payload.activityType.value)).then(({ status, text }) => {
        if (status === 500 || status === 404 || status === 400) {
          Notification('warning', text)();
        } else {
          props.reset();
          Notification('success')();
        }
      });
    },
  })(AddActivityForm)
);
