import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCellSpinner from './TableCellSpinner';

const TableRowSpinner = (props) => {
  return (
    <TableRow>
      <TableCellSpinner {...props} />
    </TableRow>
  );
};
export default TableRowSpinner;
