import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import ActivityContainer from '../Activity/ActivityContainer';
import HoursRowContainer, { ROW_TYPE } from '../HoursRow/HoursRowContainer';

const ActivitiesList = ({ activities }) => {
  return activities.map((item, index, array) => (
    <ActivityContainer activity={item} key={item.activityId} isLast={index === array.length - 1} />
  ));
};

const ActivityType = (props) => {
  const { classes, label, totals, activities } = props;
  return (
    <>
      <TableRow className={classes.activityRow}>
        <TableCell style={{ borderBottom: 'none' }}>
          <Typography type="subtitle1">{label}</Typography>
        </TableCell>
        <HoursRowContainer
          display={ROW_TYPE.TYPOGRAPHY}
          totalHoursPerDay={totals.totalHoursPerDay}
          totalHoursPerWeek={totals.totalHoursPerWeek}
          isUnderlined={false}
          isTotalsHighlighted={true}
        />
      </TableRow>
      <ActivitiesList activities={activities} />
    </>
  );
};

ActivityType.propTypes = {
  label: PropTypes.string.isRequired,
  activities: PropTypes.arrayOf(PropTypes.object),
  totals: PropTypes.shape({
    totalHoursPerDay: PropTypes.arrayOf(PropTypes.object).isRequired,
    totalHoursPerWeek: PropTypes.number.isRequired,
  }),
};

export default React.memo(ActivityType);
