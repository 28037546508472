import { connect } from 'react-redux';
import * as modalsAction from '../../../redux/modules/modals/modalsActions';
import OOOActivitiesModal from './OOOActivitiesModal';

const mapStateToProps = (state) => ({
  vacationHours: state.modals.modalProps.vacationHours,
  sickLeaveHours: state.modals.modalProps.sickLeaveHours,
  unpaidLeaveHours: state.modals.modalProps.unpaidLeaveHours,
});

const mapDispatchToProps = {
  hideModal: modalsAction.hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(OOOActivitiesModal);
