import axios from 'axios';
import queryString from 'query-string';

export const getUsers = async (pageNumber, pageSize, name, roles) =>
  await axios.get('/api/users', {
    params: {
      pageNumber,
      pageSize,
      name,
      roles,
    },
    paramsSerializer: (params) => queryString.stringify(params),
  });

export const getUserById = async (userId) => {
  return await axios.get(`/api/users/${userId}`);
};

export const archiveUser = async (userId) => {
  return await axios.post(`/api/users/${userId}/archive`);
};

export const unarchiveUser = async (userId) => {
  return await axios.post(`/api/users/${userId}/unarchive`);
};

export const getAllUsers = async (pageNumber, pageSize, name, status, roles) => {
  return await axios.get('/api/users/all', {
    params: {
      pageNumber,
      pageSize,
      name,
      status,
      roles,
    },
    paramsSerializer: (params) => queryString.stringify(params),
  });
};
