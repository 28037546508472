import PRODUCTION_CALENDAR_CONSTANTS from './productionCalendarConstants';
import * as productionCalendarRequests from '../../../axios/productionCalendar';
import { parseErrors } from '../../../components/helper/requestsHelpers';

export const getProductionCalendarByUserId = (userId) => async (dispatch) => {
  const response = await productionCalendarRequests.getProductionCalendarByUserId(userId);
  dispatch({
    type: PRODUCTION_CALENDAR_CONSTANTS.GET_PRODUCTION_CALENDAR,
    payload: response.data.country,
  });
};

export const setProductionCalendarByUserId = (userId, productionCalendarCountry) => async (dispatch) => {
  try {
    const response = await productionCalendarRequests.setProductionCalendarByUserId(userId, productionCalendarCountry);
    dispatch({
      type: PRODUCTION_CALENDAR_CONSTANTS.SET_PRODUCTION_CALENDAR,
      payload: productionCalendarCountry,
    });
    return { status: response.status };
  } catch (e) {
    return await parseErrors(e);
  }
};

export const getCurrentUserProductionCalendar = () => async (dispatch) => {
  const response = await productionCalendarRequests.getCurrentUserProductionCalendar();
  dispatch({
    type: PRODUCTION_CALENDAR_CONSTANTS.GET_PRODUCTION_CALENDAR,
    payload: response.data.country,
  });
};

export const getProductionCalendarsList = () => async (dispatch) => {
  const response = await productionCalendarRequests.getProductionCalendarsList();
  dispatch({
    type: PRODUCTION_CALENDAR_CONSTANTS.GET_PRODUCTION_CALENDARS_LIST,
    payload: response.data,
  });
};
