import { connect } from 'react-redux';
import { getWeeklyUserActivities } from '../../../../../redux/modules/activities/activitiesActions';
import WeekPicker from '../../../../common/DatePickers/WeekPicker';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTimeTrackWeek: firstDayOfWeek => getWeeklyUserActivities(firstDayOfWeek, ownProps.userId),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(WeekPicker);
