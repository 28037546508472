import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

const PREFIX = 'ConfirmationModal';

const classes = {
  root: `${PREFIX}-root`,
  headline: `${PREFIX}-headline`,
  buttonWrapper: `${PREFIX}-buttonWrapper`,
  button: `${PREFIX}-button`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  maxWidth: '60vh',
  margin: 'auto',

  [`& .${classes.headline}`]: {
    color: theme.palette.grey.dark,
    fontWeight: 'bold',
  },

  [`& .${classes.buttonWrapper}`]: {
    display: 'flex',
    justifyContent: 'center',
  },

  [`& .${classes.button}`]: {
    margin: '0 10px',
  },
}));

const ConfirmationModal = (props) => {
  const { isOpen, handleSubmit, handleClose, modalTitle, confirmButtonName } = props;

  return (
    <StyledDialog open={isOpen} onClose={handleClose}>
      <DialogTitle id="form-dialog-title">
        <div className={classes.headline}>{modalTitle}</div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.buttonWrapper}>
          <Button
            variant="contained"
            color="cancelButton"
            onClick={handleClose}
            data-cy="deny-btn"
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            data-cy="confirm-btn"
            className={classes.button}
          >
            {confirmButtonName || 'Confirm'}
          </Button>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  confirmButtonName: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
};

export default ConfirmationModal;
