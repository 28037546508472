import 'moment/locale/en-gb';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import Calendar from 'rc-calendar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DatePicker from 'rc-calendar/lib/Picker';
import enUS from 'rc-calendar/lib/locale/en_US';
import 'rc-calendar/dist/rc-calendar.css';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import './WeekPicker.css';

const PREFIX = 'WeekPicker';

const classes = {
  container: `${PREFIX}-container`,
  weekPickerInput: `${PREFIX}-weekPickerInput`,
  weeklyCalendar: `${PREFIX}-weeklyCalendar`,
  selectedWeek: `${PREFIX}-selectedWeek`,
  selectWeekArrows: `${PREFIX}-selectWeekArrows`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  alignItems: 'center',

  [`& .${classes.weekPickerInput}`]: {
    border: 'none',
    textAlign: 'center',
    paddingTop: '4px',
    width: '150px',
    cursor: 'pointer',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.white,
  },

  [`& .${classes.weeklyCalendar}`]: {
    width: '250px',
  },

  [`& .${classes.selectedWeek}`]: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '13px',
    margin: '0 10px',
  },

  [`& .${classes.selectWeekArrows}`]: {
    cursor: 'pointer',
    color: theme.palette.white,
  },
}));

const format = 'DD.MM.YYYY';

const WeekPicker = (props) => {
  const { firstDayOfWeek, onWeekChanged } = props;
  const [isOpened, setIsOpened] = useState(false);
  const calendarParentRef = useRef(null);

  const moveToWeekWhere = (date) => {
    onWeekChanged(date);
  };

  const setSelectedDate = (value) => {
    if (!value) return;
    moveToWeekWhere(value.startOf('week'));
  };

  const onOpenChange = (isOpened) => {
    setIsOpened(isOpened);
  };

  const handleClickPreviousWeek = () => {
    const previousWeek = moment(firstDayOfWeek).startOf('week').subtract(1, 'weeks');

    moveToWeekWhere(previousWeek);
  };

  const handleClickNextWeek = () => {
    const nextWeek = moment(firstDayOfWeek).startOf('week').add(1, 'weeks');

    moveToWeekWhere(nextWeek);
  };

  const renderWeek = (value) => {
    if (value) {
      return `${value.startOf('week').format(format)} - ${value.endOf('week').format(format)}`;
    }
  };

  const renderDate = (current) => {
    const selectedValue = firstDayOfWeek;
    if (selectedValue && current.year() === selectedValue.year() && current.week() === selectedValue.week()) {
      return (
        <div className="rc-calendar-selected-day">
          <div className="rc-calendar-date">{current.date()}</div>
        </div>
      );
    }

    return <div className="rc-calendar-date">{current.date()}</div>;
  };

  const renderCalendar = () => {
    return (
      <Calendar
        className="week-calendar"
        dateRender={renderDate}
        locale={enUS}
        format={format}
        dateInputPlaceholder="dd.mm.yyyy - dd.mm.yyy"
      />
    );
  };

  const renderInputDatePicker = (value) => {
    return (
      <Grid item>
        <input
          placeholder="dd.mm.yyyy - dd.mm.yyyy"
          readOnly
          className={`${classes.weekPickerInput} input-datePicker`}
          value={value && (renderWeek(value.value) || '')}
        />
      </Grid>
    );
  };

  const calendar = renderCalendar();

  return (
    <StyledGrid container direction="row" spacing={0}>
      <Grid item>
        <Typography type="subtitle1">Week:</Typography>
      </Grid>

      <>
        <Grid item className={classes.selectedWeek} ref={calendarParentRef}>
          <KeyboardArrowLeft className={classes.selectWeekArrows} onClick={handleClickPreviousWeek} />
          <DatePicker
            onOpenChange={onOpenChange}
            open={isOpened}
            calendar={calendar}
            getCalendarContainer={() => calendarParentRef.current}
            value={moment(firstDayOfWeek)}
            onChange={setSelectedDate}
          >
            {(value) => renderInputDatePicker(value)}
          </DatePicker>
          <KeyboardArrowRight className={classes.selectWeekArrows} onClick={handleClickNextWeek} />
        </Grid>
      </>
    </StyledGrid>
  );
};

export default WeekPicker;
