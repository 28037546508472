import React, { useRef, useCallback } from 'react';

const IntersectionObserverComponent = ({ children, isLoading, onIntersect }) => {
  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          onIntersect();
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoading, onIntersect]
  );

  const child = React.Children.only(children);
  return React.cloneElement(child, { ref: lastElementRef, innerRef: lastElementRef });
};

export default IntersectionObserverComponent;
