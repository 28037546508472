import React, { useRef, useState } from 'react';
import DatePicker from 'rc-calendar/lib/Picker';
import enUS from 'rc-calendar/lib/locale/en_US';
import Calendar from 'rc-calendar/lib/RangeCalendar';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import 'moment/locale/en-gb';
import 'rc-calendar/dist/rc-calendar.css';

const PREFIX = 'DateRangePicker';

const classes = {
  container: `${PREFIX}-container`,
  DateRangePickerInput: `${PREFIX}-DateRangePickerInput`,
  selectedDate: `${PREFIX}-selectedDate`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [`& .${classes.DateRangePickerInput}`]: {
    textAlign: 'center',
    paddingTop: '4px',
    width: '240px',
    cursor: 'pointer',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.white,
    borderRadius: '16px',
    height: '24px',
  },

  [`& .${classes.selectedDate}`]: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    margin: '0 10px',
    borderRadius: '13px',
  },
}));

const format = 'DD.MM.YYYY';

const DateRangePicker = (props) => {
  const { onChange, value, ...restProps } = props;
  const [isOpened, setIsOpened] = useState(false);
  const calendarParentRef = useRef(null);

  const onOpenChange = (isOpened) => {
    setIsOpened(isOpened);
  };

  const renderCalendar = () => {
    return <Calendar locale={enUS} format={format} dateInputPlaceholder='dd.mm.yyyy' />;
  };

  const renderInputDatePicker = () => {
    return (
      <Grid item>
        <input
          readOnly
          className={`${classes.DateRangePickerInput} input-datePicker`}
          value={value && value.length ? `${value[0].format(format)} - ${value[1].format(format)}` : ''}
          placeholder={props.placeholder}
        />
      </Grid>
    );
  };

  const calendar = renderCalendar();

  return (
    <StyledGrid container direction="row" spacing={0} {...restProps}>
      <>
        <Grid item className={classes.selectedDate} ref={calendarParentRef}>
          <DatePicker
            onOpenChange={onOpenChange}
            open={isOpened}
            calendar={calendar}
            getCalendarContainer={() => calendarParentRef.current}
            value={value}
            onChange={onChange}
          >
            {(value) => renderInputDatePicker(value)}
          </DatePicker>
        </Grid>
      </>
    </StyledGrid>
  );
};

export default DateRangePicker;
