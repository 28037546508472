import React from 'react';
import DateRangePicker from '../common/DatePickers/DateRangePicker';

const InnerDateRangePickerField = ({ input, ...props }) => (
  <div>
    <DateRangePicker value={input.value} onChange={input.onChange} {...props} />
  </div>
);

export default InnerDateRangePickerField;
