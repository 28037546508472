import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import EmployeesTable from './EmployeeTable/EmployeesTable';
import FiltersFormContainer from './Filters/FiltersFormContainer';
import PageTitle from '../../common/PageTitle/PageTitle';
import { getCurrentUser } from '../../../redux/modules/users/usersSelectors';
import { getResourceManagers, getSubordinatesByUserId } from '../../../redux/modules/employees/employeesActions';
import roleConstants from '../../helper/roleConstants';
import paginationConstants from '../../helper/paginationConstants';
import HeaderBarContainer from '../../common/HeaderBarWrapper/HeaderBar/HeaderBarContainer';
import withRoles from '../../../hocs/withRoles';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect';

import {
  selectEmployees,
  selectEmployeesIsLoading,
  selectEmployeesTotalItems,
} from '../../../redux/modules/employees/employeesSelectors';
import { clearEmployees, getEmployees } from '../../../redux/modules/employees/employeesActions';

const PREFIX = 'EmployeesPage';

const classes = {
  contentWrapper: `${PREFIX}-contentWrapper`,
  controls: `${PREFIX}-controls`,
  button: `${PREFIX}-button`,
  filtersContainer: `${PREFIX}-filtersContainer`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
  height: '100%',

  [`& .${classes.contentWrapper}`]: {
    display: 'flex',
    height: 'calc(100vh - 58px)',
    flexDirection: 'column',
    backgroundColor: theme.palette.whitegrey,
    padding: '30px 50px',
    overflow: 'auto',
  },

  [`& .${classes.controls}`]: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },

  [`& .${classes.button}`]: {
    fontSize: '18px',
    margin: '5px 0px',
    padding: '4px 16px',
    width: '160px',
  },
  [`& .${classes.filtersContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.white,
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
    padding: '5px 10px',
  },
}));

const EmployeesPage = () => {
  const pageTitle = 'Employees';
  const UserEditorButton = withRoles([roleConstants.userEditor, roleConstants.superManager])(Button);
  const dispatch = useDispatch();

  const employees = useSelector(selectEmployees);
  const currentUser = useSelector(getCurrentUser);
  const totalEmployees = useSelector(selectEmployeesTotalItems);
  const isLoading = useSelector(selectEmployeesIsLoading);

  const [filters, setFilters] = useState({ status: null, onlyMySubordinates: false, name: '', resourceManager: null });

  const itemsPerPage = paginationConstants.itemsPerPage;
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    totalItems: totalEmployees,
    itemsPerPage: itemsPerPage,
  });

  useEffect(() => {
    setPagination((prev) => ({ ...prev, totalItems: totalEmployees }));
  }, [totalEmployees]);

  useDebouncedEffect(
    () => {
      if (filters.onlyMySubordinates) {
        dispatch(
          getSubordinatesByUserId(
            currentUser.id,
            pagination.pageNumber,
            itemsPerPage,
            filters.name,
            filters.status,
            filters.resourceManager
          )
        );
      } else {
        dispatch(
          getEmployees(
            pagination.pageNumber,
            pagination.itemsPerPage,
            filters.name,
            filters.status,
            filters.resourceManager
          )
        );
      }
      dispatch(getResourceManagers());
    },
    filters.name ? 1000 : 0,
    [
      filters.name,
      filters.onlyMySubordinates,
      filters.status,
      filters.resourceManager,
      itemsPerPage,
      pagination.pageNumber,
    ]
  );

  const handleFiltersChange = useCallback((filters) => {
    dispatch(clearEmployees());
    setFilters(filters);
    setPagination((prev) => ({ ...prev, pageNumber: 1 }));
  }, []);

  const handlePageChange = useCallback((event, page) => {
    event.preventDefault();
    setPagination((prev) => ({ ...prev, pageNumber: page }));
  }, []);

  const onButtonClick = () => {
    const url = `${process.env.REACT_APP_AUTHORITY_URL}/users/add?returnUrl=${window.location.href}`;
    window.location.assign(url);
  };

  return (
    <>
      <PageTitle pageTitle={pageTitle} />
      <Root>
        <HeaderBarContainer pageTitle={pageTitle} />
        <div className={classes.contentWrapper}>
          <div className={classes.controls}>
            <UserEditorButton variant="contained" color="secondary" onClick={onButtonClick} className={classes.button}>
              ADD USER
            </UserEditorButton>
          </div>
          <div className={classes.filtersContainer}>
            <FiltersFormContainer onFiltersChange={handleFiltersChange} withSubordinatesFilter />
          </div>
          <EmployeesTable
            isLoading={isLoading}
            employees={employees}
            pagination={pagination}
            onPageChange={handlePageChange}
          />
        </div>
      </Root>
      <NotificationContainer />
    </>
  );
};

export default EmployeesPage;
