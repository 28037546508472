export const formatHours = value => {
  let formatValue = value;
  const hours = parseInt(value, 10);
  const minutes = (value * 60) % 60;

  if (minutes !== 0) {
    formatValue = `${hours}h ${minutes}m`;
  } else {
    formatValue = `${formatValue}h`;
  }

  return formatValue;
};
