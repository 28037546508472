import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ActivitiesList from '../ActivitiesPage/ActivitiesList';
import AddUserToActivityFormContainer from './AddUserToActivityFormContainer';
import SelectUserFormContainer from './SelectUserFormContainer';
import paginationConstants from '../../helper/paginationConstants';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect';

const PREFIX = 'UserActivities';

const classes = {
  root: `${PREFIX}-root`,
  flexContainer: `${PREFIX}-flexContainer`,
  headlineContainer: `${PREFIX}-headlineContainer`,
  select: `${PREFIX}-select`,
  headline: `${PREFIX}-headline`,
  activitiesSubheading: `${PREFIX}-activitiesSubheading`,
  formContainer: `${PREFIX}-formContainer`,
};

const Root = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  height: '100%',
  width: '60%',
  marginLeft: 'auto',
  marginRight: 'auto',

  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '25px',
    paddingTop: '10px',
    backgroundColor: theme.palette.grey.light,
    height: '62vh',
    maxHeight: '77vh',
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
    minHeight: '400px',
    overflow: 'hidden',
  },

  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 0',
  },

  [`& .${classes.headlineContainer}`]: {
    display: 'flex',
    marginTop: '3vh',
    justifyContent: 'left',
  },

  [`& .${classes.select}`]: {
    border: 'none',
    width: '50%',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '5px 5% 5px 0px',
    fontFamily: theme.typography.fontFamily,
  },

  [`& .${classes.headline}`]: {
    color: theme.palette.grey.dark,
    fontWeight: 'bold',
  },

  [`& .${classes.activitiesSubheading}`]: {
    color: theme.palette.grey.dark,
  },
}));

const UserActivities = (props) => {
  const {
    clearCurrentUser,
    clearActivities,
    clearUserActivities,
    clearUsers,

    getUsers,
    users,
    totalUsers,
    currentUser,

    getUserActivities,
    deleteUserFromActivity,
    userActivities,

    getActivities,
    activities,
    unassignedActivities,
    totalActivities,
  } = props;

  const pageSize = paginationConstants.itemsPerPage;
  const [usersPagination, setUsersPagination] = useState({
    page: 1,
    isLoading: false,
    search: '',
  });
  const [activitiesPagination, setActivitiesPagination] = useState({
    page: 1,
    isLoading: false,
    search: '',
  });

  const usersOptions = useMemo(() => users.map((user) => ({ value: user.id, label: user.name })), [users]);
  const activitiesOptions = useMemo(
    () => unassignedActivities.map((activity) => ({ value: activity.id, label: activity.name })),
    [unassignedActivities]
  );

  const handleUsersSearchChange = useCallback(
    (value) => {
      if (value !== usersPagination.search) {
        clearUsers();
        setUsersPagination({ page: 1, search: value, isLoading: true });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usersPagination.search]
  );
  const handleUsersPageChange = useCallback(() => {
    setUsersPagination((prev) => ({ ...prev, page: prev.page + 1, isLoading: true }));
  }, []);

  const handleActivitiesSearchChange = useCallback(
    (value) => {
      if (value !== activitiesPagination.search) {
        clearActivities();
        setActivitiesPagination({ page: 1, search: value, isLoading: true });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activitiesPagination.search]
  );
  const handleActivitiesPageChange = useCallback(() => {
    setActivitiesPagination((prev) => ({ ...prev, page: prev.page + 1, isLoading: true }));
  }, []);

  useEffect(() => {
    return () => {
      clearCurrentUser();
      clearActivities();
      clearUserActivities();
      clearUsers();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentUser) return;
    getUserActivities(currentUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useDebouncedEffect(
    () => {
      getUsers(usersPagination.page, pageSize, usersPagination.search);
      setUsersPagination((prev) => ({ ...prev, isLoading: false }));
    },
    usersPagination.search ? 1000 : 0,
    [usersPagination.page, usersPagination.search]
  );

  useDebouncedEffect(
    () => {
      getActivities(activitiesPagination.page, pageSize, activitiesPagination.search);
      setActivitiesPagination((prev) => ({ ...prev, isLoading: false }));
    },
    activitiesPagination.search ? 1000 : 0,
    [activitiesPagination.page, activitiesPagination.search]
  );

  return (
    <Root>
      <div className={classes.headlineContainer}>
        <Typography className={classes.headline} variant="h5">
          Assign activities to users
        </Typography>
      </div>
      <Paper className={classes.root} elevation={2}>
        <div className={classes.flexContainer}>
          <SelectUserFormContainer
            classes={classes}
            options={usersOptions}
            loadMore={handleUsersPageChange}
            hasMore={totalUsers > users.length}
            onInputChange={handleUsersSearchChange}
            isLoading={usersPagination.isLoading}
          />
        </div>

        <Typography className={classes.activitiesSubheading} variant="subtitle1">
          {currentUser && `Activities of user ${currentUser}`}
        </Typography>
        <ActivitiesList
          activities={userActivities}
          itemCanBeEdited={false}
          onDeleteButtonClicked={deleteUserFromActivity}
        />
        <AddUserToActivityFormContainer
          classes={classes}
          isDisabled={!currentUser}
          options={activitiesOptions}
          loadMore={handleActivitiesPageChange}
          hasMore={totalActivities > activities.length}
          onInputChange={handleActivitiesSearchChange}
          isLoading={activitiesPagination.isLoading}
        />
      </Paper>
    </Root>
  );
};

export default UserActivities;
